import {
  Help,
} from "@mui/icons-material";
import {
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { getTranslation } from "common";
import { SearchBar } from "components";
import UserPersonalRobots from "components/UserPersonalRobots";
import {
  useRobots,
  useTranslations,
  useSelectedOrganization,
} from "hooks";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const Robots = () => {
  const [search, setSearch] = useState<string>("");
  const [robots, getRobots] = useRobots("user");
  const translations = useTranslations();
  const organization = useSelectedOrganization();
  //const isAdmin = organization?.administrator;
  const updateRobotsList = async () => {
    await getRobots();
  };

  const searchedRobots = robots.filter((robot) =>
    robot.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <React.Fragment>
        <>
          <Container maxWidth="lg">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="h5">
                  {getTranslation(translations, "robots.name")}
                </Typography>
                <IconButton size="small" to={"help"} component={Link}>
                  <Help fontSize="small" />
                </IconButton>
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <SearchBar
                  setFilter={setSearch}
                  id="search-robots"
                  placeholder={getTranslation(
                    translations,
                    "pm.organizationchart.ricerca.placeholder"
                  )}
                />
              </Stack>
            </Stack>
            <Grid item sm={6} md={4} lg={3} xs={12} key="robot-add">
              <UserPersonalRobots onRobotUpdate={updateRobotsList} searchedRobots={searchedRobots} />
            </Grid>
          </Container>
        </>
    </React.Fragment>
  );
};

export default Robots;
