import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { addMessage } from "Redux/messageReducer";
import { useTranslations, useUser, useForm, useSnackbar } from "hooks";
import { noTokenApi, usersApi } from "api";
import { getTranslation, toDataUrl } from "common";
import { Save } from "@mui/icons-material";
import { ImagePicker } from "components";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { AuthContext } from "contexts";
import { LoadingButton } from "@mui/lab";

interface ProfileProps {
  admin?: boolean;
  updateVersion: (id: number | undefined | null) => void;
}

// TEST THIS COMPONENT
// - change picture does not work
// has to set form with get user from backend
// insert snackbar on successfully edit

interface LanguageOption {
  label: string;
  value: string;
}
const Profile: React.FC<ProfileProps> = ({ admin, updateVersion }) => {
  const translations = useTranslations();
  const dispatch = useDispatch();
  const user = useUser();
  const {
    actions: { setUser },
  } = useContext(AuthContext);
  const { sendSnack } = useSnackbar();
  const [photo, setPhoto] = useState<string | null>(null);
  const currentDate = new Date();
  const maxDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );
  const [timeDateValue, setTimeDateValue] = useState<Date | null>(user?.dob || null);
  const isDateValid =
    timeDateValue !== null && !isNaN(new Date(timeDateValue).getTime());
  const [saveLoading, setSaveLoading] = useState<boolean>(false)


  const onChangeSelectDate = (value: Date | null) => {
    if (value !== null) {
      setTimeDateValue(new Date(value));
    }
  };
  const { values, changeHandler, errors, touched, isValid } = useForm(
    { ...user },
    []
  );
  const register = (property: string) => ({
    onChange: ({
      target,
    }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      changeHandler(property, target.value),
    value: values[property] || "",
    error: Boolean(touched[property] && errors[property]),
    helperText: touched[property] && errors[property],
  });

  const submitData = async () => {
    setSaveLoading(true);
    try {
      const { username, firstname, lastname, email, i18n, linkprofile } =
        values;
      let picture = null;
      let dob = null;
      if (photo) {
        picture = await toDataUrl(photo);
      }
      if (timeDateValue) {
        dob = timeDateValue;
      }
      const updateResponse = await usersApi.put(`/${user?.jwt}`, {
        username,
        firstname,
        lastname,
        email,
        dob,
        i18n,
        linkprofile,
        picture,
      });
      if (user?.i18n !== i18n) {
        noTokenApi.get(`messages/${i18n}`).then((result) => {
          dispatch(addMessage(result.data.messages));
        });
      }
      if (user) {
        const { data } = await usersApi.get(`${user.jwt}`);
        setUser({
          subscriptiontype: data.user.i18n,
          jwt: user.jwt,
          userId: user.userId,
          email: data.user.email,
          username: data.user.username,
          firstname: data.user.firstname,
          lastname: data.user.lastname,
          picture: data.user.picture,
          // picture: picture as string,
          linkprofile: data.user.linkprofile,
          // subscriptiontype: auth,
          i18n: data.user.i18n,
          dob: data.user.dob,
        });
      }
      sendSnack({
        message: updateResponse.data.responsemessage,
        type: "success",
      });
    } catch {
    } finally {
      setSaveLoading(false)
      updateVersion(1)
    }

  };

  const [languageOptions, setLanguageOptions] = useState<LanguageOption[]>([]);

  useEffect(() => {
    let languages = getTranslation(translations, "Language");
    languages = languages.replace("(", "");
    languages = languages.replace(")", "");
    languages = languages.replaceAll("'", "");
    languages = languages.replace("Language", "");
    for (let i = 0; i <= 1; i++) {
      const commaIdx = languages.indexOf(",");
      languages = languages.substring(commaIdx + 1);
    }
    const options = languages
      .split(";")
      .map((valueLabel: string) => {
        const [value, label] = valueLabel.split(",");
        return { label, value };
      })
      .filter((x) => x.value);
    setLanguageOptions(options);
  }, [translations]);
  return (
    <Stack>
      <Typography variant="h4">
        {getTranslation(translations, "user.profile.title")}
      </Typography>
      <Stack
        alignItems={"flex-start"}
        direction={{ xs: "column", sm: "row" }}
        spacing={2}
      >
        <Box pt={2}>
          <ImagePicker
            // initialPhoto={initialPhoto || ""}
            relativePath={user?.picture || ""}
            photoUrl={photo}
            setPhotoUrl={setPhoto}
          />
        </Box>
        <Grid
          sx={{
            "& .MuiGrid-item": {
              pl: { xs: 0, sm: 2 },
              mr: { xs: 2, sm: 0 },
            },
          }}
          container
          spacing={2}
        >
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              fullWidth
              size="small"
              label={getTranslation(translations, "user.firstname.label")}
              {...register("firstname")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              fullWidth
              size="small"
              label={getTranslation(translations, "user.lastname.label")}
              {...register("lastname")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              disabled
              fullWidth
              size="small"
              label={getTranslation(translations, "user.email.label")}
              {...register("email")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label={getTranslation(translations, "user.dateofbirth.label")}
              slotProps={{
                textField: {
                  size: "small",
                  placeholder: "DD-MM-YYYY",
                  fullWidth: true,
                },
              }}
              maxDate={dayjs(maxDate) as any}
              value={dayjs(timeDateValue || null) as any}
              onChange={onChangeSelectDate}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              size="small"
              label={getTranslation(translations, "user.language.label")}
              {...register("i18n")}
            >
              {languageOptions.map(({ value, label }: LanguageOption) => (
                <MenuItem key={`language-option-${value}`} value={value}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <LoadingButton
                variant="contained"
                size="small"
                loading={saveLoading}
                loadingPosition="start"
                disabled={!isValid || !isDateValid}
                startIcon={<Save />}
                onClick={
                  submitData}
              >
                {getTranslation(translations, "pm.button.save")}
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      {/* {admin ? (
          <>
            <Form.Group controlId="disableProfile">
              <Form.Check
                type="checkbox"
                label="disableProfile"
                {...register("disableProfile")}
              />
            </Form.Group>
            <Form.Group controlId="subscriptiontype">
              <Form.Check
                type="checkbox"
                label="Two-Factor"
                {...register("subscriptiontype")}
              />
            </Form.Group>
          </>
        ) : null} */}
    </Stack>
  );
};

export default Profile;
