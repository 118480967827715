import NotFoundImage from "image/notFound.svg";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Container,
  Stack,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { getTranslation } from "common";
import { useTranslations } from "hooks";

const NotFound = () => {
  const translations = useTranslations();
  return (
    <Container
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      <Card sx={{
        zindex: 999,
        position: { xs: "absolute" },
        bottom: _ => _.spacing(4)
      }}>
        <CardHeader title={getTranslation(
          translations,
          "application.notfound.label"
        )} />
        <CardActions>
          <Stack flex={1} direction="row" spacing={2} justifyContent="flex-end">
            <Button
              to="/dashboard/organizations"
              variant="contained"
              size="small"
              component={Link}
            >
              {getTranslation(
                translations,
                "application.homepage.label"
              )}
            </Button>
            <Button variant="contained" size="small">
              {getTranslation(
                translations,
                "application.goback.label"
              )}
            </Button>
          </Stack>
        </CardActions>
      </Card>
      <Box component="img" src={NotFoundImage} alt="..." height="100%" width="150%" />
    </Container>
  );
};

export default NotFound;
