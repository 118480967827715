import React, { useState } from "react";
import {
  Box,
  Checkbox,
  Button,
  FormControl,
  FormGroup as MuiFormGroup,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import { getTranslation } from "common";
import { useTranslations } from "hooks";
import { ProcessModelType } from "model/processModel";

interface ProcessModelFilterProps {
  processModelsId: number[];
  onProcessModelsChange: (ids: number[]) => void;
  options: ProcessModelType[];
}

const getLabel = (model: ProcessModelType | undefined) => {
  if (!model) return "";
  return model.name === ""
    ? model.name
    : model.name;
    // : model.processmodelname + " (" + model.name + ")";
};

const ProcessModelFilter: React.FC<ProcessModelFilterProps> = ({
  processModelsId,
  onProcessModelsChange,
  options,
}) => {
  const translations = useTranslations();
  const [showAllModels, setShowAllModels] = useState(false);
  const displayedOptions = showAllModels ? options : options.slice(0, 5);

  const handleCheckboxChange = (id: number) => {
    if (processModelsId.includes(id)) {
      onProcessModelsChange(
        processModelsId.filter((modelId) => modelId !== id)
      );
    } else {
      onProcessModelsChange([...processModelsId, id]);
    }
  };

  return (
    <Box minWidth={200}>
      <FormControl component="fieldset">
        <MuiFormGroup>
          {displayedOptions.map((model) => (
            <Tooltip title={getLabel(model)} key={`start-event-filter-${model.id}`}>
              <FormControlLabel
                slotProps={{
                  typography: {
                    sx: {
                      maxWidth: 150,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    },
                  },
                }}
                control={
                  <Checkbox
                    id={`model-filter-${model.id}`}
                    size="small"
                    checked={processModelsId.includes(model.id)}
                    onChange={() => handleCheckboxChange(model.id)}
                  />
                }
                label={getLabel(model)}
              />
            </Tooltip>
          ))}
          {options.length > 5 && (
            <Button
              onClick={() => setShowAllModels(!showAllModels)}
              variant="text"
              sx={{ mt: 1 }}
            >
              {showAllModels
                ? getTranslation(translations, "generic.show.less")
                : getTranslation(translations, "generic.show.all")}
            </Button>
          )}
        </MuiFormGroup>
      </FormControl>
    </Box>
  );
};

export default ProcessModelFilter;
