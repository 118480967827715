import { useEffect, useState } from "react";
import { Box, Paper } from "@mui/material";
import { GridProps, GroupedRows, SortState, keyOrNothing } from "./types";
import Header from "./Header";
import Row from "./Row";
import { orderBy, groupBy, mapValues } from "lodash";
import GroupedRowsComponent from "./GroupedRows";

const mapSortState = (_: SortState) => {
  if (_ === "ASC") {
    return "asc";
  } else if (_ === "DESC") {
    return "desc";
  } else {
    return false;
  }
};

const groupRecursive = <T extends unknown>(
  rows: T[],
  groupKeys: Array<keyof T>
): GroupedRows<T> | T[] => {
  if (!groupKeys.length) return rows;
  let [k, ...rest] = groupKeys;
  return mapValues(groupBy(rows as T[], k as keyof T), (value: T[]) =>
    groupRecursive(value, rest)
  ) as any;
};

const DataGrid = <T extends unknown>({
  rows,
  columns,
  keyGetter,
  noRowsComponent,
  groupKeys,
  hideHeader
}: GridProps<T>) => {
  const [sort, setSort] = useState<Record<keyOrNothing<T>, SortState>>(
    {} as Record<keyOrNothing<T>, SortState>
  );
  const [sortedRows, setSortedRows] = useState<T[]>(rows);

  const [groupedRows, setGroupedRows] = useState<GroupedRows<T>>({});

  useEffect(() => {
    if(Object.keys(sort).length > 0) {
      setSortedRows(orderBy(
        rows,
        Object.keys(sort),
        Object.values(sort).map(mapSortState)
      ) as T[])
    } else {
      setSortedRows(rows);
    }
  }, [rows, sort]);

  useEffect(() => {
    if (groupKeys && groupKeys.length > 0) {
      const res = groupRecursive(sortedRows, groupKeys) as GroupedRows<T>;
      setGroupedRows(res);
    }
  }, [groupKeys, sortedRows]);

  return (
    <Box width="100%" component={Paper}>
      <Box
        component="table"
        sx={{
          width: "100%",
          maxHeight: "100%",
          borderCollapse: "separate",
          borderSpacing: 0,
        }}
      >
        <Box
          sx={{
            display: hideHeader ? "none" : "table-header-group",
            height: 50,
            position: "sticky",
            top: 0,
            backgroundColor: (_) => _.palette.background.default,
            zIndex: 10,
            th: {
              borderTop: "1px solid",
              borderRight: "1px solid",
              borderBottom: "2px solid",
              borderColor: (_) => _.palette.divider,
            },
            "th:first-of-type": {
              borderLeft: "1px solid",
              borderColor: (_) => _.palette.divider,
            },
          }}
          component="thead"
        >
          <Header sort={sort} setSort={setSort} columns={columns} />
        </Box>
        <Box
          component="tbody"
          maxHeight="100%"
          overflow="auto"
          sx={{
            td: {
              borderBottom: "1px solid",
              borderRight: "1px solid",
              borderColor: (_) => _.palette.divider,
            },
            "td:first-of-type": {
              borderLeft: "1px solid",
              borderColor: (_) => _.palette.divider,
            },
          }}
        >
          {rows.length > 0 ? (
            groupKeys && groupKeys.length <= 0 ? (
              (sortedRows).map((row) => (
                <Box key={`row-${keyGetter(row)}`} component="tr">
                  <Row keyGetter={keyGetter} row={row} columns={columns} />
                </Box>
              ))
            ) : (
              <GroupedRowsComponent
                sort={sort}
                setSort={setSort}
                noRowsComponent={noRowsComponent}
                groupKeysOriginal={groupKeys}
                groupKeys={groupKeys as Array<keyof T>}
                groupedRows={groupedRows}
                keyGetter={keyGetter}
                columns={columns}
              />
            )
          ) : (
            <Box component="tr">
              <Box component="td" colSpan={columns.length}>
                <Box alignItems="center" justifyContent="center" display="flex">
                  {noRowsComponent ? noRowsComponent : "No Rows"}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default DataGrid;
