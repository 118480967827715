import { Box } from "@mui/material";
import { getTranslation } from "common";
import { useTranslations } from "hooks";
import React from "react";

const PrivacyAndPolicy = () => {
  const translations = useTranslations();

  return (
    <React.Fragment>
      <Box
        dangerouslySetInnerHTML={{
          __html: getTranslation(translations, "register.content.privacy_policy"),
        }}
      />
    </React.Fragment>
  );
};

export default PrivacyAndPolicy;
