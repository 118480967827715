import { AxiosRequestConfig } from "axios";

// const getSelectedLanguage = () => {
//     if (translation.length) {
//       return translation[0].localization;
//     }
//     return navigator.language.split("-")[0];
//   };

const jwtInterceptor = (config: AxiosRequestConfig<any>) => {
  if (config.headers) {
    config.headers["Accept-Language"] = navigator.language.split("-")[0];
  }
  return config;
};
export default jwtInterceptor;
