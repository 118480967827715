import React from 'react';
import { Box } from '@mui/material';
import { RowCellProps } from './types';

const renderCell = <T extends unknown>({ value, row, column }: RowCellProps<T>) => {
  return (
    <Box sx={{ 
      borderRadius: 0,
      p: 1,
      width: "100%",
    }}>
      {value}
    </Box>
  )
}

export default renderCell