import React from "react";
import {
  Box,
  TextField,
  Button,
  Autocomplete,
  Paper,
} from "@mui/material";
import { getTranslation } from "common";
import { useTranslations } from "hooks";

interface ActivityFilterProps {
  selectedActivities: string[];
  onActivityChange: (activities: string[]) => void;
  options: string[];
}

const ActivityFilter: React.FC<ActivityFilterProps> = ({
  selectedActivities,
  onActivityChange,
  options,
}) => {
  const translations = useTranslations();
  // const [showAllActivities, setShowAllActivities] = useState(false);

  const handleActivityChange = (_: any, newActivities: string[]) => {
    onActivityChange(newActivities);
  };

  return (
<Box minWidth={200} maxWidth={260} marginBottom={2}>
    <Autocomplete
      multiple
      options={options}
      value={selectedActivities}
      onChange={handleActivityChange}
      freeSolo
      PaperComponent={(props) => (
        <Paper
          {...props}
          style={{
            maxHeight: "200px",
            overflowY: "auto",
          }}
        />
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={getTranslation(translations, "generic.search.activity")}
          variant="outlined"     
          size="small"
        />
      )}
    />
      {/* {options.length > 5 && (
        <Button
          onClick={() => setShowAllActivities(!showAllActivities)}
          variant="text"
          sx={{ mt: 1 }}
        >
          {showAllActivities
            ? getTranslation(translations, "generic.show.less")
            : getTranslation(translations, "generic.show.all")}
        </Button>
      )} */}
    </Box>
  );
};

export default ActivityFilter;