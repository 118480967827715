import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Divider,
  Stack,
  TextField,
} from "@mui/material";
import { useTranslations, useForm, useSnackbar } from "hooks";
import { getTranslation } from "common";
import { isEmail, isRequired, Validation } from "hooks/useForm";
import { usersApi } from "api";
import AuthFlowLayout from "components/layouts/AuthFlowLayout";
import PasswordImage from "image/password.png";
import FlowentiLogo from "image/Logo.svg";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const translations = useTranslations();
  const { sendSnack } = useSnackbar();

  const validations: Validation[] = [
    ({ email }) =>
      isRequired(email) || {
        email: getTranslation(translations, "register.valid.Error_after_at"),
      },
    ({ email }) =>
      isEmail(email) || {
        email: getTranslation(translations, "register.valid.Error_after_at"),
      },
  ];

  const { isValid, values, errors, touched, changeHandler } = useForm(
    {
      email: "",
    },
    validations
  );

  const submitData = async () => {
    const { email } = values;
    try {
      const createRes = await usersApi.post("forgotpassword", {
        email,
      });
      sendSnack({
        type: "success",
        message: `${createRes.data.responsemessage}`,
      });
      navigate("/login");
    } catch {
    } finally {
    }
  };

  return (
    <AuthFlowLayout
      rightAltImage="right image alt"
      rightImage={PasswordImage}
      title="Flowenti"
      image={FlowentiLogo}
      alt="image logo"
      cardTitle={getTranslation(translations, "forgotpassword.email.label")}>
      <Stack spacing={2}>
        <Stack spacing={1}>
          <TextField
            id="email-for-password"
            size="small"
            label={getTranslation(translations, "user.email.label")}
            type="email"
            autoComplete="off"
            fullWidth
            value={values.email}
            helperText={touched.email && errors.email}
            error={Boolean(touched.email && errors.email)}
            onChange={({ target }) => changeHandler("email", target.value)}
          />
        </Stack>
        <Button
          size="small"
          variant="contained"
          onClick={submitData}
          id="sign-in-button"
          disabled={!isValid}
        >
          {getTranslation(translations, "forgotpassword.button.label")}
        </Button>
        <Divider flexItem />
        <Button size="small" component={Link} to="/login" variant="text" color="inherit">
          {getTranslation(
            translations,
            "forgotpassword.link.returntologin"
          )}
        </Button>
      </Stack>
    </AuthFlowLayout>
  );
};

export default ForgotPassword;
