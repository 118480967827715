import React from "react";
import { LabelData } from "./label_Interface";
import { Box, Tooltip, Typography } from "@mui/material";
import { Label } from "./Label";

interface LabelsProps {
  labels: LabelData[];
  inCard?: boolean;
  labelCompleteMode: boolean;
}

const Labels: React.FC<LabelsProps> = ({ labelCompleteMode, labels, inCard = false }) => {

  const limitNeeded = labels.length >= 2 && inCard;
  const visibleLabelsCount = limitNeeded ? 2 : labels.length;
  const remainingsLabelsCount = labels.length - visibleLabelsCount;
  
  return (
    <Box
      display="flex"
      alignItems="center"
      gap={1}
      flexWrap={inCard ? "nowrap" : "wrap"}
      rowGap={1}
    >
      {labels.slice(0, visibleLabelsCount).map((tag) => (
        <Label labelCompleteMode={labelCompleteMode} key={`label-${tag.id}`} name={tag.name} color={tag.css} />
      ))}
      {remainingsLabelsCount > 0 && (
        <Tooltip title={labels.slice(visibleLabelsCount).map(tag => <React.Fragment key={`tooltip-tag-${tag.id}`}>{tag.name}<br></br></React.Fragment>)}>
          <Typography>+{remainingsLabelsCount}</Typography>
        </Tooltip>
      )}
    </Box>
  );
};

export default Labels;
