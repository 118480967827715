import React, { useEffect, useState } from "react";
import { Search } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";

export interface SearchBarProps {
  placeholder: string;
  setFilter: any;
  id: string;
}

const SearchBar: React.FC<SearchBarProps> = ({
  placeholder,
  setFilter,
  id,
}) => {
  const [value, setValue] = useState("");
  useEffect(() => {
    setFilter(value);
    // setFilter in array dependencies will trigger too many time the useEffect and cause bug on search
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <TextField
      id={id}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Search />
          </InputAdornment>
        ),
        style: {
          backgroundColor: "white", // aggiunge uno sfondo bianco
        },
      }}
      fullWidth
      size="small"
      label={placeholder}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
};

export default SearchBar;
