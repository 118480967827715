import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { Paper } from '@mui/material';
import { HEADER_HEIGHT, DRAWER_WIDTH, CLOSE_SIDEBAR_WIDTH } from 'common/theme';
//# This is a series of utility for sidebar style copied and pasted from
//# https://mui.com/material-ui/react-drawer/#mini-variant-drawer
// with some fix for our use case

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: CLOSE_SIDEBAR_WIDTH,
  [theme.breakpoints.up('sm')]: {
    width: CLOSE_SIDEBAR_WIDTH,
  },
});

export const SidebarHeader = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: 0,
  height: '48px!important',
  // backgroundColor: "#f4f5f7",
  justifyContent: 'space-between', //# this is an from the original
  paddingRight: theme.spacing(2), //# this is an from the original
  paddingLeft: 5, //# this is an from the original
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  minHeight: `${HEADER_HEIGHT}!important`,
}));

export const Sidebar = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);
