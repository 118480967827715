import {
  Dialog,
  DialogContent,
  FormHelperText,
  Stack,
} from "@mui/material";
import { PrimitiveUser } from "model/userModel";
import React, { useState } from "react";
import AvatarRem from "./AvatarRem";

interface UserAvatarProps {
  user: PrimitiveUser | null;
  showUsername?: boolean;
  disableHugePicture?: boolean;
}
const UserAvatar: React.FC<UserAvatarProps> = ({
  user,
  showUsername = false,
  disableHugePicture = false,
}) => {
  const [showHugePhoto, setShowHugePhoto] = useState(false);
  return user && (
    <React.Fragment>
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <AvatarRem
          onClick={(e) => { e.stopPropagation(); user?.picture && !disableHugePicture && setShowHugePhoto(true) }}
          sx={{ width: 25, height: 25, cursor: user?.picture ? "pointer" : "default" }}
          icon={user?.picture || ""}
        >
          {user.firstname && user?.firstname[0]}
          {user.lastname && user?.lastname[0]}
        </AvatarRem>
        {showUsername && (
          <FormHelperText>{`${user?.firstname} ${user?.lastname}`}</FormHelperText>
        )}
      </Stack>
      <Dialog open={showHugePhoto} onClose={() => setShowHugePhoto(false)}>
        <DialogContent sx={{ p: 0 }}>
          <AvatarRem
            variant="rounded"
            sx={{ width: 260, height: 260 }}
            icon={user?.picture || ""}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default UserAvatar;
