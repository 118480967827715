import React, { useEffect, useState } from "react";
import { MenuItem, SelectChangeEvent, TextField, Stack } from "@mui/material";
import { addFlowNodeOptions } from "Redux/processReducer";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import {
  ProcessModelDataTypes,
  ExtensionFlownodesType,
  ExtensionSwimLanesType,
} from "../../../../../../../../hooks/useBpmnModeler/useBpmnJsModeler";
import {
  ExtensionElementType,
  LinkableMenuOptionTypes,
} from "../../../../../../../../model/processModel";
import { getTranslation } from "common";
import { useSnackbar, useTranslations } from "hooks";
import { processmodelsApi } from "api";

interface RunTimeAssignmentFieldsTypes {
  element: any;
  shouldShowRunTimeAssignmentModeForLane: () => boolean;
  shouldShowRunTimeAssignmentMode: () => boolean;
  modeler: any;
  // modeler: BpmnModeler;
  processModelData: ProcessModelDataTypes;
  getRequestBodyForExtensionElements: () => Array<ExtensionElementType>;
  setProcessModelData: (data: ProcessModelDataTypes) => void;
  // getSelectedLanguage: () => string;
  openDiagram: (
    xml: string,
    shouldShowInTheViewPort: boolean,
    onSuccess?: () => void
  ) => void;
}

interface DefenitionOptionTypes {
  id: number;
  description: string;
}

const RunTimeAssignmentFields = (props: RunTimeAssignmentFieldsTypes) => {
  const {
    element,
    shouldShowRunTimeAssignmentModeForLane,
    shouldShowRunTimeAssignmentMode,
    modeler,
    processModelData,
    getRequestBodyForExtensionElements,
    setProcessModelData,
    // getSelectedLanguage,
    openDiagram,
  } = props;
  const dispatch = useDispatch();
  const translations = useTranslations();
  const flowNodeOptions = useSelector(
    (state: RootState) => state.process.flowNodeOptions
  );
  const [definitionOptions, setDefinitionOptions] = useState<
    Array<DefenitionOptionTypes>
  >([]);
  const [selectedActivityOption, setSelectedActivityOption] =
    useState<number>(0);
  const [selectedDefinitionOption, setSelectedDefinitionOption] =
    useState<string>("");
  const { sendSnack } = useSnackbar();
  const [linkedDesignerId, setLinkedDesignerId] = useState<string>("");

  const onChangeSwimlaneDefinitionOption = async (
    event: SelectChangeEvent<string>
  ) => {
    setSelectedDefinitionOption(event.target.value);
    const { xml } = await modeler
      .saveXML()
    try {
      const { data } = await processmodelsApi.post(
        `/propertyeditor/swimlane?currentswimlane=${element.id}&option=${event.target.value}`,
        {
          ...processModelData,
          model: xml,
          extensionelements: getRequestBodyForExtensionElements(),
        }
      );
      const { properties } = data;
      setProcessModelData(properties);
      openDiagram(properties.model, false, onSuccessOpenDiagram);
    } catch (e: any) {
      console.error(e);
    }
  };

  const getDefinitionOptions = async () => {
    try {
      const { data } = await processmodelsApi
        .get(`/propertyeditor/optiondefinition`)
      setDefinitionOptions(data.options);
      const selectedExtensionSwimLaneElement:
        | ExtensionSwimLanesType
        | undefined = processModelData.extensionswimlanes?.find(
          (data: ExtensionSwimLanesType) => {
            return element.id === data.source;
          }
        );
      if (selectedExtensionSwimLaneElement) {
        setSelectedDefinitionOption(
          selectedExtensionSwimLaneElement.assignmentmode.toString()
        );
      }
    } catch {
    } finally {
    }
  };

  useEffect(() => {
    if (element.type === "bpmn:Lane") getDefinitionOptions();
    else {
      const flowNodeOfCurrentElement: ExtensionFlownodesType | undefined =
        processModelData.extensionflownodes?.find(
          (data: ExtensionFlownodesType) => {
            return data.source === element.id;
          }
        );
      if (flowNodeOfCurrentElement) {
        setSelectedActivityOption(1);
        setLinkedDesignerId(flowNodeOfCurrentElement.linkedto);
      }
    }
    // eslint-disable-next-line
  }, []);

  const onChangeActivitiesDefenitionsOption = async (
    e: SelectChangeEvent<string>
  ) => {
    if (modeler) {
      const value = Number(e.target.value);
      setSelectedActivityOption(value);

      if (value === 1) {
        const { xml } = await modeler
          .saveXML()
        await processmodelsApi
          .post(`sameasthis/currentflownode/${element.id}`, {
            ...processModelData,
            model: xml?.replaceAll("#ff0000", "#000000"),
            extensionelements: getRequestBodyForExtensionElements(),
          })
          .then(
            ({ data }: { data: { tasks: Array<LinkableMenuOptionTypes> } }) => {
              if (data.tasks && data.tasks.length)
                dispatch(
                  addFlowNodeOptions({ id: element.id, data: data.tasks })
                );
            }
          )
          .catch((e: any) => {
            console.error(e);
          });
      } else {
        onChangeRemoveDesignerId();
      }
    }
  };

  const onSuccessOpenDiagram = () => {
    let elementRegistry = modeler.get("elementRegistry");
    let selectedElement: any = elementRegistry.get(element.id);
    let selection: any = modeler.get("selection");
    selection.select(selectedElement);
    //save refs enable
    // pub ref disable
    // depublish disabled too
    // NOTE: inorder to save the new diagram enable save btn and disable publish btn and depublish btn
    // saveBtnRef.current.classList.remove("save-btn-disable");
    // saveBtnRef.current.classList.add("save-btn-enable");

    // publishBtnRef.current.classList.remove("publish-btn-enable");
    // publishBtnRef.current.classList.add("publish-btn-disable");
    // handleDepublishing(false);
    sendSnack({
      message: getTranslation(
        translations,
        "generic.successful_assignment_mode_update.caption"
      ),
      type: "success",
    });
  };

  const onChangeLinkedDesignerId = async (e: SelectChangeEvent<string>) => {
    if (modeler) {
      setLinkedDesignerId(e.target.value);
      const { xml } = await modeler
        .saveXML()
      // .then((data: { xml: string | undefined }) => data.xml)
      // .then((model: string | undefined) => model);
      try {
        const { data }: { data: ProcessModelDataTypes } =
          await processmodelsApi.put(
            `/sameasthis/currentflownode/${element.id}?linkedto=${e.target.value}`,
            {
              ...processModelData,
              model: xml?.replaceAll("#ff0000", "#000000"),
              extensionelements: getRequestBodyForExtensionElements(),
            }
          );
        setProcessModelData(data);

        openDiagram(data.model, false, onSuccessOpenDiagram);
      } catch (e) {
        console.error(e);
      }
      return;
    }
  };

  const onChangeRemoveDesignerId = async () => {
    const { xml } = await modeler
      .saveXML()
    // .then((data: { xml: string | undefined; }) => data.xml)
    // .then((model: string | undefined) => model);
    try {
      const { data }: { data: ProcessModelDataTypes } =
        await processmodelsApi.put(
          `/sameasthis/currentflownode/${element.id}`,
          {
            ...processModelData,
            model: xml?.replaceAll("#ff0000", "#000000"),
            extensionelements: getRequestBodyForExtensionElements(),
          }
        );
      setProcessModelData(data);
      openDiagram(data.model, false, onSuccessOpenDiagram);
    } catch (e) {
      console.error(e);
    }
    return;
  };

  if (
    !(
      shouldShowRunTimeAssignmentModeForLane() ||
      shouldShowRunTimeAssignmentMode()
    )
  ) {
    return null;
  }
  return (
    <Stack spacing={2}>
      <TextField
        id="taskdiassociazione-btn"
        select
        size="small"
        fullWidth
        label={getTranslation(
          translations,
          shouldShowRunTimeAssignmentModeForLane()
            ? "pm.propertyeditor.specificproperties.label.runtime_assignment_mode"
            : "pm.propertyeditor.specificproperties.label.task_association"
        )}
        SelectProps={{
          displayEmpty: true,
        }}
        onChange={
          shouldShowRunTimeAssignmentModeForLane()
            ? (e) =>
              onChangeSwimlaneDefinitionOption(e as SelectChangeEvent<string>)
            : (e) =>
              onChangeActivitiesDefenitionsOption(
                e as SelectChangeEvent<string>
              )
        }
        value={
          shouldShowRunTimeAssignmentModeForLane()
            ? selectedDefinitionOption
            : selectedActivityOption
        }
      >
        {shouldShowRunTimeAssignmentModeForLane()
          ? definitionOptions.map((eachObj) => (
            <MenuItem id={`menu-associazione-lane-${eachObj.id}`} key={eachObj.id} value={eachObj.id}>
              {eachObj.description}
            </MenuItem>
          ))
          : [
            <MenuItem  key={"task-association-any-choice"} value={0}>
              {getTranslation(translations, "pm.propertyeditor.task.any")}
            </MenuItem>,
            <MenuItem id="sameAsThis-btn" key={"task-association-same-as-this"} value={1}>
              {getTranslation(
                translations,
                "pm.propertyeditor.task.same_as_this"
              )}
            </MenuItem>,
          ]}
      </TextField>

      {selectedActivityOption === 1 &&
        flowNodeOptions[element.id] &&
        flowNodeOptions[element.id].length && (
          <TextField
            id="menu-sameAsThis"
            size="small"
            fullWidth
            select
            SelectProps={{ displayEmpty: true }}
            value={linkedDesignerId}
            onChange={(e) =>
              onChangeLinkedDesignerId(e as SelectChangeEvent<string>)
            }
          >
            {flowNodeOptions[element.id]?.map((designerElement) => {
              return (
                <MenuItem
                  id={`${designerElement.designerid}-btn`}
                  key={designerElement.designerid}
                  value={designerElement.designerid}
                >
                  {`${designerElement.name} ${" "}${designerElement.designerid
                    }`}
                </MenuItem>
              );
            })}
          </TextField>
        )}
    </Stack>
  );
};

export default RunTimeAssignmentFields;
