import React from "react";
import { Box, Chip, Tooltip } from "@mui/material";

interface LabelProps {
  color?: string;
  name?: string;
  labelCompleteMode: boolean;
}

export const Label: React.FC<LabelProps> = ({
  color,
  labelCompleteMode,
  name,
}) => {
  if(!labelCompleteMode) {
    return <Tooltip title={name}>
      <Box sx={{ height: 5, borderRadius: 1, backgroundColor: color, width: 25 }}></Box>
    </Tooltip>
  }
  return (
    <Chip
      sx={{
        "& .MuiChip-label": {
          px: 1,
          py: 1,
        },
        fontSize: 13,
        height: 21,
        fontWeight: 500,
        borderRadius: 1,
        backgroundColor: "transparent",
        borderColor: color,
        borderWidth: 2,
        borderStyle: "solid",
        color: color,
        marginRight: "0.8em",
      }}
      label={name}
    />
  );
};
