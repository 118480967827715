import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Divider,
  IconButton,
  Menu,
  Stack,
  Typography,
} from "@mui/material";
import { TextField } from "@mui/material";
import { ArrowBack, CloseRounded } from "@mui/icons-material";
import { useSelectedOrganization, useSnackbar } from "../../hooks";
import { processesApi, tagsApi } from "../../api";
import { Box } from "@mui/system";
import { LabelData, LabelDataWithOptionalId } from "./label_Interface";
import { getTranslation } from "common";
import { useTranslations } from "hooks";
import LabelRow from "./LabelRow";
import LabelForm from "./LabelForm";

interface LabelManagerProps {
  idprocess?: number | undefined;
  open: HTMLElement | null;
  handleClose: () => void;
  checked?: LabelData[];
  setChecked?: React.Dispatch<React.SetStateAction<LabelData[]>>;
}

const LabelManager: React.FC<LabelManagerProps> = ({
  idprocess,
  open,
  handleClose,
  checked,
  setChecked,
}) => {
  const { sendSnack } = useSnackbar();
  const selectedOrganization = useSelectedOrganization();
  const organizationId = selectedOrganization?.id;
  const [labelList, setLabelList] = useState<LabelData[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [defaultCheck, setDefaultCheck] = useState<number[]>([]);
  const [checking, setChecking] = useState<number[]>([]);
  const [selectedLabel, setSelectedLabel] = useState<number | "new" | null>(
    null
  );
  const [labelForm, setLabelForm] = useState<LabelDataWithOptionalId | null>(
    null
  );
  const fetchLabels = useCallback(async () => {
    try {
      const promises = [tagsApi.get(`/${organizationId}/tags`)];
      if (idprocess) {
        promises.push(processesApi.get(`/${idprocess}/tags`));
      }
      const [response, fromProcess] = await Promise.all(promises);
      if (fromProcess) {
        setDefaultCheck(
          fromProcess.data.processtags.map(
            (tag: { id: number; name: string; color: string }) => tag.id
          )
        );
      }

      // Accessing 'tag' property of response data
      const labels = response.data.tag.map(
        (tag: { id: number; name: string; color: string }) => ({
          id: tag.id,
          name: tag.name,
          css: tag.color,
        })
      );
      setSelectedLabel(null);
      setLabelList(labels);
    } catch (err) {
      console.error(`Failed to fetch labels: ${err}`);
    } finally {
    }
  }, [idprocess, organizationId]);

  const translations = useTranslations();

  useEffect(() => {
    fetchLabels();
  }, [fetchLabels]);

  const handleCheckLabel = async (label: LabelData, newCheck: boolean) => {
    try {
      setChecking((_) => [..._, label.id]);
      if (newCheck) {
        if (idprocess) {
          await tagsApi.post(
            `/${organizationId}/processes/${idprocess}/tags/${label.id}`
          );
        } else {
          setChecked && setChecked((_) => [..._, label]);
        }
      } else {
        if (idprocess) {
          await tagsApi.delete(
            `/${organizationId}/processes/${idprocess}/tags/${label.id}`
          );
        } else {
          setChecked &&
            setChecked((_) => {
              const c_ = [..._];
              const c_idx = c_.findIndex((__) => __.id === label.id);
              c_.splice(c_idx, 1);
              return c_;
            });
        }
      }
    } catch {
    } finally {
      setChecking((_) => {
        const c_ = [..._];
        const c_idx = c_.findIndex((__) => __ === label.id);
        c_.splice(c_idx, 1);
        return c_;
      });
    }
  };

  const deleteLabel = async (labelId: number) => {
    try {
      await tagsApi.delete(`/${organizationId}/tags/${labelId}`);
      fetchLabels();
    } catch (err) {
      console.error(`Failed to delete label: ${err}`);
    }
  };
  const addLabel = async () => {
    if (labelForm) {
      try {
        const tagData = {
          name: labelForm.name,
          color: labelForm.css,
        };

        const { data } = await tagsApi.post(
          `/${organizationId}/tags`,
          tagData
        );

        fetchLabels();
        sendSnack({
          message: data.responsemessage,
          type: "success",
        });
      } catch {
      } finally {
      }
    } else {
    }
  };
  const editLabel = async () => {
    if (labelForm) {
      try {
        const tagData = {
          name: labelForm.name,
          color: labelForm.css,
        };

        const { data } = await tagsApi.put(
          `/${organizationId}/tags/${labelForm.id}`,
          tagData
        );

        fetchLabels();
        sendSnack({
          message: data.responsemessage,
          type: "success",
        });
      } catch {}
    } else {
    }
  };
  return (
    <Menu
      MenuListProps={{ sx: { px: 2 } }}
      anchorEl={open}
      // punto del bottone a cui deve essere ancorata l'origine della card
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      // origine della card che si apre
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(open)}
      onClose={handleClose}
    >
      <Stack
        sx={{
          px: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <IconButton
          sx={{
            opacity: 0,
            cursor: "default",
            pointerEvents: "none",
            ...(selectedLabel && {
              opacity: 1,
              pointerEvents: "auto",
              cursor: "pointer",
            }),
          }}
          size="small"
          onClick={() => setSelectedLabel(null)}
        >
          <ArrowBack fontSize="small" />
        </IconButton>
        <Typography variant="body1">
          {getTranslation(translations, "generic.labels.caption")}
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseRounded />
        </IconButton>
      </Stack>
      <Divider variant="middle" />
      {selectedLabel ? (
        <LabelForm
          onChange={(label) => setLabelForm(label)}
          defaultLabel={
            selectedLabel === "new"
              ? undefined
              : labelList.find((_) => _.id === selectedLabel)
          }
        />
      ) : (
        <Stack direction="column" sx={{ pt: 1 }} spacing={2}>
          <TextField
            value={searchValue}
            onKeyDown={e => e.stopPropagation()}
            onChange={({ target }) => setSearchValue(target.value)}
            label={getTranslation(
              translations,
              "generic.searchlabels.caption"
            )}
            variant="outlined"
            size="small"
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {Array.isArray(labelList) &&
              labelList
                .filter((x) =>
                  x.name.toLowerCase().includes(searchValue.toLowerCase())
                )
                .map((label: LabelData) => (
                  <Stack
                    key={label.id}
                    flex={1}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {idprocess ? (
                      <Checkbox
                        defaultChecked={defaultCheck.includes(label.id)}
                        disabled={checking.includes(label.id)}
                        onChange={(_e, newCheck) =>
                          handleCheckLabel(label, newCheck)
                        }
                        sx={{ color: (_) => _.palette.grey[600] }}
                      />
                    ) : (
                      <Checkbox
                        checked={
                          checked &&
                          checked.findIndex((_) => _.id === label.id) >= 0
                        }
                        onChange={(_e, newCheck) =>
                          handleCheckLabel(label, newCheck)
                        }
                        sx={{ color: (_) => _.palette.grey[600] }}
                      />
                    )}
                    <LabelRow
                      color={label.css}
                      name={label.name}
                      onEditClick={() => setSelectedLabel(label.id)}
                    />
                  </Stack>
                ))}
          </Box>
        </Stack>
      )}
      <Stack pt={1}>
        {selectedLabel ? (
          <Stack
            flex={1}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Button
              size="small"
              variant="outlined"
              component="label"
              onClick={selectedLabel === "new" ? addLabel : editLabel}
            >
              {getTranslation(
                translations,
                String(selectedLabel) !== "new"
                  ? "generic.button.savechanges"
                  : "generic.create.caption"
              )}
            </Button>
            {selectedLabel !== "new" && (
              <Button
                size="small"
                variant="outlined"
                color="error"
                component="label"
                onClick={() => deleteLabel(selectedLabel)}
              >
                {getTranslation(translations, "generic.delete.caption")}
              </Button>
            )}
          </Stack>
        ) : (
          <Button
            size="small"
            sx={{ flex: 1 }}
            variant="outlined"
            component="label"
            onClick={() => setSelectedLabel("new")}
          >
            {getTranslation(translations, "generic.createnewlabel.caption")}
          </Button>
        )}
      </Stack>
    </Menu>
  );
};

export default LabelManager;
