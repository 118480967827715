import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { addOrganization } from "../Redux/organizationReducer";
import { useTranslations, useForm } from "hooks";
import { getTranslation } from "common";
import { noTokenApi } from "api";
import { Validation, isRequired } from "hooks/useForm";
import { AuthContext } from "contexts";

const Organization = () => {
  // const [email, setEmail] = useState<string>("");
  const translations = useTranslations();
  const dispatch = useDispatch();
  const {
    state: { registerUser: user },
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const organizationInitial: string | undefined = getTranslation(
    translations,
    "register.label.edit_myOrganization"
  );
  const validations: Validation[] = [
    ({ organization }) =>
      isRequired(organization) || {
        organization: getTranslation(translations, "register.fieldrequired"),
      },
  ];
  const { values, changeHandler, errors, touched, isValid } = useForm(
    {
      organization: !organizationInitial ? "" : organizationInitial,
      emails: [],
    },
    validations
  );

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      e.preventDefault();
      setLoading(true);
      const result = await noTokenApi.post(
        `/organizations?create_template=true`,
        {
          name: values.organization,
        },
        { headers: { Authorization: `Bearer ${user?.jwt}` } }
      );

      const organizationId = result.data.id;

      dispatch(
        addOrganization({
          id: organizationId,
          name: values.organization,
        })
      );
      // await noTokenApi.post(
      //   `/organizations/${organizationId}/users/${user?.userId}`,
      //   undefined,
      //   { headers: { Authorization: `Bearer ${user?.jwt}` } }
      // );
      if (values.emails.length > 0) {
        await noTokenApi.post(
          `/users/${user?.userId}/organizations/${organizationId}/inviteusers`,
          {
            email: values.emails,
          }
        );
      }
      navigate("/login", { state: { fromRegister: true } });
    } catch {
      setLoading(false);
    } finally {
    }
  };
  return (
    <Box width="100%" height="100%">
      <Container
        maxWidth="sm"
        sx={{
          justifyContent: "center",
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Card component="form">
          <CardHeader
            title={getTranslation(
              translations,
              "register.label.organization_name_insert"
            )}
          />
          <CardContent>
            <Typography>
              {getTranslation(
                translations,
                "register.label.organization_name_describe"
              )}
            </Typography>

            <Grid container sx={{ pt: 2 }} spacing={2}>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  fullWidth
                  error={Boolean(touched.organization && errors.organization)}
                  helperText={touched.organization && errors.organization}
                  onChange={(e) =>
                    changeHandler("organization", e.target.value)
                  }
                  value={values.organization}
                  label={getTranslation(
                    translations,
                    "register.label.organization_set_name"
                  )}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <Autocomplete
                  multiple
                  id="tags-filled"
                  options={[]}
                  value={values.emails}
                  onChange={(e, newValue) => {
                    if (isEmail(email)) {
                      changeHandler("emails", newValue);
                      setEmail("");
                    } else {
                      setEmail(email);
                    }
                  }}
                  freeSolo
                  inputValue={email}
                  onInputChange={(e, value) => {
                    if (value.endsWith(",")) {
                      const _value = value.substring(0, value.length - 1);
                      if (isEmail(_value)) {
                        changeHandler("emails", [...values.emails, _value]);
                        setEmail("");
                      }
                    } else {
                      setEmail(value);
                    }
                  }}
                  renderTags={(value: readonly string[], getTagProps) =>
                    value.map((option: string, index: number) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  onBlur={() => {
                    if (email) {
                      if (isEmail(email)) {
                        changeHandler("emails", [...values.emails, email]);
                        setEmail("");
                      } else {
                        setEmail("");
                      }
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      label={getTranslation(
                        translations,
                        "register.label.send_invite_via_mail"
                      )}
                    />
                  )}
                />
              </Grid> */}
            </Grid>
          </CardContent>
          <CardActions>
            <Button
              id="organization-button"
              variant="contained"
              fullWidth
              type="submit"
              size="small"
              disabled={!isValid || loading}
              onClick={handleSubmit}
            >
              {getTranslation(translations, "register.label.button_next")}
            </Button>
          </CardActions>
        </Card>
      </Container>
    </Box>
  );
};

export default Organization;
