import React, { useState } from "react";
import {
  FormControl,
  Stack,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Button,
} from "@mui/material";
import { getTranslation } from "common";
import { useTranslations } from "hooks";

interface UserDataFilterProps {
  availableKeys: string[];
  selectedKey: string | null;
  onSelectKey: (key: string | null) => void;
}

const UserDataFilter: React.FC<UserDataFilterProps> = ({
  availableKeys,
  selectedKey,
  onSelectKey,
}) => {
  const translations = useTranslations();
  const [showAllKeys, setShowAllKeys] = useState(false);
  const displayedKeys = showAllKeys ? availableKeys : availableKeys.slice(0, 5);

  const toggleKey = (key: string | null) => {
    if (selectedKey === key) {
      key = null;
    }
    onSelectKey(key);
  };

  return (
    <FormControl component="fieldset">
      <Stack direction="column">
        {displayedKeys.map((key) => (
          <Tooltip title={key} key={key}>
            <FormControlLabel
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                wordBreak: "break-all",
                WebkitLineClamp: 1,
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                maxWidth: 150,
              }}
              slotProps={{
                typography: {
                  sx: {
                    maxWidth: 150,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  },
                },
              }}
              control={
                <Checkbox
                  id={`userdata-filter-${key}`}
                  checked={selectedKey === key}
                  onChange={() => toggleKey(key)}
                  color="primary"
                />
              }
              label={key}
            />
          </Tooltip>
        ))}
        {availableKeys.length > 5 && (
          <Button
            onClick={() => setShowAllKeys(!showAllKeys)}
            variant="text"
            sx={{ mt: 1, ml: 4 }}
          >
            {showAllKeys
              ? getTranslation(translations, "generic.show.less")
              : getTranslation(translations, "generic.show.all")}
          </Button>
        )}
      </Stack>
    </FormControl>
  );
};

export default UserDataFilter;
