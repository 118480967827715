import { Fade, Stack, Tab, Tabs } from "@mui/material";
// import { organizationsApi, usersApi } from "api";
import { getTranslation, OutletType } from "common";
import { useTranslations } from "hooks";
import React, {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { useOutletContext } from "react-router-dom";
import SearchBar from "./SearchBar";
// import FastCreation from "./FastCreation";
import GroupsPanel from "./GroupsPanel";
import RolesPanel from "./RolesPanel";
import UsersPanel from "./UsersPanel";
import UserSelectedPanel from "./UserSelectedPanel";
// import { isEmail } from "hooks/useForm";
import { User } from "./UserItem";
import GroupSelectedPanel from "./GroupSelectedPanel";
import RoleSelectedPanel from "./RoleSelectedPanel";
import RobotsPanel from "./RobotsPanel";

interface ChoosePanelProps {
  administrator: boolean,
  organizationId: number;
  inMobile?: boolean;
  setUserSelected: React.Dispatch<React.SetStateAction<User | null>>;
  userSelected: User | null;
  unit: number;
  setUnit: React.Dispatch<React.SetStateAction<number>>;
}
interface Ref {
  getGroups: Function | undefined;
  getUsers: Function | undefined;
  getRoles: Function | undefined;
  getInvitedUsers: Function | undefined;
  getRobots: Function | undefined;
  getUsersUntilPage: ((userSelected: User) => Promise<void>) | undefined;
}
const USERS = 1;
const GROUPS = 2;
const ROLES = 3;
const ROBOTS = 4;

const ChoosePanel = forwardRef<Ref, ChoosePanelProps>(
  ({ organizationId, inMobile, setUserSelected, userSelected, unit, setUnit, administrator }, ref) => {
    // to remove when implementing sse >>>>>>
    const groupRefreshRef = useRef<Function | undefined>(undefined);
    const userRefreshRef = useRef<Function | undefined>(undefined);
    const getUserUntilPageRef = useRef<((userSelected: User) => Promise<void>) | undefined>(undefined);
    const getInvitedUsersRef = useRef<Function | undefined>(undefined);
    const roleRefreshRef = useRef<Function | undefined>(undefined);
    const robotRefreshRef = useRef<Function | undefined>(undefined);
    // to remove when implementing sse <<<<<<
    const { setSecondFilter } = useOutletContext<OutletType>();
    // const user = useUser();
    // const { sendSnack } = useSnackbar();
    const [groupId, setGroupId] = useState<number | null>(null);
    const [roleId, setRoleId] = useState<number | null>(null);

    const translations = useTranslations();

    useEffect(() => {
      const reset = () => {
        setGroupId(null);
        setUserSelected(null);
        setRoleId(null);
      };
      reset();
      return reset;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unit, organizationId]);

    useImperativeHandle(
      ref,
      () => {
        if (organizationId && unit) {
          return {
            getUsersUntilPage: getUserUntilPageRef.current,
            getGroups: groupRefreshRef.current,
            getUsers: userRefreshRef.current,
            getInvitedUsers: getInvitedUsersRef.current,
            getRoles: roleRefreshRef.current,
            getRobots: robotRefreshRef.current,
          };
          //# just to refresh ref
        } else {
          return {
            getUsersUntilPage: undefined,
            getInvitedUsers: undefined,
            getGroups: undefined,
            getUsers: undefined,
            getRoles: undefined,
            getRobots:undefined,
          };
        }
      },
      [organizationId, unit]
    );

    // const handleGroupCreation = async (name: string) => {
    //   try {
    //     const createRes = await organizationsApi.post(`${organizationId}/groups`, {
    //       idorganization: organizationId,
    //       name,
    //       icon: null,
    //     });
    //     sendSnack({
    //       message: `${createRes.data.responsemessage}`,
    //       type: "success",
    //     });
    //     // to remove when implementing SSE
    //     groupRefreshRef.current && groupRefreshRef.current();
    //   } catch {
    //   } finally {
    //   }
    // };

    // const handleUserCreation = async (email: string) => {
    //   const createRes = await usersApi.post(
    //     `${user?.userId}/organizations/${organizationId}/inviteusers/dashboard`,
    //     { email: [email] }
    //   );
    //   sendSnack({
    //     message: `${createRes.data.responsemessage}`,
    //     type: "success",
    //   });
    //   // to remove when implementing SSE
    //   getInvitedUsersRef.current && getInvitedUsersRef.current();
    // };

    // const handleRoleCreation = async (name: string) => {
    //   try {
    //     const createRes = await organizationsApi.post(`${organizationId}/roles`, {
    //       idorganization: organizationId,
    //       name,
    //       icon: null,
    //     });
    //     sendSnack({
    //       message: `${createRes.data.responsemessage}`,
    //       type: "success",
    //     });
    //     // to remove when implementing SSE
    //     roleRefreshRef.current && roleRefreshRef.current();
    //   } catch {
    //   } finally {
    //   }
    // };

    return (
      <React.Fragment>
        <Stack height="100%" spacing={1}>
          <Stack spacing={2}>
            <Tabs value={unit} onChange={(e, newValue) => setUnit(newValue)}>
              <Tab
                id="tab-users"
                value={USERS}
                label={getTranslation(translations, "users.name")}
              />
              <Tab
                id="tab-groups"
                value={GROUPS}
                label={getTranslation(translations, "groups.name")}
              />
              <Tab
                id="tab-roles"
                value={ROLES}
                label={getTranslation(translations, "roles.name")}
              />
                 <Tab
                id="tab-robots"
                value={ROBOTS}
                label={getTranslation(translations, "robots.name")}
              />
            </Tabs>
            <SearchBar
              id="search-bar-panel"
              setFilter={setSecondFilter}
              placeholder={getTranslation(
                translations,
                "pm.organizationchart.ricerca.placeholder"
              )}
            />
          </Stack>
          <Fade
            style={{ overflow: "auto", height: "100%" }}
            unmountOnExit
            in={unit === USERS}
          >
            <Stack
              height="100%"
              display={unit !== USERS ? "none" : "flex"}
              spacing={2}
            >
              {/* {administrator && (
                <Box id="box-users" pt={1}>
                  <FastCreation
                    id="users"
                    validations={[({ value }) => isEmail(value) || { value: "Email non valida" }]}
                    label={getTranslation(translations, "user.email.label")}
                    onChange={handleUserCreation}
                  />
                </Box>
              )} */}
              <UsersPanel
                // to remove when implementing SSE
                ref={(ref) => {
                  userRefreshRef.current = ref?.getUsers
                  getUserUntilPageRef.current = ref?.getUserUntilPage
                  getInvitedUsersRef.current = ref?.getInvitedUsers
                }}
                onSelect={setUserSelected}
                selected={userSelected}
                organizationId={organizationId}
                administrator={administrator}
              />
            </Stack>
          </Fade>
          <Fade
            style={{ overflow: "auto", height: "100%" }}
            unmountOnExit
            in={unit === GROUPS}
          >
            <Stack
              height="100%"
              display={unit !== GROUPS ? "none" : "flex"}
              spacing={2}
            >
              {/* {administrator && (
                <Box id="box-groups" pt={1}>
                  <FastCreation
                    id="groups"
                    label={getTranslation(translations, "groups.edit.suggestnew")}
                    onChange={handleGroupCreation}
                  />
                </Box>
              )} */}
              <GroupsPanel
                // to remove when implementing SSE
                ref={(ref: any) => (groupRefreshRef.current = ref?.getGroups)}
                onSelect={
                  setGroupId
                }
                selectedId={groupId}
                organizationId={organizationId}
                administrator={administrator}
              />
            </Stack>
          </Fade>
          <Fade
            style={{ overflow: "auto", height: "100%" }}
            unmountOnExit
            in={unit === ROLES}
          >
            <Stack
              height="100%"
              display={unit !== ROLES ? "none" : "flex"}
              spacing={2}
            >
              {/* {administrator && (
                <Box id="box-roles" pt={1}>
                  <FastCreation
                    id="roles"
                    label={getTranslation(translations, "roles.edit.suggestnew")}
                    onChange={handleRoleCreation}
                  />
                </Box>
              )} */}
              <RolesPanel
                // to remove when implementing SSE
                ref={(ref) => (roleRefreshRef.current = ref?.getRoles)}
                onSelect={
                  setRoleId
                }
                selectedId={roleId}
                organizationId={organizationId}
                administrator={administrator}
              />
            </Stack>
          </Fade>
          <Fade style={{ overflow: "auto", height: "100%" }} unmountOnExit in={unit === ROBOTS}>
            <Stack height="100%" display={unit !== ROBOTS ? "none" : "flex"} spacing={2}>
              <RobotsPanel
                ref={(ref) => (robotRefreshRef.current = ref?.getRobots)}
                organizationId={organizationId}
                administrator={administrator}
              />
            </Stack>
          </Fade>
        </Stack>
        {!inMobile && userSelected && unit === USERS && (
          <UserSelectedPanel
            // to remove with sse
            userLoggedIsAdmin={administrator}
            refresh={() => userRefreshRef.current && userRefreshRef.current()}
            getUserUntilPage={getUserUntilPageRef.current}
            userSelected={userSelected}
            organizationId={organizationId}
          />
        )}
        {!inMobile && Boolean(groupId) && unit === GROUPS && (
          <GroupSelectedPanel
            groupId={groupId}
            organizationId={organizationId}
            administrator={administrator}
          />
        )}

        {!inMobile && Boolean(roleId) && unit === ROLES && (
          <RoleSelectedPanel
            roleId={roleId}
            organizationId={organizationId}
            administrator={administrator}
          />
        )}

      </React.Fragment>
    );
  }
);

export default ChoosePanel;