import { createSlice } from "@reduxjs/toolkit";
import message from "model/messageModel";

// const initialState: Array<message> = [];
const initialState: { [key: string]: string } = {};

export const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    removeMessage: (state) => {
      return (state = initialState);
    },
    addMessage: (state, action) => {
      if (action.payload.length > 0) {
        return state = (action.payload as message[]).reduce(
          (previous, current) => ({
            ...previous,
            [current.iddescribe]: current.message,
          }),
          {}
        );
        // return (state = action.payload);
      } else {
        return state;
      }
    },
  },
});

export default messageSlice.reducer;
export const { addMessage, removeMessage } = messageSlice.actions;
