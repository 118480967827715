import React from "react";
import {
  createSearchParams,
  useNavigate,
} from "react-router-dom";
import HomeImage from "image/home.png";
import { getTranslation } from "common";
import { Stack, TextField, Button, Typography } from "@mui/material";
import { useTranslations, useForm } from "hooks";
import { isEmail, isRequired, Validation } from "hooks/useForm";
import { noTokenApi } from "api";
import { Link } from "react-router-dom";
import OAuth2 from "components/OAuth2";
import { useContext } from "react";
import { AuthContext } from "contexts";
import { usersApi } from "api";
import FlowentiLogo from "image/Logo.svg"
import AuthFlowLayout from "components/layouts/AuthFlowLayout";

export default function Email() {
  const translations = useTranslations();
  // const dispatch = useDispatch();
  const {
    actions: { setRegisterUser, setUser },
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const enterWithGoogle = async (token: string, registrated: boolean) => {
    try {
      let result = await usersApi.get(`${token}`);
      if (registrated) {
        //se già registrato, vai alla dashboard
        setUser({
          jwt: token,
          userId: result.data.user.id,
          email: result.data.user.email,
          username: result.data.user.username,
          firstname: result.data.user.firstname,
          lastname: result.data.user.lastname,
          picture: result.data.user.picture,
          linkprofile: result.data.user.linkprofile,
          subscriptiontype: result.data.user.subscriptiontype,
          i18n: result.data.user.i18n,
          dob: result.data.user.dob,
        });
        navigate("/dashboard/organizations");
      } else {
        //se nuovo, imposta i dati per la registrazione
        setRegisterUser({
          userId: result.data.user.id,
          jwt: token,
          ...result.data.user,
        });
        navigate("/organization");
      }
    } catch (error) {
      console.error("Error during Google login:", error);
    }
  };

  const validations: Validation[] = [
    ({ email }) =>
      isRequired(email) || {
        email: getTranslation(translations, "register.valid.Error_after_at"),
      },
    ({ email }) =>
      isEmail(email) || {
        email: getTranslation(translations, "register.valid.Error_after_at"),
      },
  ];
  const { values, errors, touched, changeHandler, isValid } = useForm(
    { email: "" },
    validations
  );

  const submitEmail = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const response = await noTokenApi.get(
      `users/emailinplatform/${values.email}`
    );
    if (response.status === 200) {
      // dispatch(addEmail(values.email));
      //navigate(response.data.mailexist ? "/login" : "/registration", { state: { email: values.email } });
      navigate({
        pathname: response.data.mailexist ? "/login" : "/registration",
        search: createSearchParams({ email: values.email }).toString(),
      });
    }
  };

  return (
    <AuthFlowLayout
      rightAltImage="right image alt"
      rightImage={HomeImage}
      title="Flowenti"
      image={FlowentiLogo}
      alt="image logo"
      cardTitle={getTranslation(translations, "register.label.description1")}
    >
      <Stack
        spacing={2}
      >
        <Typography variant="body1" align="left">
          {getTranslation(translations, "register.label.description2")}
        </Typography>
        <Stack
          component="form"
          direction="column"
          spacing={2}
          width="100%"
          maxWidth="300px"
        >
          <TextField
            id="welcome-email"
            label={getTranslation(translations, "user.email.label")}
            value={values.email}
            onChange={({ target }) => changeHandler("email", target.value)}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            FormHelperTextProps={{
              sx: { fontSize: 11, width: 600, ml: 0 },
            }}
            type="email"
            aria-controls="email"
            size="small"
            sx={{ maxWidth: 300 }}
          />
          <Button
            id="welcome-button"
            type="submit"
            variant="outlined"
            onClick={submitEmail}
            sx={{
              height: 37,
              pointerEvents: !isValid ? "none" : "auto",
              fontWeight: isValid ? 400 : 600,
              transition: "all 0.3s ease",
            }}
          >
            {getTranslation(
              translations,
              "register.label.button_register_is_free"
            )}
          </Button>
          <OAuth2
            login={false}
            onGoogleConfirm={enterWithGoogle}
            showLoginButton={false}
          />
        </Stack>
        <Typography
          component={Link}
          to="/login"
          color="primary"
          sx={{
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
            },
            cursor: "pointer",
            marginTop: 2,
            fontSize: 14,
          }}
        >
          {getTranslation(
            translations,
            "register.label.ahref_login_have_account"
          )}
        </Typography>
      </Stack>
    </AuthFlowLayout>
  );
}
