import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'

interface ConfirmDialogProps{
    open: boolean,
    onClose: () => void,
    title: string,
    content: string,
    cancelText:string,
    confirmText:string,
    onConfirm:()=> void
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({open, onClose, title, content, cancelText, confirmText, onConfirm}) => {
  return (
    <Dialog
    open={open}
    onClose={onClose}
  >
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {content}
      </DialogContentText> 
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        {cancelText}
      </Button>
      <Button
      id="confirm-btn"
        onClick={onConfirm}
        color="primary"
      >
        {confirmText}
      </Button>
    </DialogActions>
  </Dialog>
  )
}

export default ConfirmDialog