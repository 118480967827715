import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  CircularProgress,
  Box,
} from "@mui/material";
import { getTranslation } from "common";
import { useTranslations } from "hooks";
import DialogHeader from "components/dialogs/DialogHeader";

interface RemoveUserPopUpProps {
  idLoggedUser: number;
  idUser?: number;
  handleRemoveUser: Function;
  shouldRemoveUserPopup: boolean;
  setShouldRemoveUserPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

const RemoveUserPopUp: React.FC<RemoveUserPopUpProps> = ({
  idLoggedUser,
  idUser,
  handleRemoveUser,
  shouldRemoveUserPopup,
  setShouldRemoveUserPopup,
}) => {
  const [isLoading, setLoading] = useState(false);
  const translations = useTranslations();
  return (
    <Dialog
      open={shouldRemoveUserPopup}
      onClose={() => {
        setShouldRemoveUserPopup(false);
      }}
      fullWidth={true}
      maxWidth="xs"
    >
      <DialogHeader
        onClose={() => {
          setShouldRemoveUserPopup(false);
        }}
      >
        {idUser === idLoggedUser
          ? getTranslation(translations, "generic.exit")
          : getTranslation(translations, "user.removeuser")}
      </DialogHeader>

      <DialogContent>
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : idUser === idLoggedUser ? (
          getTranslation(translations, "pm.confirm.leave_organization")
        ) : (
          getTranslation(translations, "user.removeuser")
        )}
        {/* {getTranslation(translations, "pm.dialog.save.body")} */}
      </DialogContent>
      <DialogActions>
        <Button
          id="leave-remove-user-btn"
          size="small"
          variant="contained"
          disabled={isLoading}
          onClick={() => {
            setShouldRemoveUserPopup(false);
          }}
        >
          {getTranslation(translations, "generic.button.cancel")}
        </Button>
        <Button
          id="remove-user-popup-btn"
          size="small"
          variant="contained"
          disabled={isLoading}
          onClick={async () => {
            setLoading(true);
            await handleRemoveUser(idUser);
            setLoading(false);
          }}
        >
          {idUser === idLoggedUser
            ? getTranslation(translations, "generic.exit")
            : getTranslation(translations, "generic.button.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveUserPopUp;
