import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import InputComment from "./InputComment";
import { User } from "./UserItem";
import { useTranslations, useUser } from "hooks";
import { formatDate, getTranslation } from "common/utilities";
import AvatarRem from "./AvatarRem";

export interface Comment {
  comment: string;
}
export interface CompleteComment extends Comment {
  idprocess: number;
  idflownode: number;
  flownodetype: number;
  iduser: number;
  user: User;
  username: string;
  startdoing: string;
  done: string;
  state: number;
  previous: number;
  idcomment: number;
  creation: string;
  idprocessmodel: number;
  name: string;
  designerid: string;
  idprocesstoken: number;
  idswimlane: number;
  id: number;
}
export interface CommentSectionProps {
  comments?: CompleteComment[];
  canWrite?: boolean;
  onComment?: (comment: Comment) => void;
}

const CommentsSection: React.FC<CommentSectionProps> = ({
  comments,
  canWrite,
  onComment,
}) => {
  const loggedUser = useUser();
  const [comment, setComment] = useState<string>("");
  const translations = useTranslations();

  const avatarStyles = {
    width: 32,
    height: 32,
    fontSize: 16,
  };

  return (
      <Stack direction="column" spacing={1}>
        {canWrite && (
            <Stack alignItems="center" direction="row" spacing={1}>
              <AvatarRem icon={loggedUser?.picture || ""} sx={avatarStyles}>
                {loggedUser?.firstname[0]}
                {loggedUser?.lastname[0]}
              </AvatarRem>
              <Box flex={1} >
                <Stack direction="column" spacing={1}>
                  <InputComment value={comment} onChange={setComment} />
                  <Stack direction="row" justifyContent="flex-end">
                    <Button
                        variant="outlined"
                        size="small"
                        disabled={!comment}
                        onClick={() => {
                          onComment && onComment({ comment });
                          setComment("");
                        }}
                    >
                      {getTranslation(translations, "generic.send.caption")}
                    </Button>
                  </Stack>
                </Stack>
              </Box>
            </Stack>
        )}

        {comments &&
            comments.map(({ comment, user, creation, id }) => (
                <React.Fragment key={`comment-${id}`}>
                  <Stack
                      id={`comment-${id}`}
                      alignItems="center"
                      direction="row"
                      spacing={2}
                  >
                    <Stack flex={1} spacing={1} direction="column">
                      <Stack alignItems="center" direction="row" spacing={1}>
                        <AvatarRem icon={user?.picture || ""} sx={avatarStyles}>
                          {user?.firstname[0]}
                          {user?.lastname[0]}
                        </AvatarRem>
                        <Typography
                            variant="subtitle2"
                            color="text.disabled"
                            fontSize={15}
                        >
                          {/* ho messo "?" perché mi andava in errore l'app, ma in "todo" non leggo ne il nome ne il cognome, in doing si */}
                          {user?.firstname} {user?.lastname}
                          {/* {user?.email} Niente, non va manco così */}
                          {/* Qual è il problema? Se posto un commento in doing, poi sposto la card in "todo", non leggo il nome di chi l'ha postato. */}
                          {", "}
                          {formatDate(creation)}
                        </Typography>
                      </Stack>
                      <Box
                       //   component={Paper}
                          sx={{
                            pl: 5,
                            pb: 2,
                          }}
                      >
                        <InputComment value={comment} readonly />
                      </Box>
                    </Stack>
                  </Stack>
                </React.Fragment>
            ))}
      </Stack>
  );
};

export default CommentsSection;



