import { createSlice, } from '@reduxjs/toolkit';
import ProcessTypes from '../model/processModel';

const initialState: ProcessTypes = {
  processModelList: [],
  processModelFolders: [],
  swimLanesById: {},
  flowNodeOptions: {},
  errors: [],
  expandedNodes: [],
};

export const processSlice = createSlice({
  name: 'process',
  initialState,
  reducers: {
    addProcessModelList: (state, action) => {
      const { processModelList } = action.payload;
      state.processModelList = [...processModelList, { id: NaN }];
    },
    addProcessModelFolders: (state, action) => {
      const payload = action.payload;
      state.processModelFolders = [...payload];
    },
    deleteProcessModelById: (state, action) => {
      const { id } = action.payload;
      const updatedList = [...state.processModelList];
      const index = updatedList.findIndex((model) => model.id === id);
      updatedList.splice(index, 1);
      state.processModelList = [...updatedList];
    },
    addErrors: (state, action) => {
      const { errors } = action.payload;
      // const updatedList = [...state.processModelList];
      // const index = updatedList.findIndex((model) => model.id === id);
      state.errors = [...errors];
      // state.processModelList[index].errors = [...errors];
    },
    addSwimLanesById: (state, action) => {
      state.swimLanesById = action.payload;
    },
    addFlowNodeOptions: (state, action) => {
      const payload = action.payload;
      state.flowNodeOptions = {
        ...state.flowNodeOptions,
        [payload.id]: payload.data,
      };
    },
    addExpandedNodes: (state, action) => {
      const payload = action.payload;
      state.expandedNodes = [...payload]
    },
  },
});

export default processSlice.reducer;
export const {
  addProcessModelList,
  addProcessModelFolders,
  deleteProcessModelById,
  addErrors,
  addSwimLanesById,
  addFlowNodeOptions,
  addExpandedNodes,
} = processSlice.actions;
