import { createSlice } from "@reduxjs/toolkit";

const prioritySlice = createSlice({
  name: "priority",
  initialState: "Normal",
  reducers: {
    setPriority: (state, action) => {
      return action.payload;
    },
  },
});

export default prioritySlice.reducer;
export const { setPriority } = prioritySlice.actions;
