import PopupMenuProvider from "bpmn-js/lib/features/popup-menu/ReplaceMenuProvider";
const SUPPORTED_ELEMENTS = [
  // ACTIVITY
  'replace-with-task',
  // "replace-with-user-task",
  // "replace-with-service-task",
  // "replace-with-send-task",
  // "replace-with-receive-task",
  // "replace-with-manual-task",
  // "replace-with-rule-task",
  // "replace-with-script-task",
  // "replace-with-call-activity",
  "replace-with-collapsed-subprocess",
  // "replace-with-expanded-subprocess",
  // START EVENT
  "replace-with-none-start",
  // "replace-with-none-intermediate-throwing",
  "replace-with-none-end", // For intermediate catch event too
  // "replace-with-message-start",
  "replace-with-timer-start",
  // "replace-with-conditional-start",
  // "replace-with-signal-start",
  // GATEWAY
  "replace-with-exclusive-gateway",
  "replace-with-parallel-gateway",
  // "replace-with-inclusive-gateway",
  // "replace-with-complex-gateway",
  // "replace-with-event-based-gateway",
  // POOL
  "toggle-participant-multiplicity",
  // TIMER START EVENT
  // "replace-with-none-start",
  // "replace-with-none-intermediate-throw",
  // "replace-with-none-end", // in start event too
  // "replace-with-message-intermediate-catch",
  // "replace-with-message-intermediate-throw",
  // "replace-with-escalation-intermediate-throw",
  // "replace-with-conditional-intermediate-catch",
  // "replace-with-link-intermediate-catch",
  // "replace-with-link-intermediate-throw",
  // "replace-with-compensation-intermediate-throw",
  // "replace-with-signal-intermediate-catch",
  // "replace-with-signal-intermediate-throw",
]
const _getPopupMenuEntries = PopupMenuProvider.prototype.getPopupMenuEntries;
// const _getPopupMenuHeaderEntries = PopupMenuProvider.prototype.getPopupMenuHeaderEntries;
PopupMenuProvider.prototype.getPopupMenuEntries = function (element) {
  const entries = _getPopupMenuEntries.apply(this, [element]);
  return element.type === "bpmn:IntermediateCatchEvent" ? [] : Object.fromEntries(Object.entries(entries).filter(([id]) => SUPPORTED_ELEMENTS.includes(id)));
};
PopupMenuProvider.prototype.getPopupMenuHeaderEntries = function (element) {
  // eslint-disable-next-line no-unused-vars
  // const entries = _getPopupMenuHeaderEntries.apply(this, [element]);
  return {};
};
export default PopupMenuProvider;