import React from "react";
import { ServerTag } from "model/activitiesModel";
import { Checkbox, Stack, FormControlLabel, Tooltip } from "@mui/material";
import { Label } from "./Label";

interface LabelSearchSelectProps {
  tagList: ServerTag[];
  idtags: number[];
  setSearchTags: React.Dispatch<React.SetStateAction<number[]>>;
}

const LabelSearchSelect: React.FC<LabelSearchSelectProps> = ({
  idtags,
  setSearchTags,
  tagList,
}) => {
  const toggleTag = (id: number) => {
    if (idtags.includes(id)) {
      setSearchTags(idtags.filter((tagId) => tagId !== id)); // se è nell'array rimuovi
    } else {
      setSearchTags([...idtags, id]); // sennò aggiungi
    }
  };

  return (
    <Stack direction="column">
      {tagList.map((tag) => (
        <Tooltip title={tag.tag} key={tag.idtag}>
          <FormControlLabel
            slotProps={{
              typography: {
                sx: {
                  maxWidth: 150,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },
              },
            }}
            control={
              <Checkbox
                id={`tag-filter-${tag.idtag}`}
                checked={idtags.includes(tag.idtag)}
                onChange={() => toggleTag(tag.idtag)}
                color="primary"
              />
            }
            label={<Label labelCompleteMode name={tag.tag} color={tag.color} />}
          />
        </Tooltip>
      ))}
    </Stack>
  );
};

export default LabelSearchSelect;
