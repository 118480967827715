import React from "react";
import { Box } from "@mui/material";
import DefaultRenderHeader from "./RenderHeader";
import { HeaderRowProps, SortState } from "./types";

const getNextSort = (actual: SortState) => {
  if (actual === undefined) {
    return "ASC";
  } else if (actual === "ASC") {
    return "DESC";
  } else if (actual === "DESC") {
    return undefined;
  }
};
const Header = <T extends unknown>({
  columns,
  setSort,
  sort,
}: HeaderRowProps<T>) => {
  return (
    <Box component="tr">
      {columns.map(({ RenderHeader, key, ...column }) => {
        const FinalRenderHeader = RenderHeader
          ? RenderHeader
          : DefaultRenderHeader;
        return (
          <Box
            key={`${String(key)}-column-head`}
            sx={{
              width: `${((column.flex || 1) * 100) / columns.length}%`,
              backgroundColor: (_) => _.palette.background.default,
              // borderWidth: "thin",
              // borderStyle: "solid",
              // borderColor: (_) => _.palette.divider,
            }}
            component="th"
          >
            <FinalRenderHeader
              onSort={() =>
                setSort((_) => {
                  const next = getNextSort(_[key]);
                  if (next === undefined) {
                    let copy = { ..._ };
                    delete copy[key];
                    return copy;
                  }
                  return { ..._, [key]: next };
                })
              }
              column={{ key, ...column }}
              sortDirection={sort[key]}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default Header;
