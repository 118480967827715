import React from "react";
import { Box, Divider, List, ListItem, Typography } from "@mui/material";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { Task } from "model/activitiesModel";
import { getTranslation } from "../common";
import { useTranslations } from "../hooks";

interface DonePanelProps {
  dragItem: Task | null;
}

const DonePanel: React.FC<DonePanelProps> = ({ dragItem }) => {
  const isDoingDragging = dragItem?.state === 1;
  const translations = useTranslations();
  return (
    <Box
      // component={GlassCard} 
      sx={{
        maxHeight: "90%",
        //display: 'flex',
        flexDirection: "column",
        borderRadius: "15px",
         backgroundColor: _ => _.palette.grey[200],
      }}
    >
      <Box>
        <Typography sx={{ flex: 1, textAlign: "start" }} variant="h6">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              ml: "10px",
              mt: "8px",
            }}
          >
            <div
              style={{
                height: "10px",
                width: "10px",
                borderRadius: "50%",
                backgroundColor: "rgba(139, 196, 138, 1)",
                marginRight: "8px",
              }}
            />
            {getTranslation(translations, "pm.label.process_status.done")}
          </Box>
        </Typography>
        <Divider
          sx={{
            height: "3px",
            borderRadius: "10px",
            backgroundColor: "rgba(139, 196, 138, 1)",
            mt: 3,
            mb: 3,
            width: "85%",
            mx: "auto",
          }}
        />
      </Box>

      <Droppable
        isCombineEnabled
        droppableId="done"
        mode="virtual"
        renderClone={({ dragHandleProps, draggableProps, innerRef }) => (
          <Box
            ref={innerRef}
            {...draggableProps}
            {...dragHandleProps}
            sx={{
              p: "4rem",
              borderRadius: (_) => _.shape.borderRadius,
              borderStyle: "dashed",
              borderWidth: 2,
              borderColor: "rgba(140,35,35,0.7)",
              transform: "none!important",
            }}
          >
            Clone
          </Box>
        )}
        isDropDisabled={!isDoingDragging}
      >
        {({ innerRef, droppableProps, placeholder }, dropSnapshot) => (
          <Box
            sx={{ height: "100%", overflow: "hidden" }}
            ref={innerRef}
            {...droppableProps}
          >
            <List
              disablePadding
              sx={{
                width: "100%",
                height: "100%",
                overflow: "auto",
                //borderRadius: (_) => _.shape.borderRadius,
              }}
            >
              {dragItem?.sequenceflows && dragItem?.sequenceflows.length > 0 ? (
                <React.Fragment>
                  {dragItem.sequenceflows.map((sequence, index: number) => (
                    <ListItem key={sequence.id}>
                      <Draggable
                        index={index}
                        isDragDisabled
                        draggableId={sequence.designerid}
                      >
                        {(seqProvided, seqSnapshot) => (
                          <Box
                            ref={seqProvided.innerRef}
                            {...seqProvided.draggableProps}
                            {...seqProvided.dragHandleProps}
                            sx={{
                              // width: 250,
                              width: "100%",
                              height: 140,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: (_) => _.shape.borderRadius,
                              borderStyle: "solid",
                              borderWidth: 2,
                              borderColor: "rgba(140,35,35,0.7)",
                              transform: "none!important",
                              background: sequence.isOver
                                ? (_) => _.palette.action.disabledBackground
                                : "inherit",
                            }}
                          >
                            {isDoingDragging && (
                              <Typography>{sequence.name}</Typography>
                            )}
                          </Box>
                        )}
                      </Draggable>
                    </ListItem>
                  ))}
                </React.Fragment>
              ) : (
                <Draggable index={0} draggableId="done" isDragDisabled>
                  {(provided, snapshot) => (
                    <ListItem>
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        sx={{
                          width: "100%",
                          height: 140,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: (_) => _.shape.borderRadius,
                          borderStyle: isDoingDragging ? "solid" : "dashed",
                          borderWidth: 2,
                          borderColor: "#888",
                          transform: "none!important",
                          background: dragItem?.isOverDone
                            ? (_) => _.palette.action.disabledBackground
                            : "inherit",
                        }}
                      >
                        <Typography>{getTranslation(translations, "generic.drop_here.caption")}</Typography>
                      </Box>
                    </ListItem>
                  )}
                </Draggable>
              )}
            </List>
          </Box>
        )}
      </Droppable>
    </Box>
  );
};

export default DonePanel;
