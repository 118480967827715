import { useCallback, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { findIndex, isArray, isObject, remove } from "lodash";
import { RootState } from "store";

import { organizationsApi } from "api";

import ProcessTypes, {
  ProcessModelFolderType,
  SelectedProcessModelType,
} from "../model/processModel";

import {
  addProcessModelList,
  addProcessModelFolders,
  addExpandedNodes,
} from "../Redux/processReducer";
import useSelectedOrganization from "./useSelectedOrganization";
import useSnackbar from "./useSnackbar";

interface handleUpdateTreeFunctionPropsType {
  parentId: Number | null;
  childId: string;
  childName?: string;
  isProcessModel?: Boolean;
  deleteProcessFromFolder?: Boolean;
}

const useTree = () => {
  const dispatch = useDispatch();
  const [selectedNode, setSelectedNode] = useState<Number | null>();

  const selectedOrganization = useSelectedOrganization();
  const { sendSnack } = useSnackbar();

  const {
    processModelList,
    processModelFolders,
    expandedNodes = [],
  }: ProcessTypes = useSelector((state: RootState) => state.process);

  const plainTree = useCallback(
    (
      folders: ProcessModelFolderType[],
      models: SelectedProcessModelType[],
      parent?: ProcessModelFolderType
    ) => {
      let results: Array<ProcessModelFolderType | SelectedProcessModelType> = [];
      if (folders) {
        for (let folder of folders) {
          if (folder.id) {
            if (parent) {
              results.push({ ...folder, idfolder: parent.id });
            } else {
              results.push(folder);
            }
            if (folder.children?.length) {
              results = results.concat(
                plainTree(folder.children, models, folder)
              );
            }
            const _models = models.filter((_) => _.idfolder === folder.id);
            if (_models.length) {
              results = results.concat(_models);
            }
          }
        }
      }
      return results;
    },
    []
  );
  const plain = useMemo(
    () =>
      processModelFolders && processModelFolders.length > 0
        ? plainTree(processModelFolders[0].children, processModelList).concat(
            (processModelList || []).filter((_) => !_.idfolder)
          )
        : [],
    [processModelFolders, processModelList, plainTree]
  );
  const getTreeNodes = async () => {
    if (!selectedOrganization || !selectedOrganization.id) {
      return;
    }

    const url = `/${selectedOrganization.id}/processmodels/v2`;

    try {
      const { data } = await organizationsApi.get(url);

      if (isArray(data["/"])) {
        dispatch(addProcessModelFolders(data["/"]));
      } else if (isObject(data["/"])) {
        dispatch(addProcessModelFolders([data["/"]]));
      }

      if (data.processmodels) {
        dispatch(
          addProcessModelList({ processModelList: [...data.processmodels] })
        );
      }
    } catch (error) {
      console.error("Getting error in processModels", error);
    }
  };

  const updateTree = async ({
    parentId,
    childId,
    childName,
    isProcessModel,
    deleteProcessFromFolder,
  }: handleUpdateTreeFunctionPropsType) => {
    //For updating the folder
    if (selectedOrganization) {
      if (childId && !isProcessModel) {
        try {
          // const { data } =
          await organizationsApi.put(
            `${selectedOrganization.id}/folders/${childId}`,
            {
              name: childName,
              parent_id: parentId,
            }
          );
          getTreeNodes();
          // if (data.success) {
          // }
        } catch (error) {
          console.error("error updating folder", error);
        }
      } else if (
        childId &&
        isProcessModel &&
        parentId &&
        !deleteProcessFromFolder
      ) {
        //For updating the process model into the folder
        try {
          if (String(parentId) !== String(childId)) {
            const { data } = await organizationsApi.put(
              `${selectedOrganization.id}/processmodels/${parseFloat(
                childId
              )}/folders/${parentId}`,
              {
                name: childName,
                parent_id: parentId,
              }
            );
            if (data.success) {
              getTreeNodes();
            }
          }
        } catch (error) {
          console.error("error updating process model", error);
        }
      } else if (
        childId &&
        isProcessModel &&
        parentId &&
        deleteProcessFromFolder
      ) {
        //To remove the process model from the folder
        try {
          const { data } = await organizationsApi.delete(
            `${selectedOrganization.id}/processmodels/${parseFloat(
              childId
            )}/folders/${parentId}`
          );
          if (data.success) {
            getTreeNodes();
          }
        } catch (error) {
          console.error("error", error);
        }
      }
    }
  };

  const createNewNode = async (
    folderName: string,
    successCallBack: (data:any) => void
  ) => {
    if (selectedOrganization) {
      try {
        const { data } = await organizationsApi.post(
          `${selectedOrganization.id}/folders`,
          {
            name: folderName,
            parent_id: selectedNode ? selectedNode : null,
          }
        );
        if (data.success) {
          getTreeNodes();
          if (successCallBack) {
            successCallBack(data);
          }
        }
      } catch (error) {
        console.error("Getting error in creating process model", error);
      }
    }
  };

  const updateNodeTitle = async (folderName: string, parentId?: Number) => {
    if (!folderName || folderName.length === 0 || !selectedNode) {
      return;
    }

    const updateObj: { name: string; parent_id?: Number } = {
      name: folderName,
    };

    if (parentId) {
      updateObj.parent_id = parentId;
    }
    if (selectedOrganization) {
      try {
        const { data } = await organizationsApi.put(
          `${selectedOrganization.id}/folders/${selectedNode}`,
          updateObj
        );
        if (data.success) {
          getTreeNodes();
        }
      } catch (error) {
        console.error("Error updating folder name", error);
      }
    }
  };

  const deleteNode = async (successCallBack: () => void) => {
    if (selectedOrganization) {
      try {
        const { data } = await organizationsApi.delete(
          `${selectedOrganization.id}/folders/${selectedNode}`
        );

        if (data.success) {
          sendSnack({
            message: `${data.responsemessage}`,
            type: "success",
          });
          getTreeNodes();
          if (successCallBack) {
            successCallBack();
          }
        }
        setSelectedNode(null);
      } catch (error) {
        console.error("Getting error in delete folder name", error);
        setSelectedNode(null);
      }
    }
  };

  const setExpandedNode = (nodeId: Number) => {
    if (expandedNodes === undefined) {
      return;
    }

    const nodeIndex = findIndex([...expandedNodes], (o) => {
      return o === `${nodeId}`;
    });
    if (nodeIndex === -1) {
      dispatch(addExpandedNodes([...expandedNodes, `${nodeId}`]));
    } else {
      const updatedArray = remove([...expandedNodes], (o) => {
        return o !== `${nodeId}`;
      });
      dispatch(addExpandedNodes(updatedArray));
    }
  };
  return {
    plain,
    processModelList,
    processModelFolders,
    selectedNode,
    expandedNodes,
    setSelectedNode,
    setExpandedNode,
    getTreeNodes,
    updateTree,
    createNewNode,
    updateNodeTitle,
    deleteNode,
  };
};

export default useTree;
