import { useCallback, useEffect, useState } from "react";
import { CodeResponse } from "@react-oauth/google";
import { appservicesApi } from "api";

interface Appservice {
  id: number;
  token: string;
  iduser: number;
  idappservice: number;
}
const useAppservices = () => {
  const [appservices, setAppservices] = useState<Record<number, string>>({});
  const getAppservices = useCallback(async () => {
    const { data } = await appservicesApi.get<Appservice[]>("");
    const _appservices: Record<number, string> = {};
    for (let item of data) {
      _appservices[item.idappservice] = item.token;
    }
    setAppservices(_appservices);
  }, []);

  useEffect(() => {
    getAppservices();
  }, [getAppservices]);

  const handleLinkAppservice = async (
    e: Omit<CodeResponse, "error" | "error_description" | "error_uri">, idappservice: number
  ) => {
    try {
      const { data } = await appservicesApi.post<Appservice>("", { ...e, idappservice, redirect: window.origin });
      await getAppservices();
      return data;
    } catch {
    } finally {
    }
  };

  return { appservices, handleLinkAppservice };
};

export default useAppservices;
