import { TreeItem } from "@mui/x-tree-view/TreeItem";

import {
  ProcessModelType,
  RevisionModelType,
  SelectedProcessModelType,
} from "model/processModel";

import ProcessModel from "../ProcessModel";
import { Folder, FolderOpen } from "@mui/icons-material";

import CustomTreeItem from "./CustomTreeItem";

import "./TreeView.css";
import React, { useState } from "react";
import { useDragItem, useTranslations } from "hooks";
import { getTranslation } from "common";

interface NodesProps {
  id: number;
  name: string;
  children?: readonly NodesProps[];
  processmodels?: ProcessModelType[];
}

interface handleUpdateTreeFunctionPropsType {
  parentId: Number | null;
  childId: string;
  childName?: string;
  isProcessModel?: Boolean;
}

interface PropType {
  selectedRevision: RevisionModelType | null;
  setSelectedRevision: React.Dispatch<
    React.SetStateAction<RevisionModelType | null>
  >;
  selectedProcessModel: SelectedProcessModelType | null;
  setSelectedProcessModel: React.Dispatch<
    React.SetStateAction<SelectedProcessModelType | null>
  >;
  nodes: NodesProps;
  highlighted: number | null;
  onExportClick: () => void;
  processModelList?: SelectedProcessModelType[];
  returnmodel: (
    model: SelectedProcessModelType,
    errorsInModel: Boolean
  ) => void;
  handleUpdateTree: ({
    parentId,
    childId,
    childName,
    isProcessModel,
  }: handleUpdateTreeFunctionPropsType) => void;
  handleUpdateFolderName?: (val: string, parentId?: Number) => void;
  handleOnClickDeleteFolderIcon?: (val: Number) => void;
}

const TreeItemComponent: React.FC<PropType> = ({
  nodes,
  selectedProcessModel,
  setSelectedProcessModel,
  selectedRevision,
  setSelectedRevision,
  onExportClick,
  processModelList,
  highlighted,
  returnmodel,
  handleUpdateTree,
  handleUpdateFolderName,
  handleOnClickDeleteFolderIcon,
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const translations = useTranslations();

  const { onDragStart, onDrag, onDragEnd, dragging, ref } = useDragItem({
    horizontal: false,
  });

  const handleDragStart = (
    e: React.DragEvent<HTMLLIElement>,
    itemId: Number,
    itemName: string
  ) => {
    onDragStart(e, (event) => {
      const obj = {
        childId: itemId,
        childName: itemName,
      };
      event.dataTransfer.setData("text/plain", JSON.stringify(obj));
    });
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };

  const handleDrop = (event: any, parentId: number) => {
    event.preventDefault();
    event.stopPropagation();
    const obj = JSON.parse(event.dataTransfer.getData("text/plain"));
    handleUpdateTree({
      parentId: parentId,
      childId: obj.childId,
      childName: obj.childName,
      isProcessModel: obj.isProcessModel || false,
    });
  };

  const children =
    nodes && nodes.children && Array.isArray(nodes.children)
      ? nodes.children
      : false;

  const contentProps = {
    isEditable,
    setIsEditable,
    handleUpdateFolderName: handleUpdateFolderName
      ? (val: string, parentId?: Number) =>
          handleUpdateFolderName(val, parentId)
      : false,
    handleOnClickDeleteFolderIcon: handleOnClickDeleteFolderIcon
      ? (val: Number) => handleOnClickDeleteFolderIcon(val)
      : false,
  };

  const handleUpdateChildrenFolderName = (
    folderName: string,
    parentId: Number
  ) => {
    if (handleUpdateFolderName) {
      handleUpdateFolderName(folderName, parentId);
    }
  };

  if (nodes && nodes.id && nodes.name) {
    return (
      <TreeItem
        ref={ref as any}
        sx={{
          background: (_) =>
            highlighted === nodes.id ? _.palette.action.selected : "unset",
        }}
        data-test-id={`folder-tree-item-${nodes.name}`}
        id={`folder-tree-item-${nodes.id}`}
        draggable={!isEditable}
        key={nodes.id}
        nodeId={`${nodes.id}`}
        label={`${nodes.name}`}
        onDragStart={(e) => {
          if (!isEditable) {
            handleDragStart(e, nodes.id, nodes.name);
          }
        }}
        onDrag={(e) => onDrag(e)}
        onDragEnd={(e) => onDragEnd(e)}
        onDragOver={(e) => {
          if (!isEditable && !dragging) {
            handleDragOver(e);
          }
        }}
        onDrop={(e: any) => handleDrop(e, nodes.id)}
        ContentProps={contentProps as any}
        ContentComponent={CustomTreeItem}
        collapseIcon={<FolderOpen />}
        expandIcon={<Folder />}
        className="treeItem"
      >
        {children &&
          children.map((node) => (
            <TreeItemComponent
              onExportClick={onExportClick}
              selectedProcessModel={selectedProcessModel}
              setSelectedProcessModel={setSelectedProcessModel}
              selectedRevision={selectedRevision}
              setSelectedRevision={setSelectedRevision}
              highlighted={highlighted}
              handleUpdateTree={handleUpdateTree}
              key={node.id}
              nodes={node}
              processModelList={processModelList}
              returnmodel={returnmodel}
              handleUpdateFolderName={(val) =>
                handleUpdateChildrenFolderName(val, nodes.id)
              }
              handleOnClickDeleteFolderIcon={handleOnClickDeleteFolderIcon}
            />
          ))}
        {processModelList &&
        processModelList.filter((_) => _.idfolder === nodes.id).length
          ? processModelList
              .filter((_) => _.idfolder === nodes.id)
              .map((model: SelectedProcessModelType, index: number) => (
                <ProcessModel
                  selectedProcessModel={selectedProcessModel}
                  setSelectedProcessModel={setSelectedProcessModel}
                  selectedRevision={selectedRevision}
                  setSelectedRevision={setSelectedRevision}
                  onExportClick={onExportClick}
                  highlighted={highlighted}
                  key={model.id}
                  model={model}
                  index={index}
                  parentId={nodes.id}
                  returnmodel={returnmodel}
                />
              ))
          : !(children && children.length) && (
              <TreeItem
                disabled
                nodeId={`empty-tree-item-for-folder-${nodes.id}`}
                label={getTranslation(translations, "generic.empty_folder.caption")}
                className="treeItem"
              />
            )}
      </TreeItem>
    );
  }
  return null;
};

export default TreeItemComponent;
