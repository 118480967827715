import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Collapse,
  Icon,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

export interface SideBarItemProps {
  logoSrc: any;
  isChild?: boolean;
  label: string;
  path?: string;
  children?: Omit<SideBarItemProps, "children">[];
  shouldShowLabel?: boolean;
  disabled?: boolean;
}
const SideBarItem: React.FC<SideBarItemProps> = ({
  logoSrc,
  label,
  path,
  isChild = false,
  children,
  shouldShowLabel = true,
  disabled = false,
}) => {
  const { pathname } = useLocation();

  const [selected, setSelected] = useState(false);
  const [openChildMenu, setOpenChildMenu] = useState<boolean>(false);

  useEffect(() => {
    setSelected(pathname === `/dashboard/${path}`);
  }, [pathname, path]);
  const itemButtonProps: any = {};
  if (children && children.length > 0) {
    itemButtonProps["onClick"] = (e: any) => setOpenChildMenu((_) => !_);
  } else {
    itemButtonProps["component"] = Link;
    itemButtonProps["selected"] = selected;
    itemButtonProps["to"] = `/dashboard/${path}`;
  }
  return (
    <React.Fragment>
      <ListItem
        id={`menu-list-item-${path}`}
        disablePadding
        sx={{
          borderRadius:0,
          display: "block",
          mt: isChild ? "0px" : "4px",
        }}
      >
        <ListItemButton
          disabled={disabled}
          {...itemButtonProps}
          // component={Link}
          // to={`/dashboard/${path}`}
          // selected={selected}
          sx={{
            border: "unset!important",
            minHeight: 48,
            justifyContent: shouldShowLabel ? "initial" : "center",
            px: 2,
            borderRadius: 0
            // "&.Mui-selected": {
            //   borderRadius: "0 33px 33px 0",
            // },
          }}
        >
          <ListItemIcon
            sx={{
              borderRadius: 0,
              // backgroundColor: "rgba(84, 189, 193, 0.3)",
              p: 1,
              minWidth: 0,
              mr: shouldShowLabel ? "1px" : "auto",
              justifyContent: "center",
              marginLeft: "4px",
            }}
          >
            <Tooltip placement="right" title={label}>
              <Icon color={"primary"}>{logoSrc}</Icon>
            </Tooltip>
          </ListItemIcon>
          <ListItemText
            primary={label}
            sx={{
              opacity: shouldShowLabel ? 1 : 0,
              transition: "all 0.6s ease-in-out",
              ml: !shouldShowLabel && children && children.length > 0 ? -4 : 1,
            }}
          />
          {children && children.length > 0 && (
            <ListItemSecondaryAction
              sx={{
                opacity: shouldShowLabel ? 1 : 0,
                transition: "all 0.6s ease-in-out",
              }}
            >
              {Boolean(openChildMenu) ? <ExpandLess /> : <ExpandMore />}
            </ListItemSecondaryAction>
          )}
        </ListItemButton>
      </ListItem>
      {children && children.length > 0 && (
        <Collapse
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          // anchorEl={openChildMenu}
          in={Boolean(openChildMenu)}
        // onClick={() => setOpenChildMenu(_ => !_)}
        // MenuListProps={{ dense: true }}
        // anchorOrigin={{
        //   vertical: "bottom",
        //   horizontal: "left",
        // }}
        // transformOrigin={{
        //   vertical: "top",
        //   horizontal: "left",
        // }}
        >
          <List sx={{ pl: 3, py: 0 }}>
            {children?.map((sidebarItemChild) => (
              <SideBarItem
                key={sidebarItemChild.path}
                isChild
                {...sidebarItemChild}
              />
            ))}
          </List>
        </Collapse>
      )}
    </React.Fragment>
  );
};

export default SideBarItem;
