import { TextField, Box, MenuItem } from "@mui/material";
import { Priority } from "hooks/usePriorities";

interface PrioritySelectProps {
  allPriorities: Priority[];
  onChange: (priority: Priority) => void;
  value: Priority | null;
  administrator?: boolean;
}

export const PrioritiesDropDown = ({
  allPriorities,
  onChange,
  value,
  administrator,
}: PrioritySelectProps) => {
  return !(allPriorities && allPriorities.length > 0) ? null : (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { width: '100%' },
      }}
      noValidate
      autoComplete="off"
      id="dropdown"
    >
      <TextField
        value={value ? value.name : ""}
        select
        sx={{ cursor: "pointer" }}
        size="small"
        disabled={!administrator}
        id="selectPriority"
      >
        {allPriorities.map((priority) => (
          <MenuItem
            key={priority.id}
            value={priority.name}
            onClick={() => onChange(priority)}
          >
            {priority.name}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};
