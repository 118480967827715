import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Resizable } from "re-resizable";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  useForm as useLightForm,
  useSelectedOrganization,
  useSnackbar,
  useTranslations,
} from "hooks";
//@ts-ignore
import { getBusinessObject } from "bpmn-js/lib/util/ModelUtil";
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
// import "bpmn-js-properties-panel/dist/assets/properties-panel.css";
import "bpmn-js/dist/assets/bpmn-js.css";
//@ts-ignore
import ContextPadProvider from "bpmn-js/lib/features/context-pad/ContextPadProvider";
import "diagram-js-minimap/assets/diagram-js-minimap.css";
import {
  SaveOutlined,
  SaveAsOutlined,
  VerifiedOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  CloudDoneOutlined,
  ChevronLeft,
  Settings,
  ImportExport,
} from "@mui/icons-material";
import UserModel from "model/userModel";
import "./NewProcessModule.css";
import {
  AssociationResponse,
  ExtensionElementType,
  // ProcessModelType,
} from "../../../../../model/processModel";
import PropertiesView from "./PropertyPanelEditor";
import useBpmnModeler from "../../../../../hooks/useBpmnModeler";
import SaveProcessModelPopUp from "../../components/SaveProcessModelPopUp";
import DropZoneElement from "./DropZoneElement";
import OrganizationPanel from "./OrganizationPanel";
import DialogHeader from "components/dialogs/DialogHeader";
import { Validation, isRequired } from "hooks/useForm";
import { CONSTANTS, getTranslation } from "common";
import { organizationsApi, processmodelsApi } from "api";
import { AssociationDialog, ProcessModelError } from "components";
import InputPopUp from "components/dialogs/InputPopUp";
import { clearLanes } from "common/utilities";
import { Robot } from "hooks/useRobots";

export interface UsersOfSelectedOrganization extends UserModel {
  id: number;
}

export interface GroupModel {
  idorganization: number;
  id: number;
  icon: string | null;
  name: string;
}

export interface RolesModel {
  name: string;
  id: number;
  icon: string | null;
  idorganization: number;
}

const _getContextPadEntries = ContextPadProvider.prototype.getContextPadEntries;
ContextPadProvider.prototype.getContextPadEntries = function (element: any) {
  const entries = _getContextPadEntries.apply(this, [element]);
  // yet to be determined
  delete entries["lane-divide-three"];
  delete entries["lane-divide-two"];

  return entries;
};

const NewProcessModule: React.FC = () => {
  const [selectedError, setSelectedError] = useState<number | null>(null);

  const navigate = useNavigate();
  const { sendSnack } = useSnackbar();
  const [openSaveAsDialog, setOpenSaveAsDialog] = useState(false);
  const [modelType, setModelType] = useState<number>(0);
  const published = modelType === 1;
  //NOTE: inOrder to call depublishing api we need to use a ref because we are using this api in an event listener of BPMNJS
  // const modelTypeRef = useRef<any>(true);
  const translations = useTranslations();
  const validations: Validation[] = [
    ({ saveAsName }) =>
      isRequired(saveAsName) || {
        saveAsName: getTranslation(translations, "required"),
      },
  ];
  const {
    values,
    changeHandler,
    isValid,
    errors: lightErrors,
    touched,
  } = useLightForm(
    {
      saveAsName: "",
    },
    validations
  );
  const [errors, setErrors] = useState<any | undefined>([]);
  const [isOverlapChecked, setIsOverlapChecked] = useState<boolean>(false);

  const { pathname, state } = useLocation();
  const { editId } = useParams();
  const [searchParams] = useSearchParams();
  const [mode, setMode] = useState(() => {
    return pathname.includes("insert") ? "create" : "edit";
  });

  const [shouldShowFlowNodeEditor, setShouldShowFlowNodeEditor] =
    useState<boolean>(false);

  const [allUsersOfSelectedOrganization, setAllUsersOfSelectedOrganition] =
    useState<UsersOfSelectedOrganization[]>([]);

  const [allGroupsOfSelectedOrganization, setAllGroupsOfSelectedOrganition] =
    useState<GroupModel[]>([]);

  const [allRolesOfSelectedOrganization, setAllRolesOfSelectedOrganition] =
    useState<RolesModel[]>([]);
  const [allRobotsOfSelectedOrganization, setAllRobotsOfSelectedOrganition] =
    useState<Robot[]>([]);

  const [shouldShowSaveModelPopup, setShouldShowSaveModelPopup] =
    useState<boolean>(false);

  const [nameModelPopUp, setNameModelPopUp] = useState<boolean>(false);

  const orgPanelPositionRef = useRef<any>("");
  const dropZoneRef = useRef<any>(null);
  const propertPanelResizeRef = useRef<any>();

  const [processModelName, setProcessModelName] = useState(
    getTranslation(translations, "pm.placeholder.untitledprocessmodel")
  );

  const handleDepublishing = async (shouldEnablePublish: boolean) => {
    if (modelType === 1) {
      try {
        const { data } = await processmodelsApi.delete(`${editId}/depublish`);
        sendSnack({
          message: data.responsemessage,
          type: "success",
        });
        setErrors([]);
        setModelType(0);
      } catch {
      } finally {
      }
    }
  };
  // leave it there still if it is not used we will need it later in the associations dialog
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [associations, setAssociations] = useState<AssociationResponse | null>(
    null
  );
  const getUsername = (user: {
    username?: string;
    firstname?: string;
    lastname?: string;
    email?: string;
  }) => {
    if (user.username) {
      return user.username;
    } else if (user.firstname || user.lastname) {
      return (
        user.firstname +
        (user.firstname && user.lastname ? " " : "") +
        user.lastname
      );
    } else {
      return user.email || "";
    }
  };
  const {
    bpmnModeler,
    selectedLane,
    extensionElementsRef,
    extensionSubprocesses,
    extensionElements,
    openDiagram,
    shouldShowDropZoneError,
    currentElementRef,
    handleCanvasViewPort,
    selectedElements,
    currentElement,
    onLaneMouseDown,
    processModelData,
    setProcessModelData,
    primaryPropertiesNameRef,
    originalXml,
  } = useBpmnModeler({
    selectedDesignerId: state?.designerid,
    getUsername,
    pathname,
    editId: editId ? editId : "",
    setProcessModelName,
    setModelType,
  });
  const publishedWithProcess = modelType === 1 && processModelData.hasprocess;
  const selectedOrganization = useSelectedOrganization();
  const isAdmin = selectedOrganization?.administrator;

  const resetColorsOnDiagram = useCallback(() => {
    if (bpmnModeler) {
      const modeling = bpmnModeler.get("modeling");
      const elements = bpmnModeler.get("elementRegistry");
      elements.forEach((el: any) => modeling.setColor(el, null));
    }
  }, [bpmnModeler]);

  const getRequestBodyForExtensionElements = useCallback(() => {
    let requestBodyForExtensionElements: Array<ExtensionElementType> = [];
    if (extensionElementsRef.current) {
      Object.keys(extensionElementsRef.current).forEach((key: string) => {
        requestBodyForExtensionElements.push(
          ...extensionElementsRef.current[key]
        );
      });
    }
    return requestBodyForExtensionElements;
  }, [extensionElementsRef]);

  const handleValidation = async (callBack?: () => void) => {
    try {
      if (bpmnModeler) {
        setErrors([]);
        const { xml: currentWorkingProcessModel } = await bpmnModeler.saveXML({
          format: true,
        });

        const { data } = await processmodelsApi.put(`/validate`, {
          entities: processModelData.entities,
          model: currentWorkingProcessModel?.replaceAll("#ff0000", "#000000"),
          extensionelements: getRequestBodyForExtensionElements(),
          extensionsubprocesses: extensionSubprocesses,
          extensionflownodes: processModelData.extensionflownodes,
          extensionswimlanes: processModelData.extensionswimlanes,
          idorganization: selectedOrganization?.id,
          name: processModelName,
          version: 0,
          modeltype: 0,
          creation: processModelData.creation,
          id: processModelData.id,
        });
        sendSnack({
          message: data.responsemessage,
          type: "success",
        });
        // processModelsRef.current.validatedProcessModel =
        //   currentWorkingProcessModel;
        if (callBack) {
          callBack();
        }
        setErrors([]);
      }
    } catch (error: any) {
      if (error?.response?.data?.validation) {
        setErrors(error?.response?.data?.validation ?? []);
      } else {
        sendSnack({
          message: String(error),
          type: "error",
        });
      }

      return true;
    }
  };

  useEffect(() => {
    let isOverLapEnabled = localStorage.getItem(
      CONSTANTS.STORE_OVERLAP_ENABLED
    );
    if (isOverLapEnabled === "true") {
      setIsOverlapChecked(true);
    } else {
      setIsOverlapChecked(false);
    }
  }, []);

  useEffect(() => {
    return () => setSelectedError(null);
  }, []);

  useEffect(() => {
    callOrganizationAllUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchParams.get("validate") && bpmnModeler) {
      setTimeout(handleValidation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, processModelName]);
  useEffect(() => {
    if (bpmnModeler) {
      const modeling = bpmnModeler.get("modeling");
      const elements = bpmnModeler.get("elementRegistry");
      resetColorsOnDiagram();
      const newErrorElement = errors
        .filter((_: any, ind: number) => ind === selectedError)
        .map((error: any) => {
          return elements.get(error.designerid);
        })
        .filter((_: any) => _);
      if (newErrorElement && newErrorElement.length > 0) {
        for (let el of newErrorElement) {
          modeling.setColor(el, {
            stroke: "red",
          });
        }
      }
    }
  }, [errors, bpmnModeler, selectedError, resetColorsOnDiagram]);

  const callOrganizationAllUsers = async () => {
    try {
      const promises = [
        organizationsApi.get(`${selectedOrganization?.id}/users`),
        organizationsApi.get(`${selectedOrganization?.id}/groups`),
        organizationsApi.get(`${selectedOrganization?.id}/roles`),
        organizationsApi.get(`${selectedOrganization?.id}/robots`),
      ];

      const [usersresult, groupsresult, rolesresult, robotsresult] =
        await Promise.all(promises);
      setAllUsersOfSelectedOrganition(usersresult?.data?.users);
      setAllGroupsOfSelectedOrganition(groupsresult.data.groups);
      setAllRolesOfSelectedOrganition(rolesresult.data.roles);
      setAllRobotsOfSelectedOrganition(robotsresult.data.robots);
    } catch {
    } finally {
    }
  };

  const saveOppPosition = (orgId?: number | null, modelId?: number) => {
    let oppPosition = {
      idorganization: orgId,
      modelId: modelId,
      height: orgPanelPositionRef.current,
    };

    const listOfOppPositions = localStorage.getItem(
      CONSTANTS.STORE_OPP_POSITION
    );
    const allValidPositions = listOfOppPositions
      ? JSON.parse(listOfOppPositions)
      : [];

    let prevStoredPosIndex = allValidPositions.findIndex((pos: any) => {
      if (
        pos.idorganization === oppPosition.idorganization &&
        pos.modelId === oppPosition.modelId
      ) {
        return pos;
      }
      return false;
    });

    if (prevStoredPosIndex >= 0) {
      allValidPositions.splice(prevStoredPosIndex, 1, oppPosition);
    } else {
      allValidPositions.push(oppPosition);
    }

    localStorage.setItem(
      CONSTANTS.STORE_OPP_POSITION,
      JSON.stringify(allValidPositions)
    );
  };

  const saveXmlAs = async (xml: string) => {
    let requestBodyForExtensionElements: Array<ExtensionElementType> =
      getRequestBodyForExtensionElements();
    try {
      const { data } = await organizationsApi.post(
        `${selectedOrganization?.id}/processmodels`,
        {
          entities: [],
          model: xml.replaceAll("#ff0000", "#000000"),
          extensionelements: requestBodyForExtensionElements,
          extensionflownodes: processModelData.extensionflownodes,
          extensionswimlanes: processModelData.extensionswimlanes,
          extensionsubprocesses: extensionSubprocesses,
          idorganization: selectedOrganization?.id,
          name: values.saveAsName,
          version: 0,
          modeltype: 0,
          creation: processModelData.creation,
        }
      );
      navigate(`/dashboard/process/edit/${data?.id}`);
      setOpenSaveAsDialog(false);
      // TROVARE SOLUZIONE MIGLIORE DEL LOCATION RELOAD
      // eslint-disable-next-line no-restricted-globals
      // location.reload();
    } catch (e: any) {
    } finally {
    }
  };
  const saveXml = async (xml: string, callBack: any) => {
    // const _selectedError = selectedError;
    // resetColorsOnDiagram();
    let requestBodyForExtensionElements: Array<ExtensionElementType> =
      getRequestBodyForExtensionElements();
    if (!processModelName) {
      sendSnack({
        message: getTranslation(
          translations,
          "generic.process_name_cannot_be_empty.caption"
        ),
        type: "error",
      });
    } else if (mode === "create") {
      try {
        const { data } = await organizationsApi.post(
          `${selectedOrganization?.id}/processmodels`,
          {
            entities: [],
            model: xml.replaceAll("#ff0000", "#000000"),
            extensionelements: requestBodyForExtensionElements,
            extensionflownodes: processModelData.extensionflownodes,
            extensionswimlanes: processModelData.extensionswimlanes,
            extensionsubprocesses: extensionSubprocesses,
            idorganization: selectedOrganization?.id,
            name: processModelName,
            version: 0,
            modeltype: 0,
            creation: processModelData.creation,
            id: processModelData.id,
          }
        );
        // setEditIdValue(data?.id);

        // processModelsRef.current.editIdValue = data?.id;
        saveOppPosition(selectedOrganization?.id, data.id);
        setMode("edit");
        sendSnack({
          message: data.responsemessage,
          type: "success",
        });
        // processModelsRef.current.savedProcessModel = xml;
        originalXml.current = xml;
        if (callBack) callBack(Number(data?.id));
        setErrors([]);
      } catch (e: any) {
        if (e?.response?.data?.validation) {
          setErrors(e?.response?.data?.validation ?? []);
        }
      } finally {
      }
    } else if (mode === "edit") {
      try {
        if (bpmnModeler) {
          const { data } = await processmodelsApi.put<any>(String(editId), {
            entities: [],
            idorganization: selectedOrganization?.id,
            model: xml.replaceAll("#ff0000", "#000000"),
            extensionelements: requestBodyForExtensionElements,
            extensionflownodes: processModelData.extensionflownodes,
            extensionswimlanes: processModelData.extensionswimlanes,
            extensionsubprocesses: extensionSubprocesses,
            modeltype: 0,
            name: processModelName,
            version: 0,
            creation: processModelData.creation,
            id: processModelData.id,
          });
          setMode("edit");
          // processModelsRef.current.savedProcessModel = data.model;
          originalXml.current = data.model;
          openDiagram(data.model, true);
          // bpmnModeler.importXML(data.model);
          saveOppPosition(selectedOrganization?.id, Number(editId));
          if (data.responsemessage) {
            sendSnack({
              message: data.responsemessage,
              type: "success",
            });
          }
          if (callBack) {
            callBack(Number(editId));
          }
          setErrors([]);
        }
      } catch (error: any) {
        if (
          error?.response?.data?.entities &&
          error?.response?.data?.activities
        ) {
          setAssociations(error.response.data);
        }
        if (error?.response?.data?.validation) {
          setErrors(error?.response?.data?.validation ?? []);
        }
      } finally {
        // setSelectedError(_selectedError);
      }
    }
  };

  const publishXml = async () => {
    try {
      if (mode === "edit") {
        const { data } = await processmodelsApi.post(`${editId}/publish`);
        sendSnack({
          message: data.responsemessage,
          type: "success",
        });
        setErrors([]);
        setModelType(1);
      } else {
        sendSnack({
          message: getTranslation(
            translations,
            "generic.save_process_model.before_publish.caption"
          ),
          type: "success",
        });
      }
    } catch {}
  };

  const exportModel = async (modelName: string) => {
    if (bpmnModeler) {
      const data = await bpmnModeler.saveXML({ format: true });
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," +
          encodeURIComponent(clearLanes(data.xml || ""))
      );
      element.setAttribute("download", `${modelName}.flw`);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }
  };

  function camelToNormal(str: string) {
    return str.replace(/([A-Z])/g, " $1").toLowerCase();
  }

  const getNameOfTheSelectedElement = () => {
    if (currentElementRef.current) {
      const bo = getBusinessObject(currentElementRef.current);
      const isTimerEvent = bo.eventDefinitions && bo.eventDefinitions.length;
      if (isTimerEvent) {
        return `Timer ${camelToNormal(bo.$type.split(":")[1])}`;
      }
      return camelToNormal(bo.$type.split(":")[1]);
    }
    return "";
  };
  const addEncoding = (xml: string) =>
    xml.replace(
      `<?xml version="1.0"?>`,
      `<?xml version="1.0" encoding="UTF-8"?>`
    );
  const removeFormat = (xml: string) =>
    xml.replaceAll(`\n`, ``).replaceAll("\t", "").replaceAll(" ", "");

  const onClickGoBack = () => {
    // TODO: se ha modificato qualcosa far vedere il popup per dire sicuro di voler uscire?
    // if (saveBtnRef.current.className.includes("save-btn-enable")) {
    if (bpmnModeler) {
      const parser = new DOMParser();
      const serializer = new XMLSerializer();
      bpmnModeler.saveXML({ format: false }).then((data: any) => {
        const current = removeFormat(
          addEncoding(
            serializer.serializeToString(
              parser.parseFromString(data.xml, "text/xml")
            )
          )
        );
        const compare = removeFormat(
          addEncoding(
            serializer.serializeToString(
              parser.parseFromString(originalXml.current, "text/xml")
            )
          )
        );
        if (current !== compare) {
          setShouldShowSaveModelPopup(true);
        } else {
          navigate(-1);
        }
      });
    }
  };

  const onClickValidationBtn = (callBack: () => void): void => {
    handleValidation(callBack);
  };

  const togglePropertyPanel = () => {
    setShouldShowFlowNodeEditor((_) => {
      if (_) {
        propertPanelResizeRef.current.resizable.style.width = "80px";
      } else {
        propertPanelResizeRef.current.resizable.style.width = "250px";
      }
      return !_;
    });
  };
  const closePropertyPanel = () => {
    setShouldShowFlowNodeEditor((_) => {
      propertPanelResizeRef.current.resizable.style.width = "80px";
      return false;
    });
  };

  return (
    <React.Fragment>
      <Box height="100%" display="flex" flexDirection="column">
        <Stack flexWrap="wrap" direction="row" spacing={2} alignItems="stretch">
          <Button
            variant="outlined"
            size="small"
            startIcon={<ChevronLeft />}
            id="back-button"
            onClick={onClickGoBack}
          >
            {getTranslation(translations, "pm.label.button.goback")}
          </Button>
          <TextField
            disabled={!isAdmin}
            className="dashed"
            id="process-name"
            type="text"
            variant="outlined"
            size="small"
            value={processModelName}
            onChange={({ target }) => setProcessModelName(target.value)}
            label={getTranslation(translations, "pm.label.process_name")}
          />
          {isAdmin && (
            <Stack
              sx={{ backgroundColor: (_) => _.palette.grey[300], p: 0.4 }}
              component={Paper}
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="center"
              id={"edit-page-actions-container"}
            >
              <Tooltip
                title={getTranslation(translations, "pm.buttons.action.save")}
              >
                <IconButton
                  size="small"
                  onClick={async () => {
                    bpmnModeler?.saveXML({ format: true }).then((data: any) => {
                      saveXml(data.xml, (id: number) => {
                        navigate(`/dashboard/process/edit/${id}`, {
                          replace: true,
                        });
                        if (mode === "create") {
                          // TROVARE SOLUZIONE MIGLIORE DEL LOCATION RELOAD
                          // eslint-disable-next-line no-restricted-globals
                          // location.reload();
                        }
                      });
                    });
                  }}
                  id="save-process-model-btn"
                >
                  <SaveOutlined />
                </IconButton>
              </Tooltip>
              {/* DUPLICATE BUTTON */}
              {mode === "edit" && (
                <Tooltip
                  title={getTranslation(translations, "pm.button.save_as")}
                >
                  <IconButton
                    size="small"
                    id="saveas-process-model-btn"
                    onClick={() => setOpenSaveAsDialog(true)}
                  >
                    <SaveAsOutlined />
                  </IconButton>
                </Tooltip>
              )}
              {/* DUPLICATE BUTTON */}

              <Tooltip
                title={getTranslation(
                  translations,
                  "pm.buttons.action.validate"
                )}
              >
                <IconButton
                  size="small"
                  id="valie-process-model-btn"
                  onClick={() => onClickValidationBtn(() => {})}
                >
                  <VerifiedOutlined />
                </IconButton>
              </Tooltip>

              <Tooltip
                title={getTranslation(
                  translations,
                  "pm.buttons.action.publish"
                )}
              >
                <span>
                  <IconButton
                    id="publish-btn"
                    disabled={
                      published || publishedWithProcess || mode === "create"
                    }
                    size="small"
                    onClick={async () => {
                      onClickValidationBtn(publishXml);
                    }}
                  >
                    <CloudUploadOutlined />
                  </IconButton>
                </span>
              </Tooltip>

              {!modelType ? (
                <IconButton size="small" disabled>
                  <CloudDoneOutlined />
                </IconButton>
              ) : (
                <Tooltip
                  title={getTranslation(
                    translations,
                    "pm.buttons.action.depublish"
                  )}
                >
                  <IconButton
                    id="depublish-btn"
                    size="small"
                    onClick={() => handleDepublishing(true)}
                  >
                    <CloudDownloadOutlined />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          )}
          {isAdmin && (
            <Button
              variant="outlined"
              size="small"
              endIcon={<ImportExport />}
              id={`export-model-btn`}
              onClick={(e) => {
                e.stopPropagation();
                setNameModelPopUp(true);
              }}
            >
              {getTranslation(translations, "pm.button.export_model")}
            </Button>
          )}
        </Stack>
        <Box sx={{ position: "relative" }} className="pm-dropzone-wrapper">
          {!isAdmin && (
            <Box
              sx={{
                position: "absolute",
                zIndex: 999,
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: "transparent",
              }}
            />
          )}
          <Stack direction="column" width="100%" position="relative">
            <DropZoneElement
              shouldShowDropZoneError={shouldShowDropZoneError}
              onClickEditorCollapse={togglePropertyPanel}
              shouldShowFlowNodeEditor={shouldShowFlowNodeEditor}
              bpmnModeler={bpmnModeler as any}
              dropZoneRef={dropZoneRef}
            />
            {isAdmin && (
              <Box
                sx={{
                  position: isOverlapChecked ? "unset" : "relative",
                  pt: "40px",
                }}
              >
                <ProcessModelError
                  errors={errors}
                  isOverlapChecked={isOverlapChecked}
                  setSelectedError={setSelectedError}
                  selectedError={selectedError}
                />
                <OrganizationPanel
                  extensionElements={extensionElements}
                  handleCanvasViewPort={handleCanvasViewPort}
                  orgPanelPositionRef={orgPanelPositionRef}
                  allRolesOfSelectedOrganization={
                    allRolesOfSelectedOrganization
                  }
                  allGroupsOfSelectedOrganization={
                    allGroupsOfSelectedOrganization
                  }
                  allUsersOfSelectedOrganization={
                    allUsersOfSelectedOrganization
                  }
                  allRobotsOfSelectedOrganization={
                    allRobotsOfSelectedOrganization
                  }
                  getUsername={getUsername}
                  onLaneMouseDown={onLaneMouseDown}
                  selectedLane={selectedLane}
                  isOverlapChecked={isOverlapChecked}
                  setIsOverlapChecked={setIsOverlapChecked}
                />
              </Box>
            )}
          </Stack>
          {isAdmin && (
            <Resizable
              ref={propertPanelResizeRef}
              enable={{
                top: false,
                right: false,
                bottom: false,
                left: shouldShowFlowNodeEditor,
                topRight: false,
                bottomRight: false,
                bottomLeft: false,
                topLeft: false,
              }}
              style={{ marginLeft: 4 }}
              minWidth={80}
              onResizeStop={(...rest) => {
                if (Number(rest[2].style.width.replace("px", "")) < 95) {
                  closePropertyPanel();
                }
              }}
            >
              <Stack
                component={Paper}
                py={2}
                borderRadius={0}
                sx={{ clipPath: "inset(-100px 0px 0px -100px)" }}
                height="100%"
                spacing={1}
                direction="column"
              >
                <Stack
                  direction="row"
                  spacing={2}
                  px={2}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Fab
                    size="small"
                    color="primary"
                    onClick={togglePropertyPanel}
                  >
                    <Settings id="property-panel-btn" />
                  </Fab>
                  {shouldShowFlowNodeEditor && selectedElements?.length > 0 && (
                    <Typography
                      textTransform="capitalize"
                      fontWeight="bold"
                      color="primary"
                    >
                      {getNameOfTheSelectedElement()}
                    </Typography>
                  )}
                </Stack>
                {shouldShowFlowNodeEditor && (
                  <Box px={2} height={"100%"} sx={{ overflowY: "auto" }}>
                    <PropertiesView
                      modeler={bpmnModeler as any}
                      selectedElements={selectedElements}
                      currentElement={currentElement}
                      processModelData={processModelData}
                      getRequestBodyForExtensionElements={
                        getRequestBodyForExtensionElements
                      }
                      setProcessModelData={setProcessModelData}
                      openDiagram={openDiagram}
                      primaryPropertiesNameRef={primaryPropertiesNameRef}
                    />
                  </Box>
                )}
              </Stack>
            </Resizable>
          )}
        </Box>

        <SaveProcessModelPopUp
          shouldShowSaveModelPopup={shouldShowSaveModelPopup}
          setShouldShowSaveModelPopup={setShouldShowSaveModelPopup}
          saveXml={(callBack) => {
            bpmnModeler?.saveXML({ format: true }).then((data: any) => {
              saveXml(data.xml, callBack);
            });
          }}
          processModelName={processModelName}
        />
        <Dialog
          open={openSaveAsDialog}
          onClose={() => setOpenSaveAsDialog(false)}
        >
          <form>
            <DialogHeader onClose={() => setOpenSaveAsDialog(false)}>
              {getTranslation(translations, "pm.label.choose_name")}
            </DialogHeader>
            <DialogContent>
              <Grid container mt={0} spacing={2}>
                <Grid item>
                  <TextField
                    id="saveas-field"
                    value={values.saveAsName}
                    onChange={({ target }) =>
                      changeHandler("saveAsName", target.value)
                    }
                    error={Boolean(
                      touched.saveAsName && lightErrors.saveAsName
                    )}
                    helperText={touched.saveAsName && lightErrors.saveAsName}
                    size="small"
                    label={getTranslation(
                      translations,
                      "pm.label.process_name"
                    )}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Stack direction="row" justifyContent="flex-end" spacing={2}>
                <Button>
                  {getTranslation(translations, "generic.button.cancel")}
                </Button>
                <Button
                  id="saveas-confirm-btn"
                  variant="contained"
                  type="submit"
                  disabled={!isValid}
                  onClick={async (e) => {
                    e.preventDefault();
                    if (bpmnModeler) {
                      const modeling = bpmnModeler.get("modeling");
                      const element = bpmnModeler.get("elementRegistry");
                      const newErrorElement = errors
                        .map((error: any) => {
                          if (error.designerid === "") return undefined;
                          return element.get(error.designerid);
                        })
                        .filter((el: any) => el);

                      modeling.setColor(newErrorElement, null);
                      bpmnModeler
                        .saveXML({ format: true })
                        .then((data: any) => {
                          saveXmlAs(data.xml);
                        });
                    }
                  }}
                >
                  {getTranslation(translations, "pm.button.save_as")}
                </Button>
              </Stack>
            </DialogActions>
          </form>
        </Dialog>
      </Box>
      <AssociationDialog
        open={associations}
        setOpen={setAssociations}
        onReassociate={(data) => {
          if (data.success) {
            // processModelsRef.current.savedProcessModel = data.model;
            originalXml.current = data.model;
            bpmnModeler?.importXML(data.model);
            if (data.responsemessage) {
              sendSnack({
                message: data.responsemessage,
                type: "success",
              });
            }
            setAssociations(null);
          }
        }}
        getProcessModel={async () => {
          const data = await bpmnModeler?.saveXML({ format: true });
          return {
            entities: [],
            idorganization: selectedOrganization?.id as number,
            model: data?.xml || "",
            extensionelements: getRequestBodyForExtensionElements(),
            extensionflownodes: processModelData.extensionflownodes,
            extensionswimlanes: processModelData.extensionswimlanes,
            extensionsubprocesses: extensionSubprocesses,
            modeltype: 0,
            name: processModelName,
            version: 0,
            creation: processModelData.creation || "",
            id: processModelData.id,
          };
        }}
      />
      <InputPopUp
        labels={{
          title: getTranslation(translations, "generic.model_name.caption"),
          inputLabel: getTranslation(
            translations,
            "generic.insert_model_name.caption"
          ),
        }}
        callback={exportModel}
        open={nameModelPopUp}
        onClose={() => setNameModelPopUp(false)}
      />
    </React.Fragment>
  );
};
export default NewProcessModule;
