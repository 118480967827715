import { useTheme } from "@mui/material";
import { CLOSE_SIDEBAR_WIDTH, DRAWER_WIDTH } from "common/theme";
import { UtilityContext } from "contexts";
import { useContext, useEffect, useState } from "react";
import { CONSTANTS } from "common";

const pxToNumber = (px: string) => Number(px.replace('px', ''));

const useSidebar = (): [boolean, (open: boolean) => void, number] => {
  const { state: { sidebarOpen }, actions: { setSidebarOpen } } = useContext(UtilityContext);
  const [distanceFromLeft, setDistanceFromLeft] = useState<number>(0);
  const theme = useTheme();

  useEffect(() => {
    const storedSidebarState = localStorage.getItem(CONSTANTS.STORE_SIDEBAR_OPEN);
    if (storedSidebarState !== null) {
      setSidebarOpen(storedSidebarState === 'true');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.setItem(CONSTANTS.STORE_SIDEBAR_OPEN, String(sidebarOpen));
  }, [sidebarOpen]);

  useEffect(() => {
    const calculatedDistance = pxToNumber(theme.spacing(1)) + (sidebarOpen ? DRAWER_WIDTH : pxToNumber(CLOSE_SIDEBAR_WIDTH));
    setDistanceFromLeft(calculatedDistance);
  }, [sidebarOpen, theme]);

  return [sidebarOpen, setSidebarOpen, distanceFromLeft];
};

export default useSidebar;
