import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import useSnackbar from "./useSnackbar";
import useUser from "./useUser";
// import { getTranslation } from "common";
// import useTranslations from "./useTranslations";

const useSSE = <T>(
  url: string,
  callback: (data: T) => void,
  needJWT: boolean = false
) => {
  const [mounted, setMounted] = useState<boolean>(false);
  const user = useUser();
  const { sendSnack } = useSnackbar();
  const [lastEventId, setLastEventId] = useState<number>(-1);
  // const translations = useTranslations();

  // const _onError = useCallback(
  //   (message: Event) => {
  //     console.trace("error", message);
  //     sendSnack({
  //       message: getTranslation(translations, "generic.server.error"),
  //       type: "error",
  //     });
  //   },
  //   [translations, sendSnack]
  // );

  const _callback = useCallback(
    (message: MessageEvent<string>) => {
      try {
        message.stopPropagation();
        // lastEventId.current = Number(message.lastEventId);
        setLastEventId(Number(message.lastEventId));
        if (message.data) {
          callback(JSON.parse(message.data) as T);
        }
      } catch (e) {
        sendSnack({
          message: String(e),
          type: "error",
          autoClose: 5000,
        });
      }
    },
    [callback, sendSnack]
  );
  const _onOpen = useCallback((ev: Event) => {
    const listener = ev.currentTarget as EventSource;
    // listener.onerror = _onError;
    listener.onmessage = _callback;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useLayoutEffect(() => setMounted(true), []);
  useEffect(() => {
    if (mounted) {
      let queryParams = `?lasteventid=${lastEventId}`;
      if (needJWT) {
        const jwt = user?.jwt;
        queryParams += `&jwt=${jwt}`;
      }
      const listener = new EventSource(
        `${process.env.REACT_APP_BACKEND_BASE_URI}${url}${queryParams}`
      );
      listener.addEventListener("open", _onOpen);
      // listener.onmessage = _callback;
      // listener.onerror = _onError;
      return () => {
        listener.removeEventListener("open", _onOpen);
        listener.onmessage = null;
        // listener.onerror = null;
        listener.close();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // _callback,
    needJWT,
    // type,
    url,
    user?.jwt,
    lastEventId,
    // _onError,
    mounted,
  ]);

  const resetLastEventID = useCallback(() => {
    setLastEventId(-1);
  }, []);

  return {
    resetLastEventID,
    mounted,
  };
};

export default useSSE;
