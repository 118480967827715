import React from "react";
//@ts-ignore
import BpmnModeler from "bpmn-js/lib/Modeler";
//@ts-ignore
import { getBusinessObject } from "bpmn-js/lib/util/ModelUtil";
import {
  Collapse,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
} from "@mui/material";
import { useTranslations } from "hooks";
import { getTranslation } from "common";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

interface PrimaryPropertiesPanelTypes {
  togglePrimaryPropertiesAccordian: boolean;
  setTogglePrimaryPropertiesAccordian: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  element: any;
  modeler: BpmnModeler;
  primaryPropertiesNameRef: any;
}

const PrimaryPropertiesPanel = (props: PrimaryPropertiesPanelTypes) => {
  const {
    togglePrimaryPropertiesAccordian,
    setTogglePrimaryPropertiesAccordian,
    element,
    modeler,
    primaryPropertiesNameRef,
  } = props;
  const translations = useTranslations();
  const getElementName = (element: any) => getBusinessObject(element).name;

  const updateName = (name: string) => {
    modeler.get<any>("modeling").updateLabel(element, name);
  };

  const getUpdateDocumentationText = () => {
    const bo = getBusinessObject(element);

    const documentation =
      bo.documentation && bo.documentation.length
        ? bo.documentation[0]?.text
        : "";

    return documentation;
  };

  const onChangeDocumentationText = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    event.stopPropagation();
    const bo = getBusinessObject(element);

    if (bo.documentation && bo.documentation.length && bo.documentation[0]) {
      let documentation = bo.documentation[0];
      documentation.text = event.target.value;
      modeler.get<any>("modeling").updateProperties(element, {
        documentation: [documentation],
      });
    } else {
      let moddle = modeler.get<any>("moddle");
      let documentation = moddle.create("bpmn:Documentation", {
        text: event.target.value,
      });

      modeler.get<any>("modeling").updateProperties(element, {
        documentation: [documentation],
      });
    }
  };

  return (
    <React.Fragment>
      <ListItemButton
        onClick={() => setTogglePrimaryPropertiesAccordian((_) => !_)}
      >
        <ListItemText
          primary={getTranslation(
            translations,
            "pm.propertyeditor.primaryproperties"
          )}
        />
        {togglePrimaryPropertiesAccordian ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse
        timeout="auto"
        sx={{ pt: 2 }}
        unmountOnExit
        in={togglePrimaryPropertiesAccordian}
      >
        <Stack direction="column" spacing={2}>
          <TextField
            size="small"
            label={getTranslation(
              translations,
              "pm.propertyeditor.primaryproperties.label.id_component"
            )}
            sx={{ pointerEvents: "none" }}
            disabled={["bpmn:Lane", "bpmn:SubProcess"].includes(element.type)}
            value={element?.id}
          />
          <TextField
          id="name-component"
            size="small"
            ref={primaryPropertiesNameRef}
            label={getTranslation(
              translations,
              "pm.propertyeditor.primaryproperties.label.name_component"
            )}
            defaultValue={getElementName(element)}
            onChange={(event) => {
              updateName(event?.target.value);
            }}
            disabled={["bpmn:Lane", "bpmn:SubProcess"].includes(element.type)}
          />

          <TextField
            multiline
            size="small"
            label={getTranslation(
              translations,
              "pm.propertyeditor.primaryproperties.label.documentation"
            )}
            defaultValue={getUpdateDocumentationText()}
            onChange={onChangeDocumentationText}
          />
        </Stack>
      </Collapse>
    </React.Fragment>
  );
};

export default PrimaryPropertiesPanel;
