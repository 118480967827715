import {
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
} from "@mui/material";
import React from "react";
import { PrecisionManufacturing } from "@mui/icons-material";
import { useDragItem } from "hooks";
import AvatarRem from "components/AvatarRem";
import { Robot } from "hooks/useRobots";

interface RobotAssociationItemProps {
  selected: boolean;
  ele: Robot;
  ind: number;
  onLaneMouseDown: any;
  selectedLane: string;
}
const RobotAssociationItem: React.FC<RobotAssociationItemProps> = ({
  ele,
  ind,
  selected,
  onLaneMouseDown,
  selectedLane,
}) => {
  const { dragging, onDrag, onDragEnd, onDragStart, ref } = useDragItem();
  return (
    <Paper
      ref={ref as any}
      sx={{
        p: 0,
        my: 1,
        backgroundColor: dragging
          ? (_) => _.palette.background.paper
          : "transparent",
      }}
      elevation={dragging ? 5 : 0}
      draggable={true}
      onDragStart={(e) =>
        onDragStart(e, (event: any) => {
          const dragData = {
            name: ele?.name,
            idparticipant: ele.id,
            idparticipanttype: 4,
            assignmentmode: 0,
          };
          event.dataTransfer.setData(
            "application/json",
            JSON.stringify(dragData)
          );
        })
      }
      onDragEnd={onDragEnd}
      onDrag={onDrag}
    >
      <ListItemButton
        disableGutters
        selected={selected}
        id={`groups${ele.id}`}
        onDoubleClick={() => {
          if (Boolean(selectedLane)) {
            onLaneMouseDown(selectedLane, {
              name: ele?.name,
              idparticipant: ele.id,
              idparticipanttype: 4,
              assignmentmode: 0,
            });
          }
        }}
      >
        <ListItemAvatar>
          <AvatarRem icon={ele.picture || ""}>
            <PrecisionManufacturing />
          </AvatarRem>
        </ListItemAvatar>
        <ListItemText key={`groups${ind}`}>{ele?.name}</ListItemText>
      </ListItemButton>
    </Paper>
  );
};

export default RobotAssociationItem;
