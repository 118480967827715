import React from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  REDO_COMMAND,
  UNDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  $getSelection,
  $isRangeSelection,
  // $createParagraphNode,
  $getNodeByKey,
  // GridSelection,
  // NodeSelection,
  // RangeSelection,
  ElementFormatType,
  TextFormatType,
} from "lexical";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import {
  $isParentElementRTL,
  // $wrapNodes,
  $isAtNodeEnd,
} from "@lexical/selection";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
  // INSERT_ORDERED_LIST_COMMAND,
  // INSERT_UNORDERED_LIST_COMMAND,
  // REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode,
} from "@lexical/list";
import {
  // $createHeadingNode,
  // $createQuoteNode,
  $isHeadingNode,
} from "@lexical/rich-text";
import {
  // $createCodeNode,
  $isCodeNode,
  getDefaultCodeLanguage,
  getCodeLanguages,
} from "@lexical/code";
import {
  Close,
  Code,
  DragHandle,
  Edit,
  ExpandMore,
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatItalic,
  // FormatListBulleted,
  // FormatListNumbered,
  // FormatQuote,
  FormatStrikethrough,
  FormatUnderlined,
  Link,
  // Notes,
  Redo,
  // Title,
  Undo,
} from "@mui/icons-material";
import {
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Stack,
  Menu,
  MenuItem,
  // ListItemIcon,
  // ListItemText,
  MenuList,
  PaperProps,
  Paper,
  Dialog,
  DialogContent,
  TextField,
  DialogTitle,
  IconButton,
  Link as MuiLink
} from "@mui/material";
import { theme } from "common";
import Draggable from "react-draggable";
// import { useTranslations } from "hooks";

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
const LowPriority = 1;

const blockTypeToBlockName = {
  code: "Code Block",
  h1: "Large Heading",
  h2: "Small Heading",
  h3: "Heading",
  h4: "Heading",
  h5: "Heading",
  ol: "Numbered List",
  paragraph: "Normal",
  quote: "Quote",
  ul: "Bulleted List",
};

function positionEditorElement(editor: HTMLElement, rect: any) {
  if (rect === null) {
    editor.style.opacity = "0";
    editor.style.top = "-1000px";
    editor.style.left = "-1000px";
  } else {
    editor.style.opacity = "1";
    editor.style.top = `${rect.top + rect.height + window.pageYOffset + 10}px`;
    editor.style.left = `${rect.left + window.pageXOffset - editor.offsetWidth / 2 + rect.width / 2
      }px`;
  }
}

function FloatingLinkEditor({ editor, open, setIsLink }: { editor: any, open: boolean, setIsLink: React.Dispatch<React.SetStateAction<boolean>> }) {
  const editorRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const mouseDownRef = useRef(false);
  const [linkUrl, setLinkUrl] = useState("");
  const [isEditMode, setEditMode] = useState(false);

  // const [lastSelection, setLastSelection] = useState<
  //   RangeSelection | NodeSelection | GridSelection | null
  // >(null);

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl("");
      }
    }
    const editorElem = editorRef.current;
    const nativeSelection = window.getSelection();
    const activeElement = document.activeElement;

    if (editorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();
    if (
      selection !== null &&
      !nativeSelection?.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection?.anchorNode)
    ) {
      const domRange = nativeSelection?.getRangeAt(0);
      let rect;
      if (nativeSelection?.anchorNode === rootElement) {
        let inner = rootElement;
        while (inner.firstElementChild != null) {
          inner = inner.firstElementChild;
        }
        rect = inner.getBoundingClientRect();
      } else {
        rect = domRange?.getBoundingClientRect();
      }

      if (!mouseDownRef.current) {
        positionEditorElement(editorElem, rect);
      }
      // setLastSelection(selection);
    } else if (!activeElement || activeElement.className !== "link-input") {
      positionEditorElement(editorElem, null);
      // setLastSelection(null);
      setEditMode(false);
      setLinkUrl("");
    }

    return true;
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }: any) => {
        editorState.read(() => {
          updateLinkEditor();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        LowPriority
      )
    );
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    if (isEditMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditMode]);

  return (
    <Dialog
      open={open}
      hideBackdrop
      disableEnforceFocus
      disableAutoFocus
      componentsProps={{
        root: {
          style: {
            pointerEvents: "none"
          }
        }
      }}
      PaperComponent={PaperComponent}
    >
      <DialogTitle sx={{ pointerEvents: "all" }} p={0}>
        <Stack direction="row" alignItems="center">
          <DragHandle
            fontSize="small"
            id="draggable-dialog-title"
            sx={{ cursor: "move" }}
          />
          <IconButton
            size="small"
            onClick={() => {
              setIsLink(false);
              //  setLinkUrl("");
              // setEditMode(false);
            }}
          >
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ pointerEvents: "all" }}>
        {isEditMode ? (
          <TextField
            ref={inputRef}
            size="small"
            // className="link-input"
            value={linkUrl}
            onChange={(event) => {
              setLinkUrl(event.target.value);
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                if (linkUrl !== "") {
                  editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
                }
                setEditMode(false);
              } else if (event.key === "Escape") {
                event.preventDefault();
                setEditMode(false);
              }
            }}
          />
        ) : (
          <Stack direction="row" alignItems="center" spacing={1}>
            <MuiLink href={linkUrl} target="_blank" rel="noopener noreferrer">
              {linkUrl}
            </MuiLink>
            <IconButton
              size="small"
              role="button"
              tabIndex={0}
              onMouseDown={(event) => event.preventDefault()}
              onClick={() => {
                setEditMode(true);
              }}
            >
              <Edit />
            </IconButton>
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
}

function Select({ onChange, className, options, value }: any) {
  const [openLanguages, setOpenLanguages] = useState<HTMLElement | null>(null);

  return (
    <React.Fragment>
      <ToggleButtonGroup size="small" sx={{ py: (_) => _.spacing(0)  }}>
        <ToggleButton
          value="dropdown"
          onClick={(e) => {
            if (Boolean(openLanguages)) {
              setOpenLanguages(null);
            } else {
              setOpenLanguages(e.currentTarget);
            }
          }}
          aria-label="Formatting Options"
        >
          <Stack direction="row">
            <span className="text">{value}</span>
            <ExpandMore />
          </Stack>
        </ToggleButton>
      </ToggleButtonGroup>
      <Menu
        anchorEl={openLanguages}
        open={Boolean(openLanguages)}
        onClose={() => setOpenLanguages(null)}
      >
        <MenuList>
          {options.map((option: any) => (
            <MenuItem
              onClick={() => {
                onChange(option);
                setOpenLanguages(null);
              }}
              key={option}
            >
              {option}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </React.Fragment>
  );
}

function getSelectedNode(selection: any) {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
}

// function BlockOptionsDropdownList({
//   editor,
//   blockType,
//   toolbarRef,
//   setShowBlockOptionsDropDown,
// }: any) {
//   const dropDownRef = useRef<any>(null);

//   useEffect(() => {
//     const toolbar = toolbarRef.current;
//     const dropDown = dropDownRef.current;

//     if (toolbar !== null && dropDown !== null) {
//       const { top, left } = toolbar.getBoundingClientRect();
//       dropDown.style.top = `${top + 40}px`;
//       dropDown.style.left = `${left}px`;
//     }
//   }, [dropDownRef, toolbarRef]);

//   useEffect(() => {
//     const dropDown = dropDownRef.current;
//     const toolbar = toolbarRef.current;

//     if (dropDown !== null && toolbar !== null) {
//       const handle = (event: any) => {
//         const target = event.target;

//         if (!dropDown.contains(target) && !toolbar.contains(target)) {
//           setShowBlockOptionsDropDown(false);
//         }
//       };
//       document.addEventListener("click", handle);

//       return () => {
//         document.removeEventListener("click", handle);
//       };
//     }
//   }, [dropDownRef, setShowBlockOptionsDropDown, toolbarRef]);

//   const formatParagraph = () => {
//     if (blockType !== "paragraph") {
//       editor.update(() => {
//         const selection = $getSelection();

//         if ($isRangeSelection(selection)) {
//           $wrapNodes(selection, () => $createParagraphNode());
//         }
//       });
//     }
//     setShowBlockOptionsDropDown(false);
//   };

//   const formatLargeHeading = () => {
//     if (blockType !== "h1") {
//       editor.update(() => {
//         const selection = $getSelection();

//         if ($isRangeSelection(selection)) {
//           $wrapNodes(selection, () => $createHeadingNode("h1"));
//         }
//       });
//     }
//     setShowBlockOptionsDropDown(false);
//   };

//   // const formatSmallHeading = () => {
//   //   if (blockType !== "h2") {
//   //     editor.update(() => {
//   //       const selection = $getSelection();

//   //       if ($isRangeSelection(selection)) {
//   //         $wrapNodes(selection, () => $createHeadingNode("h2"));
//   //       }
//   //     });
//   //   }
//   //   setShowBlockOptionsDropDown(false);
//   // };

//   const formatBulletList = () => {
//     if (blockType !== "ul") {
//       editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
//     } else {
//       editor.dispatchCommand(REMOVE_LIST_COMMAND);
//     }
//     setShowBlockOptionsDropDown(false);
//   };

//   const formatNumberedList = () => {
//     if (blockType !== "ol") {
//       editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
//     } else {
//       editor.dispatchCommand(REMOVE_LIST_COMMAND);
//     }
//     setShowBlockOptionsDropDown(false);
//   };

//   const translations = useTranslations();

//   const formatQuote = () => {
//     if (blockType !== "quote") {
//       editor.update(() => {
//         const selection = $getSelection();

//         if ($isRangeSelection(selection)) {
//           $wrapNodes(selection, () => $createQuoteNode());
//         }
//       });
//     }
//     setShowBlockOptionsDropDown(false);
//   };

//   const formatCode = () => {
//     if (blockType !== "code") {
//       editor.update(() => {
//         const selection = $getSelection();

//         if ($isRangeSelection(selection)) {
//           $wrapNodes(selection, () => $createCodeNode());
//         }

//       });
//     }
//     setShowBlockOptionsDropDown(false);
//   };

//   return (
//     <MenuList>
//       <MenuItem selected={blockType === "paragraph"} onClick={formatParagraph}>
//         <ListItemIcon>
//           <Notes />
//         </ListItemIcon>
//         <ListItemText>{getTranslation(translations, "generic.normal")}</ListItemText>
//       </MenuItem>
//       <MenuItem selected={blockType === "h1"} onClick={formatLargeHeading}>
//         <ListItemIcon>
//           <Title />
//         </ListItemIcon>
//         <ListItemText>{getTranslation(translations, "generic.largeheading")}</ListItemText>
//       </MenuItem>
//       <MenuItem selected={blockType === "ul"} onClick={formatBulletList}>
//         <ListItemIcon>
//           <FormatListBulleted />
//         </ListItemIcon>
//         <ListItemText>
//         {getTranslation(translations, "generic.bulletlist")}
//         </ListItemText>
//       </MenuItem>
//       <MenuItem selected={blockType === "ol"} onClick={formatNumberedList}>
//         <ListItemIcon>
//           <FormatListNumbered />
//         </ListItemIcon>
//         <ListItemText>{getTranslation(translations, "generic.numberedlist")}</ListItemText>
//       </MenuItem>
//       <MenuItem selected={blockType === "quote"} onClick={formatQuote}>
//         <ListItemIcon>
//           <FormatQuote />
//         </ListItemIcon>
//         <ListItemText>{getTranslation(translations, "generic.quote")}</ListItemText>
//       </MenuItem>
//       <MenuItem selected={blockType === "code"} onClick={formatCode}>
//         <ListItemIcon>
//           <Code />
//         </ListItemIcon>
//         <ListItemText>
//         {getTranslation(translations, "generic.choosehours")}
//         </ListItemText>
//       </MenuItem>
//     </MenuList>
//   );
// }

export default function ToolbarPlugin({ value }: { value: string }) {
  const [editor] = useLexicalComposerContext();
  const [isLink, setIsLink] = useState(false);
  // const toolbarRef = useRef(null);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [blockType, setBlockType] =
    useState<keyof typeof blockTypeToBlockName>("paragraph");
  const [selectedElementKey, setSelectedElementKey] = useState<string | null>(
    null
  );
  // const [showBlockOptionsDropDown, setShowBlockOptionsDropDown] =
  //   useState<HTMLElement | null>(null);
  const [codeLanguage, setCodeLanguage] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isRTL, setIsRTL] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isCode, setIsCode] = useState(false);
  const [align, setAlign] = useState("left");

  const handleChangeAlign = (e: any, val: ElementFormatType) => {
    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, val);
    setAlign(val);
  };

  const handleChangeFormat = (e: any, val: TextFormatType | null) => {
    if (value) {
      editor.dispatchCommand(FORMAT_TEXT_COMMAND, val as TextFormatType);
    }
  };

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type as any);
          if ($isCodeNode(element)) {
            setCodeLanguage(element.getLanguage() || getDefaultCodeLanguage());
          }
        }
      }
      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsUnderline(selection.hasFormat("underline"));
      setIsStrikethrough(selection.hasFormat("strikethrough"));
      setIsCode(selection.hasFormat("code"));
      setIsRTL($isParentElementRTL(selection));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar();
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);

  const codeLanguges = useMemo(() => getCodeLanguages(), []);
  const onCodeLanguageSelect = useCallback(
    (e: any) => {
      editor.update(() => {
        if (selectedElementKey !== null) {
          const node = $getNodeByKey(selectedElementKey);
          if ($isCodeNode(node)) {
            node.setLanguage(e);
          }
        }
      });
    },
    [editor, selectedElementKey]
  );

  const insertLink = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (!isLink) {
        editor.dispatchCommand(TOGGLE_LINK_COMMAND, "https://");
      } else {
        editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
      }
    },
    [editor, isLink]
  );

  return (
    <Toolbar classes={theme.mixins.toolbar}
      sx={{
        // WebkitTransformOrigin: {marginX: 0, paddingX: 0}, 
        // WebkitTransformOriginY: _ => _.spacing(0),
        // WebkitTransformOriginX: _ => _.spacing(0),
        // transform: "scale(0.85)",
        pl: _ => `${_.spacing(1)}!important`,
        pt: "8px!important",
        minHeight: "0px!important"
      }}>
      <Stack direction="row" flexWrap="wrap" spacing={1}>
        <ToggleButtonGroup sx={{ py: (_) => _.spacing(0) }} size="small">
          <ToggleButton
            value="undo"
            disabled={!canUndo}
            onClick={() => {
              editor.dispatchCommand(UNDO_COMMAND, undefined);
            }}
            aria-label="Undo"
          >
            <Undo />
          </ToggleButton>
          <ToggleButton
            value="redo"
            disabled={!canRedo}
            onClick={() => {
              editor.dispatchCommand(REDO_COMMAND, undefined);
            }}
            aria-label="Redo"
          >
            <Redo />
          </ToggleButton>
        </ToggleButtonGroup>
        {/* {supportedBlockTypes.has(blockType) && (
          <React.Fragment>
            <ToggleButtonGroup size="small" sx={{ py: (_) => _.spacing(0) }}>
              <ToggleButton
                value="dropdown"
                onClick={(e) => {
                  if (Boolean(showBlockOptionsDropDown)) {
                    setShowBlockOptionsDropDown(null);
                  } else {
                    setShowBlockOptionsDropDown(e.currentTarget);
                  }
                }}
                aria-label="Formatting Options"
              >
                <Stack direction="row">
                  <span className="text">
                    {blockTypeToBlockName[blockType]}
                  </span>
                  <ExpandMore className="chevron-down" />
                </Stack>
              </ToggleButton>
            </ToggleButtonGroup>
            <Menu
              anchorEl={showBlockOptionsDropDown}
              open={Boolean(showBlockOptionsDropDown)}
              onClose={() => setShowBlockOptionsDropDown(null)}
            >
              <BlockOptionsDropdownList
                editor={editor}
                blockType={blockType}
                toolbarRef={toolbarRef}
                setShowBlockOptionsDropDown={setShowBlockOptionsDropDown}
              />
            </Menu>
          </React.Fragment>
        )} */}
        {blockType === "code" ? (
          <Select
            className="toolbar-item code-language"
            onChange={onCodeLanguageSelect}
            options={codeLanguges}
            value={codeLanguage}
          />
        ) : (
          <Stack direction="row" spacing={1}>
            <ToggleButtonGroup sx={{ py: (_) => _.spacing(0) }} size="small">
              <ToggleButton
                selected={isBold}
                onClick={() => handleChangeFormat(undefined, "bold")}
                value="bold"
              >
                <FormatBold />
              </ToggleButton>
              <ToggleButton
                selected={isItalic}
                onClick={() => handleChangeFormat(undefined, "italic")}
                value="italic"
              >
                <FormatItalic />
              </ToggleButton>
              <ToggleButton
                selected={isUnderline}
                onClick={() => handleChangeFormat(undefined, "underline")}
                value="underline"
              >
                <FormatUnderlined />
              </ToggleButton>
              <ToggleButton
                selected={isStrikethrough}
                onClick={() => handleChangeFormat(undefined, "strikethrough")}
                value="strikethrough"
              >
                <FormatStrikethrough />
              </ToggleButton>
              <ToggleButton
                selected={isCode}
                onClick={() => handleChangeFormat(undefined, "code")}
                value="code"
              >
                <Code />
              </ToggleButton>
            </ToggleButtonGroup>
            <ToggleButtonGroup size="small" sx={{ py: (_) => _.spacing(0) }}>
              <ToggleButton value="link" onClick={insertLink}>
                <Link />
              </ToggleButton>
            </ToggleButtonGroup>
            <FloatingLinkEditor editor={editor} open={isLink} setIsLink={setIsLink} />
            <ToggleButtonGroup
              sx={{ py: (_) => _.spacing(0) }}
              size="small"
              exclusive
              value={align}
              onChange={handleChangeAlign}
            >
              <ToggleButton disabled={!value} value="left">
                <FormatAlignLeft />
              </ToggleButton>
              <ToggleButton disabled={!value} value="center">
                <FormatAlignCenter />
              </ToggleButton>
              <ToggleButton disabled={!value} value="right">
                <FormatAlignRight />
              </ToggleButton>
              <ToggleButton disabled={!value} value="justify">
                <FormatAlignJustify />
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        )}
      </Stack>
    </Toolbar>
  );
}
