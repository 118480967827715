import React, { useCallback, useEffect, useState } from "react";
import { messagesApi } from "api";
import { DataGrid } from "components/DataGrid";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import DialogHeader from "components/dialogs/DialogHeader";
import { useForm, useSnackbar } from "hooks";
import { Validation, isRequired } from "hooks/useForm";

interface Message {
  id: number;
  iddescribe: string;
  localization: string;
  message: string;
}

const Messages = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [openForm, setOpenForm] = useState<Message | "create" | null>(null);
  const [confirmDelete, setConfirmDelete] = useState<Message | null>(null);
  const { sendSnack } = useSnackbar();
  const validations: Validation[] = [
    ({ iddescribe }) =>
      isRequired(iddescribe) || { iddescribe: "iddescribe is required" },
    ({ localization }) =>
      isRequired(localization) || { localization: "localization is required" },
    ({ message }) => isRequired(message) || { message: "message is required" },
  ];
  const { values, isValid, reset, errors, touched, changeHandler } = useForm(
    {
      iddescribe: "",
      localization: "",
      message: "",
    },
    validations
  );
  const getMessages = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await messagesApi.get("");
      setMessages(data);
    } catch {
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!openForm || openForm === "create") {
      reset();
    } else {
      reset(openForm);
    }
  }, [openForm, reset]);

  useEffect(() => {
    getMessages();
  }, [getMessages]);

  const handleDelete = async () => {
    try {
      await messagesApi.delete(`${confirmDelete?.id}`);
      sendSnack({ message: "Message deleted success", type: "success" });
      getMessages();
      setConfirmDelete(null);
    } catch {
    } finally {
    }
  };
  const handleSave = async () => {
    try {
      if (openForm === "create") {
        await messagesApi.post("", values);
      } else {
        await messagesApi.put(`${openForm?.id}`, values);
      }
      sendSnack({ message: "Message saved success", type: "success" });
      getMessages();
      setOpenForm(null);
    } catch {
    } finally {
    }
  };
  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={() => setOpenForm(null)}
        open={Boolean(openForm)}
      >
        <DialogHeader onClose={() => setOpenForm(null)}>
          {openForm === "create"
            ? "Create new translation"
            : `Edit translation`}
        </DialogHeader>
        <DialogContent>
          <Stack mt={1} spacing={2}>
            <TextField
              label="ID Describe"
              size="small"
              error={Boolean(touched.iddescribe && errors.iddescribe)}
              helperText={touched.iddescribe && errors.iddescribe}
              value={values.iddescribe}
              onChange={({ target }) =>
                changeHandler("iddescribe", target.value)
              }
            />
            <TextField
              label="Localization"
              size="small"
              error={Boolean(touched.localization && errors.localization)}
              helperText={touched.localization && errors.localization}
              value={values.localization}
              onChange={({ target }) =>
                changeHandler("localization", target.value)
              }
            />
            <TextField
              label="Message"
              size="small"
              error={Boolean(touched.message && errors.message)}
              helperText={touched.message && errors.message}
              value={values.message}
              onChange={({ target }) => changeHandler("message", target.value)}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button
              variant="outlined"
              size="small"
              onClick={() => setOpenForm(null)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              disabled={!isValid}
              onClick={handleSave}
            >
              Save
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={() => setConfirmDelete(null)}
        open={Boolean(confirmDelete)}
      >
        <DialogHeader onClose={() => setConfirmDelete(null)}>
          Confirm delete
        </DialogHeader>
        <DialogContent>
          Delete {confirmDelete?.iddescribe} for {confirmDelete?.localization} ?
        </DialogContent>
        <DialogActions>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button
              variant="outlined"
              size="small"
              onClick={() => setConfirmDelete(null)}
            >
              Cancel
            </Button>
            <Button size="small" onClick={handleDelete}>
              Confirm
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
      {loading ? (
        <CircularProgress />
      ) : (
        <Stack spacing={2}>
          <Stack spacing={2} direction="row">
            <Button
              variant="contained"
              startIcon={<Add />}
              size="small"
              onClick={() => setOpenForm("create")}
            >
              Add
            </Button>
          </Stack>
          <DataGrid
            rows={messages}
            keyGetter={(message) => `message-${message.id}`}
            columns={[
              {
                key: "iddescribe",
                name: "describe",
                flex: 1,
              },
              {
                name: "Message",
                key: "message",
                flex: 1,
              },
              {
                name: "Localization",
                key: "localization",
                flex: 1,
              },
              {
                name: "Action",
                key: "",
                RenderCell: ({ row }) => (
                  <Stack direction="row" spacing={2}>
                    <IconButton onClick={() => setOpenForm(row)}>
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => setConfirmDelete(row)}>
                      <Delete />
                    </IconButton>
                  </Stack>
                ),
              },
            ]}
            groupKeys={["iddescribe"]}
          />
        </Stack>
      )}
    </React.Fragment>
  );
};

export default Messages;
