//@ts-ignore
import BpmnModeler from "bpmn-js/lib/Modeler";
import {
  ProcessModelDataTypes,
} from "hooks/useBpmnModeler/useBpmnJsModeler";
import ElementProperties from "./PropertyPanelEditor";
import { useTranslations } from "hooks";
import { Box, Typography } from "@mui/material";
import { getTranslation } from "common";
import { ExtensionElementType } from "model/processModel";

interface PropertiesViewTypes {
  modeler: BpmnModeler;
  selectedElements: any;
  currentElement: any;
  processModelData: ProcessModelDataTypes;
  getRequestBodyForExtensionElements: () => Array<ExtensionElementType>;
  setProcessModelData: (data: ProcessModelDataTypes) => void;
  // getSelectedLanguage: () => string;
  openDiagram: (xml: string, shouldShowInTheViewPort: boolean, onSuccess?: () => void) => Promise<void>;
  primaryPropertiesNameRef: any;
}

const PropertiesView = (props: PropertiesViewTypes) => {
  const {
    modeler,
    selectedElements,
    currentElement,
    processModelData,
    getRequestBodyForExtensionElements,
    setProcessModelData,
    // getSelectedLanguage,
    openDiagram,
    primaryPropertiesNameRef,
  } = props;
  const translations = useTranslations();
  return (
    <Box height={"100%"} sx={{ overflowY: "auto" }}>
      {selectedElements.length === 1 && currentElement && (
        <ElementProperties
          modeler={modeler}
          element={currentElement}
          processModelData={processModelData}
          getRequestBodyForExtensionElements={
            getRequestBodyForExtensionElements
          }
          setProcessModelData={setProcessModelData}
          // getSelectedLanguage={getSelectedLanguage}
          openDiagram={openDiagram}
          primaryPropertiesNameRef={primaryPropertiesNameRef}
        />
      )}
      {selectedElements.length === 0 && (
        <Typography>
          {getTranslation(translations, "pm.propertyeditor.label.select_element")}
        </Typography>
      )}
      {selectedElements.length > 1 && (
        <Typography>
          {getTranslation(translations, "pm.propertyeditor.label.select_single_element")}
        </Typography>
      )}
    </Box>
  );
};

export default PropertiesView;
