import React, { useRef } from "react";
import {
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  TextField,
} from "@mui/material";
import { getTranslation } from "common";
import { useTranslations } from "hooks";
import DialogHeader from "components/dialogs/DialogHeader";

interface InputPopUpProps {
  labels: {
    title: string;
    cancel?: string;
    confirm?: string;
    inputLabel: string;
  };
  callback: (value: string) => void;
  open: boolean;
  onClose: () => void;
}

const InputPopUp: React.FC<InputPopUpProps> = ({
  labels,
  callback,
  open,
  onClose,
}) => {
  const translations = useTranslations();
  const inpRef = useRef<HTMLInputElement | null>(null);

  const handleOkPopUp = () => {
    if (inpRef.current) {
      callback(inpRef.current.value);
      onClose();
    }
  };
  return (
    // <Portal>
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="xs"
    >
      <form>
        <DialogHeader
          onClose={onClose}
        >
          {labels.title}
        </DialogHeader>
        <DialogContent>
          <TextField
          id="export-model-field"
            inputRef={inpRef}
            type="name"
            sx={{ mt: 1 }}
            size="small"
            name="value"
            fullWidth
            label={labels.inputLabel}
          />
        </DialogContent>
        <DialogActions>
          <Button
            id="close-input-popup"
            size="small"
            variant="contained"
            onClick={onClose}
          >
            {labels.cancel ||
              getTranslation(
                translations,
                "pm.startprocess.button.cancel.caption"
              )}
          </Button>
          <Button
            id="save-name-model"
            size="small"
            type="submit"
            variant="contained"
            onClick={(e) => {
              e.preventDefault();
              handleOkPopUp();
            }}
          >
            {labels.confirm ||
              getTranslation(translations, "pm.buttons.action.save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
    // </Portal>
  );
};

export default InputPopUp;
