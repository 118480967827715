import { useCallback, useState } from "react";

const useVersions = () => {
  const [versions, setVersions] = useState<Record<number, number>>({});
  
  const updateVersion = useCallback((id: number | undefined | null) => {
    setVersions((_versions) => ({
      ..._versions,
      [id || -1]: _versions[id || -1] ? _versions[id || -1] + 1 : 1,
    }));
  }, []);

  const getVersion = (id: number | undefined | null) => versions[id || -1];
  
  return {
    getVersion,
    updateVersion,
  };
};

export default useVersions;
