import { createTheme, responsiveFontSizes } from "@mui/material";

// custom.d.ts

export const DRAWER_WIDTH = 199;
export const HEADER_HEIGHT = "60px";
const mode = "light";
const primary = "#54bdc1";

const theme = createTheme({ palette: { mode } });
// const defaultShadows: ThemeOptions["shadows"] = [...theme.shadows];
const customTheme = responsiveFontSizes(createTheme({
  spacing: 4,
  // shadows: defaultShadows.map(() => "none") as Shadows,
  typography: {
    h1: {
      fontFamily: "Poppins-Bold",
      fontSize: 32,
    },
    h2: {
      fontFamily: "Poppins-Bold",
      fontSize: 28,
    },
    h3: {
      fontFamily: "Poppins-Bold",
      fontSize: 24,
    },
    h4: {
      fontFamily: "Poppins-Bold",
      fontSize: 20,
    },
    h5: {
      fontFamily: "Poppins-Bold",
      fontSize: 18,
    },
    h6: {
      fontFamily: "Poppins-Bold",
      fontSize: 16,
    },
    allVariants: {
      fontFamily: "Poppins-Light",
      fontSize: 14
    },
    button: {
      textTransform: "capitalize",
      fontSize: 14
    },
  },
  components: {
    MuiMenu: {
      defaultProps: {
        sx: {
          "& > .MuiPaper-root": {
            boxShadow:
              "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
          },
        },
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        ".djs-overlay-container": { zIndex: 100 },
        ".editor-code": {
          "&::after": { color: "inherit" },
          "&::before": {
            backgroundColor: "transparent",
            borderRight: `1px solid ${theme.palette.divider}`,
          },
          backgroundColor: "transparent",
        },
        ".editor-text-code": { backgroundColor: "transparent" },
        ".editor-link": { color: primary },
        body: {
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "transparent",
            width: 5,
            height: 5,
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "#6b6b6b",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "#2b2b2b",
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          border: "2px solid transparent",
          "&.Mui-selected": {
            borderRadius: "5px",
            border: "2px solid #54bdc1",
          },
          "& > .MuiListItemSecondaryAction-root": {
            position: "sticky",
            transform: "none!important",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "&.dashed": {
            "& fieldset": {
              borderStyle: "dashed!important",
            },
          },
        },
      },
    },
  },
  palette: {
    mode,
    primary: {
      main: primary,
      light: "#89f0f4",
      dark: "#0c8c91",
      contrastText: "#fff",
    },
    secondary: {
      main: "#1e355f",
      light: "#4c5e8d",
      dark: "#000f35",
      contrastText: "#fff",
    },
    grey: {
      50: "#fafafa",
      100: "#f5f5f5",
      200: "#eeeeee",
      300: "#e0e0e0",
      400: "#bdbdbd",
      500: "#9e9e9e",
      600: "#757575",
      700: "#616161",
      800: "#424242",
      900: "#212121",
    },
  },
}));
export const CLOSE_SIDEBAR_WIDTH = `62px`;
export default customTheme;
