import React from "react";
import { List, Typography, Box } from "@mui/material";
import { useTranslations } from "hooks";
import { getTranslation } from "common";
import { UsersOfSelectedOrganization } from "./NewProcessModule";
import UserAssociationItem from "./AssociationComponents/UserAssociationItem";
import { ExtensionElements } from "model/processModel";

//fatto su modello di AssociationList
const UserAssociationList: React.FC<{
  listToBeDisplayed?: UsersOfSelectedOrganization[];
  extensionElements: ExtensionElements | undefined;
  getUsername: (value: UsersOfSelectedOrganization) => string;
  onLaneMouseDown: any;
  selectedLane: string;
  oppAccordianRef: any;
  containerHeight: number; 
}> = ({
  listToBeDisplayed,
  extensionElements,
  getUsername,
  onLaneMouseDown,
  selectedLane,
  oppAccordianRef,
  containerHeight,
}) => {
  const translations = useTranslations();
  const laneParticipantIds: string[] =
    (extensionElements &&
      selectedLane &&
      extensionElements[selectedLane]?.map(
        (extEl) => `${extEl.idparticipant}-${extEl.idparticipanttype}`
      )) ||
    [];

  return (
    <Box>
      <Typography variant="subtitle1" fontWeight="bold">
        {getTranslation(translations, "users.name")}
      </Typography>
      {/* questo -100 è un po' così cosà forse */}
      <Box sx={{ overflowY: "auto", maxHeight: containerHeight- 100 }}>
        <List dense disablePadding>
          {listToBeDisplayed?.map((ele) => (
            <UserAssociationItem
              key={`${ele.id}-3`}
              getUsername={getUsername}
              ele={ele}
              selected={laneParticipantIds.includes(`${ele.id}-3`)}
              onLaneMouseDown={onLaneMouseDown}
              selectedLane={selectedLane}
            />
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default UserAssociationList;
