import React, { useContext, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslations } from "hooks";
import { getTranslation } from "common";
import { AuthContext } from "contexts"; import { usersApi } from "api";

export default function Welcome() {
  const translations = useTranslations();
  const {
    state: { registerUser },
    //@damiano
    actions: { setUser, setJwt },
  } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleClick = async () => {
    const token = registerUser?.jwt || "";
    if (!token) {
      console.error("No Token");
      return;
    }
    try {
      setJwt(token);
      const result = await usersApi.get(`${token}`);
      if (result?.data?.user) {
        setUser({
          jwt: token,
          userId: result.data.user.id,
          email: result.data.user.email,
          username: result.data.user.username,
          firstname: result.data.user.firstname,
          lastname: result.data.user.lastname,
          picture: result.data.user.picture,
          linkprofile: result.data.user.linkprofile,
          subscriptiontype: result.data.user.subscriptiontype,
          i18n: result.data.user.i18n,
          dob: result.data.user.dob,
        });
        navigate("/dashboard/organizations");
      } else {
      }
    } catch {
    }
  };

  useEffect(() => {
    const token = registerUser?.jwt || "";
    if (!token) {
      navigate("/");
    }
  }, [navigate, registerUser?.jwt]);

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <Container
        maxWidth="sm"
        sx={{
          justifyContent: "center",
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={getTranslation(
              translations,
              "register.label.welcome"
            ).replace("%1", registerUser?.email || "")}
          />
          <CardActions>
            <Button
              id="get-started-button"
              size="small"
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleClick}
            >
              {getTranslation(translations, "register.label.welcome_button")}
            </Button>
          </CardActions>
        </Card>
      </Container>
    </Box>
  );
}
