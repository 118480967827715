import { CreateAppendAnythingModule } from "bpmn-js-create-append-anything";
// WORKAROUND TO ACCESS TO APPEND MENU PROVIDER
const AppendMenuProvider = CreateAppendAnythingModule.__depends__[0].appendMenuProvider[1];
// HAD NOT FOUND A SOLUTION IN THE WEB

const SUPPORTED_ELEMENTS = [
  "append-exclusive-gateway",
  "append-parallel-gateway",
  // "append-inclusive-gateway",
  // "append-complex-gateway",
  // "append-event-based-gateway",
  "append-task",
  // "append-user-task",
  // "append-service-task",
  // "append-send-task",
  // "append-receive-task",
  // "append-manual-task",
  // "append-rule-task",
  // "append-script-task",
  // "append-call-activity",
  // "append-transaction",
  // "append-event-subprocess",
  // "append-collapsed-subprocess",
  // "append-expanded-subprocess",
  // "append-none-intermediate-throwing",
  "append-none-end-event",
  // "append-message-intermediate-catch",
  // "append-message-intermediate-throw",
  "append-timer-intermediate-catch",
  // "append-escalation-intermediate-throw",
  // "append-conditional-intermediate-catch",
  // "append-link-intermediate-catch",
  // "append-link-intermediate-throw",
  // "append-compensation-intermediate-throw",
  // "append-signal-intermediate-catch",
  // "append-signal-intermediate-throw",
  // "append-message-end",
  // "append-escalation-end",
  // "append-error-end",
  // "append-cancel-end",
  // "append-compensation-end",
  // "append-signal-end",
  // "append-terminate-end",
  // "append-message-boundary",
  // "append-timer-boundary",
  // "append-escalation-boundary",
  // "append-conditional-boundary",
  // "append-error-boundary",
  // "append-cancel-boundary",
  // "append-signal-boundary",
  // "append-compensation-boundary",
  // "append-non-interrupting-message-boundary",
  // "append-non-interrupting-timer-boundary",
  // "append-non-interrupting-escalation-boundary",
  // "append-non-interrupting-conditional-boundary",
  // "append-non-interrupting-signal-boundary",
  // "append-data-store-reference",
  // "append-data-object-reference"
]
const _getPopupMenuEntries = AppendMenuProvider.prototype.getPopupMenuEntries

AppendMenuProvider.prototype.getPopupMenuEntries = function (element) {
  const entries = _getPopupMenuEntries.apply(this, [element]);
  const result = Object.fromEntries(Object.entries(entries).filter(([key]) => SUPPORTED_ELEMENTS.includes(key)).map(([key, values]) => [key, ({ ...values, rank: undefined })]));
  return result;
};

export default CreateAppendAnythingModule;