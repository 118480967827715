import React, { useState, useEffect, useCallback } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Box,
    Grid,
    IconButton,
    Tooltip,
} from '@mui/material';
import { Robot } from 'hooks/useRobots';
import AvatarRem from './AvatarRem';
import { PrecisionManufacturing, Add as AddIcon, Close } from '@mui/icons-material';
import { useSelectedOrganization, useTranslations } from 'hooks';
import { organizationsApi } from 'api';
import { useSnackbar } from 'hooks';
import { getTranslation } from 'common';

interface RobotDetailsProps {
    open: boolean;
    onClose: () => void;
    robot: Robot | undefined;
    onRobotUpdate?: () => Promise<void>;
}

const RobotDetails: React.FC<RobotDetailsProps> = ({ open, onClose, robot, onRobotUpdate }) => {
    const organization = useSelectedOrganization();
    const { sendSnack } = useSnackbar();
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isRobotInOrganization, setIsRobotInOrganization] = useState(false);
    const translations = useTranslations();

    const checkRobotInOrganization = useCallback(() => {
        if (robot && robot.organizations && organization) {
            const robotInOrg = robot.organizations.some(org => org.name === organization.name);
            setIsRobotInOrganization(robotInOrg);
        } else {
            setIsRobotInOrganization(false);
        }
    }, [robot, organization]);

    useEffect(() => {
        checkRobotInOrganization();
    }, [checkRobotInOrganization]);

    const handleOpenAddDialog = () => setOpenAddDialog(true);
    const handleCloseAddDialog = () => setOpenAddDialog(false);

    const handleChangeOrganization = async () => {
        setLoading(true);
        try {
            const response = await organizationsApi.put(`${organization?.id}/robots/${robot?.id}`);
            sendSnack({
                message: response.data.responsemessage,
                type: 'success',
            });
            //  console.log("sono fuori")
            //  console.log(onRobotUpdate, "fuori dall'if")
            if (onRobotUpdate) {
                //      console.log("sono dentro 1")
                await onRobotUpdate();
                //      console.log("sono dentro 2")
            }
            setIsRobotInOrganization(true);
            handleCloseAddDialog();
        } catch { } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
                <DialogTitle>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h3">{getTranslation(translations, "robot.details")}</Typography>
                        <Box>
                            <Tooltip title={getTranslation(translations, "generic.add")}>
                                <IconButton
                                    color="primary"
                                    onClick={handleOpenAddDialog}
                                    disabled={loading || isRobotInOrganization}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                            <IconButton onClick={onClose} color="primary">
                                <Close />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box display="flex" justifyContent="center">
                                <AvatarRem
                                    sx={{ width: 120, height: 120 }}
                                    icon={robot?.picture || undefined}
                                >
                                    <PrecisionManufacturing sx={{ width: '50%', height: '50%' }} />
                                </AvatarRem>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Typography variant="h5" gutterBottom>{robot?.name}</Typography>
                            <Typography variant="body2" paragraph>
                                {robot?.description}
                            </Typography>
                            <Typography variant="subtitle1">
                                {getTranslation(translations, "robot.owner.label")}: <span style={{ fontWeight: "bold" }}>{robot?.user?.firstname} {robot?.user?.lastname}</span>
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            {/* Dialog di conferma aggiunta */}
            <Dialog open={openAddDialog} onClose={handleCloseAddDialog}>
                <DialogTitle>{getTranslation(translations, "robot.confirm.add")}</DialogTitle>
                <DialogContent>
                    <Typography>
                        {getTranslation(translations, "generic.add")} <span style={{ fontWeight: "bold" }}>{robot?.name}</span> {getTranslation(translations, "generic.to")} <span style={{ fontWeight: "bold" }}>{organization?.name}</span>?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddDialog} color="primary">{getTranslation(translations, "generic.button.cancel")}</Button>
                    <Button onClick={handleChangeOrganization} color="primary" variant="contained" disabled={loading}>
                        {getTranslation(translations, "generic.add")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default RobotDetails;