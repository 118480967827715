import React from "react";
import { List, Typography, Box } from "@mui/material";
import { useTranslations } from "hooks";
import { getTranslation } from "common";
import { GroupModel } from "./NewProcessModule";
import GroupAssociationItem from "./AssociationComponents/GroupAssociationItem";
import { ExtensionElements } from "model/processModel";

//fatto su modello di AssociationList
const GroupAssociationList: React.FC<{
  listToBeDisplayed: GroupModel[];
  extensionElements: ExtensionElements | undefined;
  onLaneMouseDown: any;
  selectedLane: string;
  oppAccordianRef: any;
  containerHeight: number;
}> = ({
  listToBeDisplayed,
  extensionElements,
  onLaneMouseDown,
  selectedLane,
  containerHeight,
  oppAccordianRef,
}) => {
  const translations = useTranslations();
  const laneParticipantIds: string[] =
    (extensionElements &&
      selectedLane &&
      extensionElements[selectedLane]?.map(
        (extEl) => `${extEl.idparticipant}-${extEl.idparticipanttype}`
      )) ||
    [];

  return (
    <Box>
      <Typography variant="subtitle1" fontWeight="bold">
        {getTranslation(translations, "groups.name")}
      </Typography>
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          maxHeight: containerHeight - 100,
        }}
      >
        <List dense disablePadding>
          {listToBeDisplayed.map((ele) => (
            <GroupAssociationItem
              key={`association-group-${ele.id}`}
              selected={laneParticipantIds.includes(`${ele.id}-1`)}
              ele={ele}
              onLaneMouseDown={onLaneMouseDown}
              selectedLane={selectedLane}
              ind={ele.id}
            />
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default GroupAssociationList;
