import React, { useCallback, useMemo } from "react";
import { ExtendedTask } from "model/statisticsModel";
import { Box, Card, Typography } from "@mui/material";
import dayjs from "dayjs";
import { getTranslation } from "common";
import { useTranslations } from "hooks";

export const StandardTooltipContent: React.FC<{
  formatDate: (value: Date, includeTime?: boolean) => string;
  task: ExtendedTask;
  fontSize: string;
  fontFamily: string;
}> = ({ task, fontSize, fontFamily, formatDate }) => {
  const translations = useTranslations();
  const dict = useMemo(
    () => ({
      "{$}": getTranslation(translations, "generic.years.caption"),
      "{$$}": getTranslation(translations, "generic.months.caption"),
      "{$$$}": getTranslation(translations, "generic.days.caption"),
      "{$$$$}": getTranslation(translations, "generic.hours.caption"),
      "{$$$$$}": getTranslation(translations, "generic.minutes.caption"),
      "{$$$$$$}": getTranslation(translations, "generic.seconds.caption"),
    }),
    [translations]
  );
  const style = {
    fontSize,
    fontFamily,
  };
  const isModel = task.taskType === "processmodel";
  const isProcess = task.taskType === "process";
  const isTask = !isProcess && !isModel;

  const replaceDatePlaceHolder = useCallback(
    (formattedDate: string) => {
      Object.entries(dict).forEach(([key, value]) => {
        formattedDate = formattedDate.replace(key, value);
      });
      return formattedDate;
    },
    [dict]
  );

  const formatDuration = (seconds: number | undefined) => {
    if (seconds) {
      let format = "ss {$$$$$$}";
      if (seconds > 60) {
        format = "mm {$$$$$} " + format;
      }
      if (seconds > 3600) {
        format = "HH {$$$$} " + format;
      }
      if (seconds > 24 * 3600) {
        format = "DD {$$$} " + format;
      }
      if (seconds > 24 * 3600 * 30) {
        format = "MM {$$} " + format;
      }
      if (seconds > 24 * 3600 * 365) {
        format = "YY {$} " + format;
      }
      // if(seconds > 24 * 3600 * )
      return format;
    }
    return "";
  };

  return (
    <Box
      component={Card}
      sx={{
        p: 2,
        boxShadow:
          "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
      }}
    >
      <Typography sx={{ ...style, fontWeight: "bold" }}>
        {isModel
          ? `${getTranslation(translations, "pm.label.caption")}: ${task.name}`
          : isProcess
            ? `${getTranslation(translations, "generic.processes")}: ${task.name}`
            : `${getTranslation(translations, "generic.task.caption")}: ${task.name}`}
      </Typography>

      {!isModel && (
        <React.Fragment>
          <Typography sx={{ ...style }}>
          {getTranslation(translations, "pm.processstart.start.label")}:{formatDate(task.start, true)}
          </Typography>
          <Typography sx={{ ...style }}>
          {getTranslation(translations, "generic.end.caption")}:{formatDate(task.end, true)}
          </Typography>
        </React.Fragment>
      )}
      {isTask && (
        <Typography sx={{ ...style }}>
          {getTranslation(translations, "generic.user")}:{task.userfullname}
        </Typography>
      )}
      {isTask && (
        <Typography sx={{ ...style }}>
           {getTranslation(translations, "generic.waitingtime.caption")}:{" "}
          {replaceDatePlaceHolder(
            dayjs
              .duration(task.waitingSeconds || 0, "seconds")
              .format(formatDuration(task.waitingSeconds))
          )}
        </Typography>
      )}
      {!isModel && (
        <Typography sx={{ ...style }}>
           {getTranslation(translations, "generic.workingtime.caption")}:{" "}
          {replaceDatePlaceHolder(
            dayjs
              .duration(task.workingSeconds || 0, "seconds")
              .format(formatDuration(task.workingSeconds))
          )}
        </Typography>
      )}
    </Box>
  );
};

export default StandardTooltipContent;
