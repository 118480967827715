import { Box, Divider, Fab, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ButtonBar, ChoosePanel, OrganizationsPanel } from "../components";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import "swiper/css";
import UserSelectedPanel from "components/UserSelectedPanel";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { useBreakpoint, useSelectedOrganization } from "hooks";
import { User } from "components/UserItem";
import { addSelectedOrganization } from "Redux/organizationReducer";
import { useDispatch } from "react-redux";

const Organizations2 = () => {
  const selectedOrganization = useSelectedOrganization();
  const administrator = Boolean(selectedOrganization?.administrator);
  const dispatch = useDispatch();
  const updateSelectedOrganization = (id: number | null) => {
    dispatch(addSelectedOrganization({ id }));
  };
  const getUserUntilPageRef = useRef<
    ((userSelected: User) => Promise<void>) | undefined
  >(undefined);
  const getOrganizations = useRef<Function | undefined>();
  const getInvitedUsers = useRef<Function | undefined>();
  const getGroups = useRef<Function | undefined>();
  const getUsers = useRef<Function | undefined>();
  const getRoles = useRef<Function | undefined>();
  const getRobots = useRef<Function | undefined>();
  const [userSelected, setUserSelected] = useState<User | null>(null);
  const [unit, setUnit] = useState<number>(1);
  const [view, setView] = useState<number>(0);
  const isMobile = useBreakpoint("down", "md");
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);

  useEffect(() => {
    swiper?.slideTo(view);
  }, [view, swiper]);

  useEffect(() => {
    if (selectedOrganization && swiper) {
      // swiper.slideTo(0);
      setView(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganization, swiper]);
  // useEffect(() => {
  //   if(userSelected) {
  //     swiper?.
  //   }
  // }, [userSelected, swiper])
  useEffect(() => {
    if (userSelected) {
      setView(2);
    }
  }, [userSelected]);

  return (
    <React.Fragment>
      {isMobile && (
        <Box
          sx={{ height: "100%", overflow: "hidden" }}
          display={{ xs: "flex", md: "none" }}
        >
          <ButtonBar
            organizationId={selectedOrganization?.id}
            getOrganizations={getOrganizations}
            getGroups={getGroups}
            getInvitedUsers={getInvitedUsers}
            getRoles={getRoles}
            getRobots={getRobots}
            administrator={administrator}
          />
          <Box
            sx={{
              zIndex: 1299,
              position: "absolute",
              bottom: (_) => _.spacing(2),
              left: (_) => _.spacing(2),
            }}
          >
            <Stack direction="row" spacing={2}>
              <Fab
                color="primary"
                disabled={view === 0}
                onClick={() => setView((_) => (_ <= 0 ? _ : _ - 1))}
              >
                <ChevronLeft />
              </Fab>
              <Fab
                color="primary"
                disabled={
                  !selectedOrganization?.id ||
                  (!userSelected?.id && view === 1) ||
                  view === 2
                }
                onClick={() =>
                  setView((_) => (_ >= (!userSelected?.id ? 1 : 2) ? _ : _ + 1))
                }
              >
                <ChevronRight />
              </Fab>
            </Stack>
          </Box>
          <Swiper
            onSwiper={setSwiper}
            style={{ height: "100%", width: "100%" }}
            slidesPerView={1}
            onActiveIndexChange={(slider: SwiperClass) => {
              setView(slider.activeIndex);
            }}
            allowSlideNext={!(view === 1 && !userSelected)}
          >
            <SwiperSlide>
              <Box
                sx={{
                  pt: (_) => _.spacing(1),
                  flex: 1,
                  height: "100%",
                  // width: view === 0 ? "100%" : "0px",
                }}
              >
                <OrganizationsPanel
                  ref={(ref) =>
                    (getOrganizations.current = ref?.getOrganizations)
                  }
                  onSelect={updateSelectedOrganization}
                  selectedId={selectedOrganization?.id}
                />
              </Box>
            </SwiperSlide>
            <SwiperSlide>
              <Box
                sx={{
                  pt: (_) => _.spacing(1),
                  flex: 1,
                  height: "100%",
                  // width: "100%",
                }}
              >
                {selectedOrganization?.id && (
                  <ChoosePanel
                    inMobile
                    unit={unit}
                    setUnit={setUnit}
                    userSelected={userSelected}
                    setUserSelected={setUserSelected}
                    administrator={administrator}
                    organizationId={selectedOrganization.id || 0}
                    ref={(ref) => {
                      getInvitedUsers.current = ref?.getInvitedUsers;
                      getGroups.current = ref?.getGroups;
                      getUsers.current = ref?.getUsers;
                      getRoles.current = ref?.getRoles;
                      getRobots.current = ref?.getRobots;
                    }}
                  />
                )}
              </Box>
            </SwiperSlide>
            <SwiperSlide>
              <Box
                // key={key}
                sx={{
                  pt: (_) => _.spacing(1),
                  flex: 1,
                  height: "100%",
                  // width: "100%",
                }}
              >
                {userSelected?.id && (
                  <UserSelectedPanel
                    // to remove with sse
                    refresh={() => getUsers.current && getUsers.current()}
                    getUserUntilPage={getUserUntilPageRef.current}
                    inMobile
                    userLoggedIsAdmin={administrator}
                    userSelected={userSelected}
                    organizationId={selectedOrganization?.id || 0}
                  />
                )}
              </Box>
            </SwiperSlide>
          </Swiper>
        </Box>
      )}
      <Stack
        display={{ xs: "none", md: "flex" }}
        sx={{ height: "100%" }}
        spacing={1}
      >
        <ButtonBar
          organizationId={selectedOrganization?.id}
          getOrganizations={getOrganizations}
          getGroups={getGroups}
          getInvitedUsers={getInvitedUsers}
          getRoles={getRoles}
          getRobots={getRobots}
          administrator={administrator}
        />
        <Stack
          sx={{ height: "100%", overflow: "auto", pt: (_) => _.spacing(1) }}
          direction="row"
          display={{ xs: "none", md: "flex" }}
        >
          <OrganizationsPanel
            ref={(ref) => (getOrganizations.current = ref?.getOrganizations)}
            onSelect={updateSelectedOrganization}
            selectedId={selectedOrganization?.id}
          />
          <Divider flexItem orientation="vertical" sx={{ m: 1 }} />
          {selectedOrganization?.id && (
            <ChoosePanel
              unit={unit}
              setUnit={setUnit}
              userSelected={userSelected}
              setUserSelected={setUserSelected}
              administrator={administrator}
              organizationId={selectedOrganization?.id}
              ref={(ref) => {
                getUserUntilPageRef.current = ref?.getUsersUntilPage;
                getInvitedUsers.current = ref?.getInvitedUsers;
                getGroups.current = ref?.getGroups;
                getUsers.current = ref?.getUsers;
                getRoles.current = ref?.getRoles;
                getRobots.current = ref?.getRobots;
              }}
            />
          )}
        </Stack>
      </Stack>
    </React.Fragment>
  );
};

export default Organizations2;
