import ZoomInIcon from "@mui/icons-material/ZoomIn";

import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { Box, IconButton, Stack } from "@mui/material";

//@ts-ignore
import BpmnModeler from "bpmn-js/lib/Modeler";
import { useSelectedOrganization } from "hooks";

interface DropZoneElementTypes {
  shouldShowDropZoneError: boolean;
  onClickEditorCollapse: () => void;
  shouldShowFlowNodeEditor: boolean;
  bpmnModeler: BpmnModeler;
  dropZoneRef: any;
}

const DropZoneElement: React.FC<DropZoneElementTypes> = ({
  shouldShowDropZoneError,
  bpmnModeler,
  dropZoneRef,
}) => {
  const selectedOrganization = useSelectedOrganization();
  const isAdmin = selectedOrganization?.administrator;
  return (
    <div
      className={`content ${shouldShowDropZoneError ? "with-error" : "with-diagram"
        }`}
      id="js-drop-zone"
      ref={dropZoneRef}
    >
      {isAdmin && (
        <Stack
          sx={{ position: "absolute", top: 190, left: 20, zIndex: 10 }}
          direction="row"
          spacing={1}
        >
          <IconButton
            onClick={() => {
              bpmnModeler.get<any>("zoomScroll").zoom(1);
            }}
          >
            <ZoomInIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              bpmnModeler.get<any>("zoomScroll").zoom(-1);
            }}
          >
            <ZoomOutIcon />
          </IconButton>
        </Stack>
      )}
      <Box sx={{
        "& .bjs-drilldown": { display: "none" },
        "& .djs-minimap": { display: isAdmin ? "block" : "none" },
        "& .djs-palette": { display: isAdmin ? "block" : "none" },
        "& .djs-palette-open": {
          width: "calc(100% - 118px)!important",
        }
      }}
        className="canvas" id="js-canvas"></Box>
    </div>
  );
};

export default DropZoneElement;
