import { SelectedOrganizationType } from "model/organizationModel";
import { useSelector } from "react-redux";
import { RootState } from "store";

const useSelectedOrganization = () => {
  const selectedOrganization: SelectedOrganizationType | null = useSelector(
    (state: RootState) => state.organization.selectedOrganization
  );


  return selectedOrganization;
};

export default useSelectedOrganization;
