import Snackbar from "model/snackbarModel";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { addSnackbar } from "Redux/snackbarReducer";

const useSnackbar = () => {
  const dispatch = useDispatch();

  const sendSnack = useCallback(
    (payload: Snackbar) => dispatch(addSnackbar(payload)),
    [dispatch]
  );

  return { sendSnack };
};

export default useSnackbar;
