import user, { RegisterUser } from "model/userModel";
import createContext, { Action } from "./createDataContext";
import { DispatchAction } from "./createDataContext";
import { CONSTANTS } from "common";

const SET_USER = "SET_USER";
const SET_JWT = "SET_JWT";
const LOGOUT = "LOGOUT";
const SET_USER_AND_JWT = "SET_USER_AND_JWT";
const SET_CHECK_IN_APP = "SET_CHECK_IN_APP";
const SET_REGISTER_USER = "SET_REGISTER_USER";

const INITIAL_STATE: {
  user: user | null;
  registerUser: RegisterUser | null;
  jwt: string | null;
  checkInApp: boolean;
} = {
  user: null,
  jwt: null,
  registerUser: null,
  checkInApp: false,
};

const reducer = (state: typeof INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case SET_JWT: {
      return { ...state, jwt: action.payload };
    }
    case SET_REGISTER_USER: {
      return { ...state, registerUser: action.payload };
    }
    case SET_USER: {
      return { ...state, user: action.payload };
    }
    case SET_USER_AND_JWT: {
      return {
        ...state,
        user: action.payload,
        jwt: action.payload?.jwt,
        checkInApp: true,
      };
    }
    case SET_CHECK_IN_APP: {
      return { ...state, checkInApp: action.payload };
    }
    case LOGOUT: {
      return { ...INITIAL_STATE, checkInApp: true };
    }
    default: {
      return state;
    }
  }
};

const setJwt: DispatchAction<string, void> = (dispatch) => (jwt) => {
  dispatch({ type: SET_JWT, payload: jwt });
};
const setUser: DispatchAction<user, void> = (dispatch) => (user) => {
  localStorage.setItem(CONSTANTS.STORE_USER, JSON.stringify(user));
  dispatch({ type: SET_USER, payload: user });
};
const setRegisterUser: DispatchAction<RegisterUser, void> = (dispatch) => (user) => {
  dispatch({ type: SET_REGISTER_USER, payload: user });
};
const removeCheckInApp: DispatchAction<void, void> = (dispatch) => () => {
  dispatch({ type: SET_CHECK_IN_APP, payload: false });
};
const logout: DispatchAction<void, void> = (dispatch) => () => {
  localStorage.removeItem(CONSTANTS.STORE_USER);
  dispatch({ type: LOGOUT });
};
const setUserAndJwt: DispatchAction<user | null, void> =
  (dispatch) => (user) => {
    dispatch({ type: SET_USER_AND_JWT, payload: user });
  };
const actions = {
  setJwt,
  setUser,
  logout,
  setUserAndJwt,
  removeCheckInApp,
  setRegisterUser,
  // ACTIONS
};
type ActionsType = typeof actions;
export type ContextType = {
  state: typeof INITIAL_STATE;
  actions: {
    [Property in keyof ActionsType]: ReturnType<ActionsType[Property]>;
  };
};
export const { Context, Provider } = createContext<
  ContextType,
  typeof INITIAL_STATE,
  typeof actions
>(reducer, actions, INITIAL_STATE);
