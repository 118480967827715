import React, { useState, useRef, useMemo } from "react";
//import AssociationList from "../AssociationList";
import { CONSTANTS, getTranslation } from "common";
import { useTranslations } from "hooks";
import {
  Accordion,
  AccordionSummary,
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Resizable } from "re-resizable";
import SearchBar from "components/SearchBar";
//@ts-ignore
import {
  GroupModel,
  RolesModel,
  UsersOfSelectedOrganization,
} from "../NewProcessModule";
import UserAssociationList from "../UserAssociationList";
import GroupAssociationList from "../GroupAssociationList";
import RoleAssociationList from "../RoleAssociationList";
import RobotAssociationList from "../RobotAssociationList";
import { ExtensionElements } from "model/processModel";
import { Robot } from "hooks/useRobots";

const OrganizationPanel = ({
  extensionElements,
  handleCanvasViewPort,
  orgPanelPositionRef,
  allRolesOfSelectedOrganization,
  allGroupsOfSelectedOrganization,
  allUsersOfSelectedOrganization,
  allRobotsOfSelectedOrganization,
  getUsername,
  onLaneMouseDown,
  selectedLane,
  isOverlapChecked,
  setIsOverlapChecked,
}: {
  handleCanvasViewPort: () => void;
  extensionElements: ExtensionElements | undefined;
  orgPanelPositionRef: any;
  allRobotsOfSelectedOrganization: Array<Robot>;
  allRolesOfSelectedOrganization: Array<RolesModel>;
  allGroupsOfSelectedOrganization: Array<GroupModel>;
  allUsersOfSelectedOrganization: Array<UsersOfSelectedOrganization>;
  getUsername: (value: UsersOfSelectedOrganization) => string;
  onLaneMouseDown: (event: any, data: any) => void;
  selectedLane: string;
  isOverlapChecked: boolean;
  setIsOverlapChecked: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [filterText, setFilterText] = useState("");

  const [showOppAccordian, setShowOppAccordian] = useState<boolean>(false);
  const [containerSize, setContainerSize] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  }); //ho pensato di fare così per gestire le dimensioni così lo posso riusare tipo in riga 180 

  const resizableElementRef = useRef<any>(null);
  const oppAccordianRef = useRef<any>(null);
  const translations = useTranslations();
  
  const listOfRolesToBeDisplayed: {
    list: Array<RolesModel>;
  } = useMemo(() => {
    if (filterText) {
      const list = allRolesOfSelectedOrganization.filter((org: RolesModel) =>
        org.name.toLocaleLowerCase().includes(filterText)
      );
      return {
        list,
      };
    } else {
      return {
        list: allRolesOfSelectedOrganization,
      };
    }
  }, [allRolesOfSelectedOrganization, filterText]);

  const listOfRobotsToBeDisplayed: {
    list: Array<Robot>;
  } = useMemo(() => {
    if (filterText) {
      const list = allRobotsOfSelectedOrganization.filter((org: Robot) =>
        org.name.toLocaleLowerCase().includes(filterText)
      );
      return {
        list,
      };
    } else {
      return {
        list: allRobotsOfSelectedOrganization,
      };
    }
  }, [allRobotsOfSelectedOrganization, filterText]);

  const listOfGroupsToBeDisplayed: {
    list: Array<GroupModel>;
  } = useMemo(() => {
    if (filterText) {
      const list = allGroupsOfSelectedOrganization.filter((org: GroupModel) =>
        org.name.toLocaleLowerCase().includes(filterText)
      );
      return {
        list,
      };
    } else {
      return {
        list: allGroupsOfSelectedOrganization,
      };
    }
  }, [allGroupsOfSelectedOrganization, filterText]);

  const listOfUsersToBeDisplayed: {
    list: Array<UsersOfSelectedOrganization>;
  } = useMemo(() => {
    if (filterText) {
      const list = allUsersOfSelectedOrganization
        .filter((org: UsersOfSelectedOrganization) => {
          return (
            (org.username || org.lastname) &&
            (`${org.username} ${org.lastname}`
              .trim()
              .toLocaleLowerCase()
              .includes(filterText.toLocaleLowerCase()) ||
              `${org.email}`
                .trim()
                .toLocaleLowerCase()
                .includes(filterText.toLocaleLowerCase()))
          );
        })
        .sort();
      return {
        list,
      };
    } else {
      return {
        list: allUsersOfSelectedOrganization,
      };
    }
  }, [allUsersOfSelectedOrganization, filterText]);

  const handleChangeOverlap = (
    e: React.MouseEvent<HTMLLabelElement, MouseEvent>
  ) => {
    e.stopPropagation();
    e.preventDefault();
    setIsOverlapChecked((_: boolean) => {
      localStorage.setItem(CONSTANTS.STORE_OVERLAP_ENABLED, JSON.stringify(!_));
      return !_;
    });
  };

  return (
    <Resizable
      ref={resizableElementRef}
      className={`resizable-container ${
        isOverlapChecked
          ? "resizable-container-absolute"
          : "resizable-container-relative"
      }`}
      onResizeStart={() => {
        // setShouldResizeAutomatically(false);
        handleCanvasViewPort();
      }}
      //e è l'evento di resize
      //direction la direzione del resizing
      //ref è il riferimento al componente resizable (linea 164)
      //d è la differenza di dimensioni del componente durante il resize
      onResize={(e, direction, ref, d) => {  //questo elemento viene chiamato continuamanete quando l'elemento viene ridimensionato.
        const { width, height } = ref.style; //contiene le dimensioni dell'elemento ridimensionato
        setContainerSize({ width: parseFloat(width), height: parseFloat(height) }); //con parseFloat convertiamo le strings in numbers e setContainerSize aggiorna lo stato di containerSize
        // con le nuove dimensioni che poi viene passato ai componenti figli come props 
      }}
      onResizeStop={(e, direction, ref, d) => {
        if (Number(ref.style.height) <= 64) { //vuol castato a number e quindi se l'altezza è = o < di 64 px, imposta l'altezza del pannello a 64px
          orgPanelPositionRef.current = "64px";
          resizableElementRef.current.resizable.style.height = "64px";
          setShowOppAccordian(false);
        } else {
          orgPanelPositionRef.current = ref.style.height; //altrimenti tiene la dimensione del resize
        }
        // setShouldResizeAutomatically(false);
        handleCanvasViewPort();
      }}
      enable={{
        top: showOppAccordian,
        right: false,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
    >
      <Accordion
        disableGutters
        id="organization-action-container"
        // component={Paper}
        TransitionComponent={Collapse}
        TransitionProps={{ style: { flex: 1 } }}
        sx={{
          overflowY: "hidden",
          width: (_) => `calc(100% + ${_.spacing(1)})`,
          ml: (_) => `-${_.spacing(1)}`,
          clipPath: "inset(-200px 0px 0px 0px)",
          borderRadius: 0,
          height: "100%",
        }}
        expanded={showOppAccordian}
        onChange={(_, expanded) => {
          if (expanded) { // se accordion è espanso impostiamo l'altezza dell'ELEMENTO RESIZABLE a 200px
            resizableElementRef.current.resizable.style.height = "200px";
            setContainerSize((prev) => ({ ...prev, height: 200 })); //e aggiorniamo lo stato
          } else {
            resizableElementRef.current.resizable.style.height = "64px"; // se accordion è compresso impostiamo l'altezza dell'elemento resizable a 64px
            setContainerSize((prev) => ({ ...prev, height: 64 })); //aggiorniamo lo stato
          }
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={(e) => {
            e.stopPropagation();
            if (showOppAccordian) {
              const reducableHeight = oppAccordianRef.current?.clientHeight ||0; //evita l'errore di height undefined
              const originalHeight =
                resizableElementRef.current.resizable.clientHeight;
              const resultantHeight = originalHeight - reducableHeight;
              resizableElementRef.current.resizable.style.height = `${resultantHeight}px`;
            }
            setShowOppAccordian(!showOppAccordian);
          }}
        >
          <Stack
            flex={1}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Stack alignItems="center" direction="row" spacing={2}>
                <Typography variant="h6">
                  {getTranslation(translations, "pm.organizationchart.lable")}
                </Typography>
                <Box
                  onClick={(e) => {
                    if (showOppAccordian) {
                      e.stopPropagation();
                    }
                  }}
                >
                  <SearchBar
                    id="search-bar-panel-org"
                    placeholder={getTranslation(
                      translations,
                      "pm.organizationchart.ricerca.placeholder"
                    )}
                    setFilter={(text: string) => {
                      setFilterText(text.toLowerCase());
                    }}
                  />
                </Box>
              </Stack>
              {/* <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleFilter();
                }}
              >
                <Sort />
              </IconButton> */}
            </Stack>
            <FormControlLabel
              onClick={handleChangeOverlap}
              control={<Checkbox checked={isOverlapChecked} />}
              label={getTranslation(
                translations,
                "pm.organizationchart.overlapped.label"
              )}
            />
          </Stack>
        </AccordionSummary>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            height: containerSize.height,
            overflowY: "auto",
            pl: 2
          }}
        >
          <Box sx={{ flex: 1 }}>
            <UserAssociationList
              listToBeDisplayed={listOfUsersToBeDisplayed.list}
              extensionElements={extensionElements}
              getUsername={getUsername}
              onLaneMouseDown={onLaneMouseDown}
              selectedLane={selectedLane}
              containerHeight={containerSize.height}
              oppAccordianRef={oppAccordianRef}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <RoleAssociationList
              listToBeDisplayed={listOfRolesToBeDisplayed.list}
              extensionElements={extensionElements}
              onLaneMouseDown={onLaneMouseDown}
              selectedLane={selectedLane}
              containerHeight={containerSize.height}
              oppAccordianRef={oppAccordianRef}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <GroupAssociationList
              listToBeDisplayed={listOfGroupsToBeDisplayed.list}
              extensionElements={extensionElements}
              onLaneMouseDown={onLaneMouseDown}
              selectedLane={selectedLane}
              containerHeight={containerSize.height}
              oppAccordianRef={oppAccordianRef}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <RobotAssociationList
              listToBeDisplayed={listOfRobotsToBeDisplayed.list}
              extensionElements={extensionElements}
              onLaneMouseDown={onLaneMouseDown}
              selectedLane={selectedLane}
              containerHeight={containerSize.height}
              oppAccordianRef={oppAccordianRef}
            />
          </Box>
        </Box>
      </Accordion>
    </Resizable>
  );
};

export default OrganizationPanel;
