import { ListItemButton, ListItemText } from "@mui/material";
import { ProcessModelErrorsType } from "model/processModel";
import React from "react";

const ErrorList: React.FC<{
  errors: Array<ProcessModelErrorsType>;
  selectedError: number | null;
  setSelectedError: (errorId: number | null) => void;
}> = ({ errors, selectedError, setSelectedError }) => {
  return (
    <React.Fragment>
      {errors?.length! > 0 &&
        errors?.map((ele: ProcessModelErrorsType, ind: number) => (
          <ListItemButton
            key={`errors${ind}`}
            // className="organization-panel-specs"
            disabled={
              !(
                !ele.designerid.toLowerCase().includes("no") ||
                ele.designerid.split(" ").length === 1
              )
            }
            sx={{
              backgroundColor:
                selectedError === ind
                  ? "lightgray"
                  : "rgb(244, 245, 247)",
              cursor: "pointer",
            }}
            onClick={() => {
              // const modeling = bpmnModeler.get("modeling");
              // const element = bpmnModeler.get("elementRegistry");
              const isSelected = selectedError === ind;
              setSelectedError(isSelected ? null : ind);
              // if (ele.designerid !== "") {
              //   const value = ele.designerid;
              //   const el = element.get(value)
              //   modeling.setColor(el, {
              //     stroke: isSelected ? "black" : "red",
              //   });
              // }
              // const newErrorElement = errors
              //   .filter((list: any) => list.designerid !== ele.designerid)
              //   .map((error: any) => {
              //     if (error.designerid === "") return null;
              //     else if (
              //       !error.designerid.toLowerCase().includes("no") ||
              //       error.designerid.split(" ").length === 1
              //     ) {
              //       return element.get(error.designerid);
              //     } else return null;
              //   })
              //   .filter((list: any) => list);

              // modeling.setColor(newErrorElement, null);
            }}
          >
            <ListItemText primary={ele.messaging} />
          </ListItemButton>
        ))}
    </React.Fragment>
  );
};

export default ErrorList;
