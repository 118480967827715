import React, { useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  TextField,
} from '@mui/material';

import { getTranslation } from 'common';
import { useTranslations, useForm } from 'hooks';
import { Validation, isRequired } from 'hooks/useForm';

import DialogHeader from 'components/dialogs/DialogHeader';

interface NewFolderModelPopUpTypes {
  open: boolean;
  setClose: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (text: string) => void;
}

const NewFolderModelPopUp = ({
  open,
  setClose,
  handleSubmit,
}: NewFolderModelPopUpTypes) => {
  const validations: Validation[] = [
    ({ name }) =>
      isRequired(name) || {
        name: getTranslation(translations, 'pm.newfolder.required.name'),
      },
  ];
  const translations = useTranslations();

  const { reset, isValid, changeHandler, values, errors, touched } = useForm(
    {
      name: getTranslation(translations, 'pm.newfolder.placeholder.new'),
    },
    validations,
  );

  useEffect(() => {
    if (!open) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setClose(false);
      }}
      fullWidth={true}
      maxWidth="xs"
    >
      <DialogHeader
        onClose={() => {
          setClose(false);
        }}
      >
        {getTranslation(translations, 'pm.newfolder.title')}
      </DialogHeader>
      <DialogContent>
        <TextField
          id="folder-name"
          margin="dense"
          variant="outlined"
          fullWidth
          size='small'
          label={getTranslation(
            translations,
            'pm.newfolder.label.typefoldername',
          )}
          error={Boolean(touched.name && errors.name)}
          helperText={touched.name && errors.name}
          value={values?.name || ''}
          onChange={({ target }) => changeHandler('name', target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          id="save-folder-btn"
          disabled={!isValid}
          size="small"
          variant="contained"
          onClick={() => handleSubmit(values.name)}
        >
          {getTranslation(translations, 'pm.dialog.save.button.ok.label')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewFolderModelPopUp;
