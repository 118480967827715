import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getTranslation } from "common";

// import { MdOutlineCloudDone } from "react-icons/md";
import {
  CloudDone,
  CloudUpload,
  Delete,
  Edit,
  FileDownload,
  MoreHoriz,
  MoreVert,
  Queue,
  Restore,
  Visibility,
} from "@mui/icons-material";
import "camunda-bpmn-js/dist/assets/camunda-platform-modeler.css";

import "./ProcessModel.css";
import {
  RevisionModelType,
  SelectedProcessModelType,
} from "model/processModel";
import { deleteProcessModelById } from "Redux/processReducer";
import {
  useDragItem,
  useSelectedOrganization,
  useSnackbar,
  useTranslations,
} from "hooks";
import {
  IconButton,
  Button,
  Stack,
  ListItemButton,
  ListItemText,
  ListItemSecondaryAction,
  List,
  Menu,
  Tooltip,
  Fab,
  SpeedDialAction,
  SpeedDialIcon,
  Checkbox,
  ListItemIcon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { processmodelsApi } from "api";
import { formatDate } from "common/utilities";
import { StyledSpeedDial } from "components";
import UserAvatar from "components/UserAvatar";

const ProcessModel: React.FC<{
  model: SelectedProcessModelType;
  highlighted: number | null;
  index: number;
  selectedRevision: RevisionModelType | null;
  setSelectedRevision: React.Dispatch<
    React.SetStateAction<RevisionModelType | null>
  >;
  selectedProcessModel: SelectedProcessModelType | null;
  setSelectedProcessModel: React.Dispatch<
    React.SetStateAction<SelectedProcessModelType | null>
  >;
  onExportClick: () => void;
  // getProcessModels: () => void;
  returnmodel: (
    model: SelectedProcessModelType,
    errorsInModel: Boolean
  ) => void;
  parentId?: Number;
}> = ({
  model,
  highlighted,
  returnmodel,
  onExportClick,
  parentId,
  selectedProcessModel,
  setSelectedProcessModel,
  setSelectedRevision,
  selectedRevision,
}) => {
  const [selectedModelMenu, setSelectedModelMenu] = useState<number | null>(
    null
  );
  const { onDragStart, onDrag, onDragEnd, ref } = useDragItem({
    horizontal: false,
  });
  const navigate = useNavigate();
  const translations = useTranslations();
  const selectedOrganization = useSelectedOrganization();
  const isAdmin = selectedOrganization?.administrator;
  const [modelType, setModelType] = useState<number>(0);

  const dispatch = useDispatch();

  const { sendSnack } = useSnackbar();

  const [shouldShowRevisions, setShouldShowRevisions] =
    useState<Element | null>(null);
  const [revisionList, setRevisionList] = useState<Array<RevisionModelType>>(
    []
  );

  const [openFirstDialog, setOpenFirstDialog] = useState(false);
  const [openSecondDialog, setOpenSecondDialog] = useState(false);
  const [openFirstDeleteDialog, setOpenFirstDeleteDialog] = useState(false);
  const [openSecondDeleteDialog, setOpenSecondDeleteDialog] = useState(false);

  useEffect(() => {
    setModelType(model.modeltype);
  }, [model.modeltype]);

  const handleDeleteModel = async (id: number) => {
    setOpenFirstDeleteDialog(true);
  };
  
  const confirmFirstDeleteDialog = () => {
    setOpenFirstDeleteDialog(false);
    setOpenSecondDeleteDialog(true);
  };
  
  const confirmSecondDeleteDialog = async (id: number) => {
    setOpenSecondDeleteDialog(false);
    try {
      const { data } = await processmodelsApi.delete(`/${id}`); //così ci standardizziamo
      if (data.responsemessage) { //c'era un data di troppo prima
        sendSnack({
          message: data.responsemessage,
          type: "success",
        });
      }   
      dispatch(deleteProcessModelById({ id }));
    } catch (e: any) {
      sendSnack({
        message: e.response?.data?.message,
        type: "error",
      });
    }
  };
  
  const revertTheProcessModelHistory = async (revesion: RevisionModelType) => {
    try {
      const { data } = await processmodelsApi.put(
        `/${model.id}/histories/${revesion.id}/revert`,
        {}
      );
      sendSnack({ message: data.responsemessage, type: "success" });
      getModelRevisions();
      setModelType(revesion.modeltype);
      setSelectedRevision(revesion);
    } catch {
      // console.error(e);
    } finally {
    }
  };

  const getModelRevisions = async () => {
    try {
      const { data } = await processmodelsApi.get(`/${model.id}/histories`);
      const sortedRevisions = data.processmodelshistories.sort(
        (a: RevisionModelType, b: RevisionModelType) => {
          const date1: number = new Date(a.creation_time).getTime();
          const date2: number = new Date(b.creation_time).getTime();
          if (date1 < date2) {
            return 1;
          }
          if (date1 > date2) {
            return -1;
          }
          return 0;
        }
      );
      setRevisionList(sortedRevisions);
    } catch {
    } finally {
    }
  };

  const handleDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    itemId: number,
    itemName: string
  ) => {
    onDragStart(event, (e) => {
      const obj = {
        childId: itemId,
        childName: itemName,
        isProcessModel: true,
        parentId: parentId,
      };
      e.dataTransfer.setData("text/plain", JSON.stringify(obj));
    });
  };
  const handlePublish = async (e: any) => {
    e.stopPropagation();
    try {
      let { data: pmData } = await processmodelsApi.get(`/${model.id}`);
      await processmodelsApi.put(`/validate`, {
        ...pmData,
      });
      await processmodelsApi.post(`/${Number(model.id)}/publish`);
      returnmodel(model, false);
      setModelType(1);
    } catch (error: any) {
      returnmodel(model, true);
    }
  };
  const handleDepublish = async (e: any) => {
    e.stopPropagation();
    setOpenFirstDialog(true);
  };

  const confirmFirstDialog = async () => {
    setOpenFirstDialog(false);
    setOpenSecondDialog(true);
  };

  const confirmSecondDialog = async () => {
    setOpenSecondDialog(false);
    try {
      await processmodelsApi.delete(`${model.id}/depublish`);
      setModelType(0);
    } catch (error: any) {
      if (error.response.data.message) {
        sendSnack({
          message: error.response.data.message,
          type: "error",
        });
      }
      console.error(error);
    }
  };
  const handleEdit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/dashboard/process/edit/${model.id}/`);
  };
  const actions = [
    {
      icon: <Delete id={`delete-model-btn-${model.id}`} />,
      onClick: (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        handleDeleteModel(model.id);
      },
      name: getTranslation(translations, "generic.button.delete"),
    },
    {
      icon: <FileDownload id={`export-model-btn-${model.id}`} />,
      onClick: (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        onExportClick();
      },
      name: getTranslation(translations, "pm.button.export_model"),
    },
    {
      icon: <Edit id={`modify-model-btn-${model.id}`} />,
      onClick: handleEdit,
      name: getTranslation(translations, "pm.button.edit"),
    },
  ];
  return (
    <React.Fragment>
      <ListItemButton
        ref={ref as any}
        selected={selectedProcessModel?.id === model.id}
        sx={{
          p: 0,
          background: (_) =>
            highlighted === model.id ? _.palette.action.selected : "unset",
        }}
        id={`model-item-${model.id}`}
        onDoubleClick={handleEdit}
        onClick={() => {
          setSelectedProcessModel(model);
        }}
        draggable={true}
        key={model.id}
        onDragStart={(e) => handleDragStart(e, model.id, model.name)}
        onDrag={(e) => onDrag(e)}
        onDragEnd={(e) => onDragEnd(e)}
      >
        <ListItemIcon>
          <Tooltip
            title={getTranslation(
              translations,
              modelType
                ? "pm.buttons.action.depublish"
                : "pm.buttons.action.publish"
            )}
          >
            <Checkbox
              icon={<CloudUpload />}
              checkedIcon={<CloudDone />}
              onClick={
                isAdmin
                  ? !modelType
                    ? handlePublish
                    : handleDepublish
                  : () => {}
              }
              checked={Boolean(modelType)}
            />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary={model?.name}></ListItemText>
        <ListItemSecondaryAction>
          <Stack spacing={1} direction="row" alignItems="center">
            {isAdmin ? (
              <React.Fragment>
                <StyledSpeedDial
                  ariaLabel="process model menu"
                  FabProps={{ size: "small" }}
                  sx={{ position: "static" }}
                  onClose={() =>
                    setTimeout(() => setSelectedModelMenu(null), 500)
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setSelectedModelMenu(model.id);
                  }}
                  icon={
                    <SpeedDialIcon
                      id={`edit-model-btn-${model.id}`}
                      icon={<MoreHoriz />}
                      openIcon={<MoreVert />}
                    />
                  }
                  direction="left"
                  open={selectedModelMenu === model.id}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      FabProps={{ size: "small" }}
                      tooltipTitle={action.name}
                      onClick={action.onClick}
                    />
                  ))}
                </StyledSpeedDial>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Tooltip
                  title={getTranslation(
                    translations,
                    "generic.button.visualize"
                  )}
                >
                  <Fab color="primary" size="small">
                    <Visibility />
                  </Fab>
                </Tooltip>
              </React.Fragment>
            )}
            {isAdmin && (
              <React.Fragment>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ display: { xs: "none", lg: "flex" } }}
                  endIcon={<Queue />}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShouldShowRevisions((_) => {
                      if (!_) {
                        getModelRevisions();
                        // setShouldShowExpandViewer(model.id);
                      }
                      return _ ? null : (e.target as Element);
                    });
                  }}
                >
                  {getTranslation(translations, "pm.button.revisions")}
                </Button>
              </React.Fragment>
            )}
          </Stack>
        </ListItemSecondaryAction>
      </ListItemButton>
      <Menu
        disableScrollLock
        anchorEl={shouldShowRevisions}
        onClose={() => setShouldShowRevisions(null)}
        open={Boolean(shouldShowRevisions)}
      >
        <List sx={{ minWidth: 250 }}>
          {revisionList?.map((revision) => (
            <ListItemButton
              sx={{ m: 1 }}
              selected={revision.id === selectedRevision?.id}
              onClick={() => setSelectedRevision(revision)}
              key={`process-model-${revision.idprocessmodel}-revision-${revision.id}`}
            >
              <ListItemText
                primaryTypographyProps={{
                  sx: {
                    fontWeight: revision.is_working_copy ? "bold" : "unset",
                  },
                }}
                primary={formatDate(revision.creation_time)}
                secondary={
                  <UserAvatar
                    disableHugePicture
                    user={{ ...revision, email: "" }}
                    showUsername
                  />
                }
              />
              {revision.id === selectedRevision?.id && (
                <ListItemSecondaryAction>
                  <Tooltip
                    title={
                      Boolean(modelType)
                        ? getTranslation(
                            translations,
                            "generic.model_published_cannot_restore.caption"
                          )
                        : getTranslation(
                            translations,
                            "generic.restore.caption"
                          )
                    }
                  >
                    <span>
                      <IconButton
                        disabled={Boolean(modelType)}
                        onClick={(e) => {
                          e.stopPropagation();
                          revertTheProcessModelHistory(revision);
                        }}
                      >
                        <Restore />
                      </IconButton>
                    </span>
                  </Tooltip>
                </ListItemSecondaryAction>
              )}
            </ListItemButton>
          ))}
        </List>
      </Menu>

      <Dialog
        open={openFirstDialog}
        onClose={() => setOpenFirstDialog(false)}
      >
        <DialogTitle>{getTranslation(translations, "generic.warning.caption")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          {getTranslation(translations, "pm.depublish.first.warning")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenFirstDialog(false)} color="primary">
          {getTranslation(translations, "generic.no.caption")}
          </Button>
          <Button onClick={confirmFirstDialog} color="primary">
          {getTranslation(translations, "generic.yes.caption")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openSecondDialog}
        onClose={() => setOpenSecondDialog(false)}
      >
        <DialogTitle>{getTranslation(translations, "generic.warning.caption")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          {getTranslation(translations, "pm.depublish.second.warning")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenSecondDialog(false)} color="primary">
          {getTranslation(translations, "generic.no.caption")}
          </Button>
          <Button onClick={confirmSecondDialog} color="primary">
          {getTranslation(translations, "generic.yes.caption")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
  open={openFirstDeleteDialog}
  onClose={() => setOpenFirstDeleteDialog(false)}
>
  <DialogTitle>{getTranslation(translations, "generic.warning.caption")}</DialogTitle>
  <DialogContent>
    <DialogContentText>
      {getTranslation(translations, "pm.delete.first.warning")}
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setOpenFirstDeleteDialog(false)} color="primary">
      {getTranslation(translations, "generic.no.caption")}
    </Button>
    <Button id="confirm-delete-model-btn" onClick={confirmFirstDeleteDialog} color="primary">
      {getTranslation(translations, "generic.yes.caption")}
    </Button>
  </DialogActions>
</Dialog>

<Dialog
  open={openSecondDeleteDialog}
  onClose={() => setOpenSecondDeleteDialog(false)}
>
  <DialogTitle>{getTranslation(translations, "generic.warning.caption")}</DialogTitle>
  <DialogContent>
    <DialogContentText>
      {getTranslation(translations, "pm.depublish.second.warning")}
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setOpenSecondDeleteDialog(false)} color="primary">
      {getTranslation(translations, "generic.no.caption")}
    </Button>
    <Button
    id="death-model-btn"
      onClick={() => confirmSecondDeleteDialog(model.id)}
      color="primary"
    >
      {getTranslation(translations, "generic.yes.caption")}
    </Button>
  </DialogActions>
</Dialog>
    </React.Fragment>
  );
};

export default ProcessModel;
