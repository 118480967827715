import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { Translations } from '../model/messageModel';

const useTranslations = () => {
    const translations: Translations = useSelector(
        (state: RootState) => state.message
    );
    return translations;
}

export default useTranslations