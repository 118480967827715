import { useCallback, useEffect, useState } from "react";

export type Validation = (form: any) => boolean | { [key: string]: string };

export const isJson = (value: string) => {
  if (value) {
    try {
      JSON.parse(value);
      return true;
    } catch {
      return false;
    }
  }
  return true;
};
export const validate = (validations: Validation[], values: any) => {
  const errors = validations
    .map((validation) => validation(values || {}))
    .filter((validation) => typeof validation === "object");
  return {
    isValid: errors.length === 0,
    errors: errors.reduce(
      (errs: Object, error: Object) => ({ ...errs, ...error }),
      {}
    ),
  };
};

export const isRequired = (value: any) =>
  value !== null && value !== undefined && String(value).trim().length > 0;

export const isSame = (value1: any, value2: any) => value1 === value2;

export const isEmail = (value: any) => {
  if (value) {
    // eslint-disable-next-line no-useless-escape
    return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    );
  }
  return true;
};
const useForm = (
  initialState: any,
  validations: Validation[] = [],
  onSubmit = (values: any) => {}
) => {
  const { isValid: initialIsValid, errors: initialErrors } = validate(
    validations,
    initialState
  );
  const [values, setValues] = useState<typeof initialState>(initialState);
  const [errors, setErrors] = useState<any>(initialErrors);
  const [isValid, setValid] = useState(initialIsValid);
  const [touched, setTouched] = useState<any>({});

  const changeHandler = (name: string, value: any) => {
    // const newValues = { ...values, [name]: value };
    setValues((currentValues: any)=> {
      const newValues = {...currentValues, [name]: value}
      const { isValid, errors } = validate(validations, newValues);
      setValid(isValid);
      setErrors(errors);
      setTouched({ ...touched, [name]: true });
      return newValues;
    });
  };

  useEffect(() => {
    const { isValid, errors } = validate(validations, values);
    setValid(isValid);
    setErrors(errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitHandler = (event: any) => {
    event.preventDefault();
    onSubmit(values);
  };

  const reset = useCallback((payload: typeof initialState = undefined) => {
    const { isValid: initialIsValid, errors: initialErrors } = validate(
      validations,
      initialState
    );

    setTouched({});
    setValues(payload ? payload : initialState);
    setErrors(initialErrors);
    setValid(initialIsValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const reset = useCallback(() => {
  //   const { isValid: initialIsValid, errors: initialErrors } = validate(
  //     validations,
  //     initialState
  //   );
  //   setTouched({});
  //   setValues(initialState);
  //   setErrors(initialErrors);
  //   setValid(initialIsValid);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return {
    values,
    changeHandler,
    isValid,
    errors,
    touched,
    submitHandler,
    setValues,
    reset,
  };
};

export default useForm;
