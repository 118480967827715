// import { useSelector } from 'react-redux';
// import { RootState } from 'store';
// import UserModel from "model/userModel";
import { useContext } from 'react';
import { AuthContext } from 'contexts';

const useUser = () => {
  // const user: UserModel = useSelector((state: RootState) => state.user);
  const { state: { user } } = useContext(AuthContext);
  return user;
}

export default useUser