import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useTreeItem, TreeItemContentProps } from "@mui/x-tree-view/TreeItem";
import { styled, alpha } from "@mui/material/styles";
import { ClickAwayListener, IconButton, Stack, TextField } from "@mui/material";
import clsx from "clsx";
import Typography from "@mui/material/Typography";
import { Delete, Edit } from "@mui/icons-material";
import { useSelectedOrganization } from "hooks";

const CustomContentRoot = styled("div")(({ theme }) => ({
  WebkitTapHighlightColor: "transparent",

  "&.Mui-selected.Mui-focused .MuiTreeItem-contentBar": {
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity + theme.palette.action.focusOpacity
    ),
  },

  ".MuiTextField-root": {
    background: theme.palette.primary.contrastText,
  },
}));

const CustomTreeItem = React.forwardRef(function CustomContent(
  props: TreeItemContentProps & {
    handleUpdateFolderName?: (val: string) => void;
    handleOnClickDeleteFolderIcon?: (val: Number) => void;
    isEditable?: boolean;
    setIsEditable?: React.Dispatch<React.SetStateAction<boolean>>;
  },
  ref
) {
  const {
    className,
    classes,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
    isEditable,
    setIsEditable,
    handleUpdateFolderName,
    handleOnClickDeleteFolderIcon,
  } = props;

  const [title, setTitle] = useState<string>("");
  const inputRef = useRef();
  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);

  useEffect(() => {
    if (label) {
      setTitle(label.toString());
    }
  }, [label]);

  const icon = iconProp || expansionIcon || displayIcon;

  const selectedOrganization = useSelectedOrganization();
  const isAdmin = selectedOrganization?.administrator;

  const handleMouseDown = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    preventSelection(event);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    handleExpansion(event);
    handleSelection(event);
  };

  const handleBlur = () => {
    setIsEditable && setIsEditable(false);
    if (
      handleUpdateFolderName &&
      title &&
      title.length > 0 &&
      title !== label
    ) {
      handleUpdateFolderName(title.toString());
    } else if (title.length === 0 && label) {
      setTitle(label.toString());
    }
  };

  const handleOnChangeTextField = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (e.key === "Enter") {
      handleBlur();
    }
  };

  const onClickDeleteIcon = () => {
    if (handleOnClickDeleteFolderIcon) {
      handleOnClickDeleteFolderIcon(parseFloat(nodeId));
    }
  };
  useLayoutEffect(() => {
    if (isEditable && inputRef.current) {
      (inputRef.current as any).focus();
    }
  }, [isEditable]);

  return (
    <CustomContentRoot
      className={clsx(className, classes.root, {
        "Mui-expanded": expanded,
        "Mui-selected": selected,
        "Mui-focused": focused,
        "Mui-disabled": disabled,
      })}
      onClick={(e) => !isEditable && handleClick(e)}
      onMouseDown={handleMouseDown}
      ref={ref as React.Ref<HTMLDivElement>}
    >
      <IconButton
        className={classes.iconContainer}
        onClick={() => setIsEditable && setIsEditable(false)}
        disableRipple
      >
        {icon}
      </IconButton>
      <Stack
        flex={1}
        direction="row"
        alignItems="center"
        spacing={2}
        justifyContent="space-between"
      >
        {isEditable ? (
          <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={handleBlur}
          >
            <TextField
              sx={{ minWidth: 250 }}
              color="primary"
              size="small"
              inputRef={inputRef as any}
              onKeyDown={handleOnKeyDown}
              value={title}
              onChange={handleOnChangeTextField}
            />
          </ClickAwayListener>
        ) : (
          <Typography className={classes.label}>{title}</Typography>
        )}
        {isAdmin && (

          <Stack direction="row" spacing={1} alignItems="center">
            <IconButton
              color="primary"
              size="small"
              id="edit-folder-btn"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsEditable && setIsEditable(true);
              }}
            >
              <Edit />
            </IconButton>
            <IconButton
              // color="error"
              size="small"
              id="delete-folder-btn"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onClickDeleteIcon();
              }}
            >
              <Delete />
            </IconButton>
          </Stack>
        )}
      </Stack>
    </CustomContentRoot>
  );
});

export default CustomTreeItem;
