import { organizationsApi } from "api";
import { useCallback, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addOrganizations,
  addSelectedOrganization,
} from "Redux/organizationReducer";
import { SelectedOrganizationType } from "model/organizationModel";
import { RootState } from "store";
import { UtilityContext } from "contexts";
import useUser from "./useUser";
import { CONSTANTS } from "common";

const useOrganizations = (
  sleep?: number
): [
    SelectedOrganizationType[],
    () => Promise<void>
  ] => {
  const {
    state: { organizationsFirstSearch },
    actions: { addOrganizationsFirstSearch },
  } = useContext(UtilityContext);
  const user = useUser();
  const dispatch = useDispatch();
  const organizations: SelectedOrganizationType[] = useSelector(
    (state: RootState) => state.organization.organizations
  );

  const getOrganizations = useCallback(async () => {
    try {
      if (user) {
        const { data } = await organizationsApi.get(`user`);
        dispatch(addOrganizations({ list: data.organizations }));
        const alreadySelectedOrganizationId = localStorage.getItem(CONSTANTS.STORE_SELECTED_ORGANIZATION_ID);
        if (alreadySelectedOrganizationId && (
          data.organizations.findIndex((_: any) => String(_.id) === String(alreadySelectedOrganizationId)) >= 0
        )) {
          dispatch(addSelectedOrganization({ id: Number(alreadySelectedOrganizationId) }));
        } else {
          dispatch(addSelectedOrganization({ id: data.organizations[0]?.id }))
        }
      } else {
        dispatch(addOrganizations({ list: [] }));
      }
    } catch { } finally { }
    // dispatch(addSelectedOrganization({ id: data.owning[0]?.id || data.participating[0]?.id }));
  }, [dispatch, user]);

  useEffect(() => {
    const start = () => {
      if (!organizationsFirstSearch) {
        addOrganizationsFirstSearch({});
        getOrganizations();
      }
    };
    if (sleep) {
      setTimeout(start, sleep);
    } else {
      start();
    }
  }, [
    sleep,
    getOrganizations,
    organizationsFirstSearch,
    addOrganizationsFirstSearch,
  ]);

  return [organizations, getOrganizations];
};

export default useOrganizations;
