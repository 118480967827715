import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from "@mui/x-tree-view/TreeView";

import {
  ProcessModelFolderType,
  RevisionModelType,
  SelectedProcessModelType,
} from "model/processModel";

import ProcessModel from "../ProcessModel";

import TreeItem from "./TreeItem";
import { Box } from "@mui/system";
import InputPopUp from "components/dialogs/InputPopUp";
import { processmodelsApi } from "api";
import { useSnackbar, useTranslations } from "hooks";
import { clearLanes, getTranslation } from "common/utilities";

interface handleUpdateTreeFunctionPropsType {
  parentId: Number | null;
  childId: string;
  childName?: string;
  isProcessModel?: Boolean;
  deleteProcessFromFolder?: Boolean;
}

interface TreeViewPropsType {
  highlighted: number | null;
  processModelFolders: Array<ProcessModelFolderType>;
  processModelList?: SelectedProcessModelType[];
  expandedNodes: string[];
  selectedRevision: RevisionModelType | null;
  setSelectedRevision: React.Dispatch<
    React.SetStateAction<RevisionModelType | null>
  >;
  selectedProcessModel: SelectedProcessModelType | null;
  setSelectedProcessModel: React.Dispatch<
    React.SetStateAction<SelectedProcessModelType | null>
  >;
  returnmodel: (
    model: SelectedProcessModelType,
    errorsInModel: Boolean
  ) => void;
  handleUpdateTree: ({
    parentId,
    childId,
    childName,
    isProcessModel,
    deleteProcessFromFolder,
  }: handleUpdateTreeFunctionPropsType) => void;
  setSelectedNode: (val: Number) => void;
  handleUpdateFolderName?: (val: string, parentId?: Number) => void;
  handleOnClickDeleteFolderIcon?: (val: Number) => void;
  setExpandedNode: (val: Number) => void;
}

const TreeViewComponent: React.FC<TreeViewPropsType> = ({
  selectedProcessModel,
  setSelectedProcessModel,
  selectedRevision,
  setSelectedRevision,
  processModelFolders,
  highlighted,
  processModelList,
  returnmodel,
  handleUpdateTree,
  setSelectedNode,
  handleUpdateFolderName,
  handleOnClickDeleteFolderIcon,
  setExpandedNode,
  expandedNodes,
}) => {
  const handleDrop = (
    event: React.DragEvent<HTMLDivElement>,
    isProcessModel = false
  ) => {
    event.preventDefault();
    event.stopPropagation();
    const obj = JSON.parse(event.dataTransfer.getData("text/plain"));
    handleUpdateTree({
      parentId: obj.isProcessModel ? obj.parentId : null,
      childId: obj.childId,
      childName: obj.childName,
      isProcessModel: obj.isProcessModel || false,
      deleteProcessFromFolder: obj.isProcessModel ? true : false,
    });
  };
  // const eventTarget = useRef<EventTarget | null>(null);
  const handleDragOver = (
    event: React.DragEvent<HTMLDivElement>,
    isProcessModel = false
  ) => {
    event.preventDefault();
  };

  const [exportId, setExportId] = useState<number | null>(null);
  const { sendSnack } = useSnackbar();
  const translations = useTranslations();


  const handleOnNodeSelect = (
    event: React.SyntheticEvent<Element, Event>,
    nodeIds: string[]
  ) => {
    if (nodeIds && nodeIds[0]) {
      setSelectedNode(parseFloat(nodeIds[0]));
      setExpandedNode(parseFloat(nodeIds[0]));
    }
  };

  const exportModel = async (nameModel: string) => {
    try {
      const { data } = await processmodelsApi.get(`${exportId}`);
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," +
        encodeURIComponent(clearLanes(data.model))
      );
      element.setAttribute("download", `${nameModel}.flw`);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);

      sendSnack({
        message: data.responsemessage,
        type: "success",
      });
    } catch (e) {
      console.error(e);
      sendSnack({ message: getTranslation(translations, "generic.something_went_wrong.caption"), type: "error" });
    }
  };
  return (
    <React.Fragment>
      <InputPopUp
        labels={{
          title: getTranslation(translations, "generic.model_name.caption"),
          inputLabel: getTranslation(translations, "generic.insert_model_name.caption"),
        }}
        callback={exportModel}
        open={Boolean(exportId)}
        onClose={() => setExportId(null)}
      />
      <TreeView
        sx={{ height: "100%" }}
        aria-label="process models folder navigator"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        onNodeSelect={handleOnNodeSelect}
        expanded={
          expandedNodes && expandedNodes.length > 0 ? expandedNodes : []
        }
        defaultExpanded={
          expandedNodes && expandedNodes.length > 0 ? expandedNodes : []
        }
        multiSelect
      >
        <Box
          sx={{ height: "100%", display:"flex", flexDirection:"column", pb: 10 }}
          onDrop={handleDrop}
          onFocusCapture={(e) => e.stopPropagation()}
          onDragOver={handleDragOver}
        >
          {processModelFolders &&
            processModelFolders.map(
              (item) =>
                Boolean(item && item.id && item.name) && (
                  //  <React.Fragment key={item.id}>
                  <TreeItem
                    selectedProcessModel={selectedProcessModel}
                    setSelectedProcessModel={setSelectedProcessModel}
                    selectedRevision={selectedRevision}
                    setSelectedRevision={setSelectedRevision}
                    onExportClick={() => setExportId(item.id)}
                    highlighted={highlighted}
                    handleUpdateTree={handleUpdateTree}
                    key={item.id}
                    nodes={item}
                    processModelList={processModelList}
                    returnmodel={returnmodel}
                    handleUpdateFolderName={handleUpdateFolderName}
                    handleOnClickDeleteFolderIcon={
                      handleOnClickDeleteFolderIcon
                    }
                  />
                  // </React.Fragment>
                )
            )}
          <Box
            sx={{ height: "100%" }}
            onFocusCapture={(e) => e.stopPropagation()}
            onDragOver={(e) => handleDragOver(e, true)}
            onDrop={(e) => handleDrop(e, true)}
          >
            {processModelList?.map(
              (model: SelectedProcessModelType, index: number) =>
                Boolean(model.id && !model.idfolder) && (
                  <ProcessModel
                    selectedProcessModel={selectedProcessModel}
                    setSelectedProcessModel={setSelectedProcessModel}
                    selectedRevision={selectedRevision}
                    setSelectedRevision={setSelectedRevision}
                    onExportClick={() => setExportId(model.id)}
                    highlighted={highlighted}
                    key={model.id}
                    model={model}
                    index={index}
                    returnmodel={returnmodel}
                  />
                )
            )}
          </Box>
        </Box>
      </TreeView>
    </React.Fragment>
  );
};

export default TreeViewComponent;
