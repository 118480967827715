import { AddCircleRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  List,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import DialogHeader from "./dialogs/DialogHeader";
import { getTranslation } from "common";
import { useForm, useTranslations } from "hooks";
import { Validation, isRequired } from "hooks/useForm";
import FieldsListItem from "./FieldsListItem";
import { SupportedUserDataTypes, UserData } from "hooks/useUserDataKeys";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

interface FieldsListProps {
  canManage?: boolean;
  onChange: (newValues: { [key: string]: UserData }) => void;
  value: { [key: string]: UserData };
  selectedProcess: number | null;
  handleSaveUserdata?: (newUserdata: { [key: string]: UserData }) => void;
}

const FieldsList: React.FC<FieldsListProps> = ({
  canManage = true,
  selectedProcess,
  onChange,
  value,
  handleSaveUserdata,
}) => {
  const [check, setCheck] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState<string | null>(null);
  const translations = useTranslations();
  const validations: Validation[] = [
    ({ key }) =>
      isRequired(key) || {
        key: getTranslation(translations, "register.fieldrequired"),
      },
    ({ value }) =>
      isRequired(value) || {
        value: getTranslation(translations, "register.fieldrequired"),
      },
    ({ key }) =>
      Boolean(edit) ||
      !(key in value) || { key: getTranslation(translations, "generic.keypresent.metadata.caption") },
  ];
  const { values, changeHandler, errors, touched, isValid, reset, setValues } =
    useForm(
      {
        key: "",
        value: "",
        type: SupportedUserDataTypes.string,
      },
      validations
    );
  const onChangeInCard = (_: ChangeEvent, newCheck: boolean, key: string) => {
    const newValue = newCheck ? key : null
    localStorage.setItem(`userdata-per-${selectedProcess}`, newValue || "");
    setCheck(newValue);
    Object.keys(value).forEach(k => {
      value[k].inCard = false
    })
    handleSaveUserdata && handleSaveUserdata({ ...value, [key]: { ...value[key], inCard: newCheck } });
  };
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    let newUserdata;
    const { key, ..._values } = values
    if (edit) {
      const { [edit]: _, ...newValue } = { ...value };
      newUserdata = { ...newValue, [key]: _values };
    } else {
      newUserdata = { ...value, [key]: _values };
    }
    onChange(newUserdata);
    setOpen(false);
    handleSaveUserdata && handleSaveUserdata(newUserdata);
  };

  const onDelete = (key: string) => {
    const { [key]: _, ...newValue } = { ...value };
    onChange(newValue);
    handleSaveUserdata && handleSaveUserdata(newValue);
  };
  const onEdit = (key: string, value: UserData) => {
    if (!value.type && typeof (value) === "object") {
      value.type = SupportedUserDataTypes.string
    }
    setOpen(true);
    setValues({ key, ...value });
    setEdit(key);
  };

  useEffect(() => {
    setCheck(localStorage.getItem(`userdata-per-${selectedProcess}`))
    if (!open) {
      reset();
      setEdit(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  return (
    <Box>
      {canManage && (
        // <span
        //   style={{
        //     cursor: "not-allowed",
        //     textAlign: "left",
        //   }}
        // >
          <Button
            id="addfield-button"
            endIcon={<AddCircleRounded />}
            variant="text"
            size="small"
            onClick={() => setOpen(true)}
          >
            {getTranslation(translations, "pm.mayactivities.addfield.label")}
          </Button>
        // </span>
      )}
      <List
        sx={{
          //  maxHeight: "100px",
          maxWidth: "450px",
          //   overflowY: "hidden",
          marginTop: "10px",
        }}
      >
        {Object.entries(value).map(([key, _value]) => (
          <FieldsListItem
            canManage={canManage}
            onChangeInCard={onChangeInCard}
            check={check}
            selectedProcess={selectedProcess}
            key={`fields-list-item-${key}`}
            item={{ key, value: _value }}
            onDelete={() => onDelete(key)}
            onEdit={() => onEdit(key, _value)}
          />
        ))}
      </List>
      {/* <Button id="save-userdata-btn" size="small" variant="contained" color="primary" onClick={handleSaveUserdata}>
        Salva Userdata (n.t)
      </Button> */}

      <Dialog open={open} onClose={() => setOpen(false)}>
        <form>
          <DialogHeader onClose={() => setOpen(false)}>
            {getTranslation(translations, "pm.mayactivities.addfield.label")}
          </DialogHeader>
          <DialogContent>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="key-field"
                  size="small"
                  label={getTranslation(
                    translations,
                    "pm.mayactivities.key.label"
                  )}
                  fullWidth
                  error={Boolean(touched.key && errors.key)}
                  helperText={touched.key && errors.key}
                  value={values.key}
                  onChange={({ target }) => changeHandler("key", target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                {values.type === SupportedUserDataTypes.string && (
                  <TextField
                    id="value-field"
                    size="small"
                    label={getTranslation(
                      translations,
                      "placeholder.value.label"
                    )}
                    fullWidth
                    error={Boolean(touched.value && errors.value)}
                    helperText={touched.value && errors.value}
                    value={values.value}
                    onChange={({ target }) =>
                      changeHandler("value", target.value)
                    }
                  />
                )}
                {values.type === SupportedUserDataTypes.number && (
                  <TextField
                    id="value-field"
                    size="small"
                    type="number"
                    label={getTranslation(
                      translations,
                      "placeholder.value.label"
                    )}
                    fullWidth
                    error={Boolean(touched.value && errors.value)}
                    helperText={touched.value && errors.value}
                    value={values.value}
                    onChange={({ target }) =>
                      changeHandler("value", target.value)
                    }
                  />
                )}
                {values.type === SupportedUserDataTypes.date && (
                  <DatePicker
                    slotProps={{
                      textField: { size: "small" }
                    }}
                    label="Controlled picker"
                    value={dayjs(values.value)}
                    onChange={(newValue) => changeHandler("value", newValue)}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  select
                  id="type-field"
                  size="small"
                  label={getTranslation(
                    translations,
                    "placeholder.type.label"
                  )}
                  fullWidth
                  error={Boolean(touched.type && errors.type)}
                  helperText={touched.type && errors.type}
                  value={values.type}
                  onChange={({ target }) =>
                    changeHandler("type", target.value)
                  }
                >
                  {Object
                    .entries(SupportedUserDataTypes)
                    .filter(([v]) => isNaN(Number(v)))
                    .map(([key, value]) => (
                      <MenuItem
                        key={key}
                        value={value}
                      >
                        {getTranslation(translations, `supported.userdata.type.${key}`)}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button size="small" onClick={() => setOpen(false)}>
              {getTranslation(
                translations,
                "pm.startprocess.button.cancel.caption"
              )}
            </Button>
            <Button
              id="confirm-field-button"
              variant="contained"
              size="small"
              type="submit"
              disabled={!isValid}
              onClick={handleSubmit}
            >
              {getTranslation(
                translations,
                "pm.mayactivities.confirmation.label"
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};

export default FieldsList;
