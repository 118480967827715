import React from 'react';
import { Dialog, DialogContent, Button, DialogActions } from '@mui/material';

import DialogHeader from 'components/dialogs/DialogHeader';
import { getTranslation } from 'common';
import { useTranslations } from 'hooks';

interface DeleteFolderPopUpTypes {
  open: boolean;
  setClose: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: () => void;
}

const DeleteFolderPopUp = ({
  open,
  setClose,
  handleSubmit,
}: DeleteFolderPopUpTypes) => {
  const translations = useTranslations();
  return (
    <Dialog
      open={open}
      onClose={() => {
        setClose(false);
      }}
      fullWidth={true}
      maxWidth="xs"
    >
      <DialogHeader
        onClose={() => {
          setClose(false);
        }}
      >
        {getTranslation(translations, "generic.pleaseconfirm")}
      </DialogHeader>
      <DialogContent>{getTranslation(translations, "pm.confirm.delete_folder")}</DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="outlined"
          onClick={() => setClose(false)}
        >
          {getTranslation(
            translations,
            "pm.startprocess.button.cancel.caption"
          )}
        </Button>
        <Button
          id="delete-folder-confirm-btn"
          size="small"
          variant="contained"
          onClick={() =>
            handleSubmit()
          }
        >
          {getTranslation(
            translations,
            "pm.mayactivities.confirmation.label"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteFolderPopUp;
