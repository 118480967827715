import { Checkbox, Stack } from "@mui/material";
import { getTranslation } from "common";
import { useTranslations } from "hooks";
import React, { useState } from "react";

interface HODCheckboxProps {
  initialChecked: boolean;
  onChange: (checked: boolean) => Promise<void>;
  disabled?: boolean;
}

const HODCheckbox: React.FC<HODCheckboxProps> = ({
  onChange,
  initialChecked,
  disabled: disableFromParent
}) => {
  const translations = useTranslations();
  const [disabled, setDisabled] = useState<boolean>(false);

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Checkbox
        checked={initialChecked}
        disabled={disabled || disableFromParent}
        onChange={async (e, _checked) => {
          try {
            setDisabled(true);
            await onChange(_checked);
            // setChecked(_checked);
          } catch {
            // setChecked(!_checked);
          }
          setDisabled(false);
        }}
      />
      {getTranslation(translations, "groups.check.hod")}
    </Stack>
  );
};

export default HODCheckbox;
