import { Alert, Slide } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { deleteSnackbarById } from "Redux/snackbarReducer";
import SnackbarModel from "model/snackbarModel";
import { useDispatch } from "react-redux";

const Message: React.FC<SnackbarModel> = ({
  id,
  message,
  onClose,
  autoClose,
  type,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const handleClose = useCallback(() => {
    setOpen(false);
    setTimeout(() => {
      dispatch(deleteSnackbarById(id || ""));
      onClose && onClose();
    }, 100);
  }, [dispatch, onClose, id]);

  useEffect(() => {
    if (autoClose) {
      setTimeout(handleClose, autoClose);
    }
  }, [handleClose, autoClose]);

  if (!id) return null;
  if (autoClose) {
    return (
      <Slide direction="left" in={open} mountOnEnter unmountOnExit>
        <Alert variant="filled" severity={type}>
          {message}
        </Alert>
      </Slide>
    );
  } else {
    return (
      <Slide direction="left" in={open} mountOnEnter unmountOnExit>
        <Alert
          id={id}
          variant="filled"
          severity={type}
          onClose={(e) => handleClose()}
        >
          {message}
        </Alert>
      </Slide>
    );
  }
};

export default Message;
