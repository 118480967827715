import {
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
} from "@mui/material";
import React from "react";
import { RolesModel } from "../NewProcessModule";
import { ManageAccounts } from "@mui/icons-material";
import { useDragItem } from "hooks";
import AvatarRem from "components/AvatarRem";

interface RoleAssociationItemProps {
  selected: boolean;
  ele: RolesModel;
  ind: any;
  onLaneMouseDown: any;
  selectedLane: string;
}
const RoleAssociationItem: React.FC<RoleAssociationItemProps> = ({
  ele,
  ind,
  selected,
  onLaneMouseDown,
  selectedLane,
}) => {
  const { dragging, onDrag, onDragEnd, onDragStart, ref } = useDragItem();
  return (
    <Paper
      ref={ref as any}
      draggable={true}
      onDrag={onDrag}
      onDragEnd={onDragEnd}
      onDragStart={(e) =>
        onDragStart(e, (event) => {
          const dragData = {
            name: ele?.name,
            idparticipant: ele.id,
            idparticipanttype: 2,
            assignmentmode: 0,
          };

          event.dataTransfer.setData("application/json", JSON.stringify(dragData));
        })
      }
      sx={{
        my: 1,
        backgroundColor: dragging
          ? (_) => _.palette.background.paper
          : "transparent",
      }}
      elevation={dragging ? 5 : 0}
    >
      <ListItemButton
        disableGutters
        selected={selected}
        id={`roles${ele.id}`}
        onDoubleClick={(e: any) => {
          if (Boolean(selectedLane)) {
            onLaneMouseDown(selectedLane, {
              name: ele?.name,
              idparticipant: ele.id,
              idparticipanttype: 2,
              assignmentmode: 0,
            });
          }
        }}
      >
        <ListItemAvatar>
          <AvatarRem icon={ele.icon || ""}>
            <ManageAccounts />
          </AvatarRem>
        </ListItemAvatar>
        <ListItemText key={`roles${ind}`}>{ele?.name}</ListItemText>
      </ListItemButton>
    </Paper>
  );
};

export default RoleAssociationItem;
