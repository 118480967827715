import { Avatar, AvatarProps } from '@mui/material';
import { baseApi } from 'api';
import React, { useCallback, useEffect, useState } from 'react';

interface AvatarRemProps extends AvatarProps {
  icon?: string;
}

const AvatarRem: React.FC<AvatarRemProps> = ({ icon, ...rest }) => {
  const [image, setImage] = useState<string>('');

  const fetchData = useCallback(async () => {
    if (icon) {
      try {
        const res = await baseApi.get(icon);
        setImage(res.data);
      } catch {
      }
    } else {
      setImage("");
    }
  }, [icon]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return <Avatar {...rest} src={image}/>;
};

export default AvatarRem;
