import dayjs from "dayjs";
import { Translations } from "../model/messageModel";
import {
  ProcessModelFolderType,
  SelectedProcessModelType,
} from "model/processModel";
import { Priority } from "hooks/usePriorities";
import { organizationsApi } from "api";
import {
  DraggableStateSnapshot,
  DraggingStyle,
  NotDraggingStyle,
} from "react-beautiful-dnd";
import { ServerTag, Task } from "model/activitiesModel";
import { LabelData } from "components/labels/label_Interface";
import { SortState } from "hooks/useSortableList";
import { SupportedUserDataTypes, UserData } from "hooks/useUserDataKeys";

export const TASK_CARD_HEIGHT = 190;
export const LABEL_OFFSET = 20;

export const getUserData = (
  userdata: string | null | undefined
): { [key: string]: UserData } => {
  try {
    return JSON.parse(userdata || "{}");
  } catch {
    return {};
  }
};

export const getUserDataValue = (userdata: UserData) => {
  let value = userdata.value;
  if (userdata.type === SupportedUserDataTypes.date) {
    value = dayjs(value).format("DD/MM/YYYY");
  }
  return value;
};
export const range = (n: number) => {
  const res = [];
  for (let i = 1; i <= n; i++) {
    res.push(i);
  }
  return res;
};
export const getLabelDataFromJsonTags = (
  jsonTags: string | null | undefined
): LabelData[] => {
  return (JSON.parse(jsonTags || "null") || []).map(
    (tag: { idtag: number; tag: string; color: string }) => ({
      id: tag.idtag,
      name: tag.tag,
      css: tag.color,
    })
  );
};

export const trackPageView = (url: string) => {
  (window as any).gtag("config", process.env.REACT_APP_ANALYTICS_KEY, {
    page_path: url,
  });
};

export interface SortCriterion {
  [field: string]: SortState;
}

export const filterTasks =
  (
    filter: string,
    idtags: number[], //array degli ID tag per filtrare i task
    selectedPriorities: string[], //array delle priorities
    selectedActivities:string[],
    userDataKey: string | null,
    processModelsId: number[],
    isInAnd: boolean = true
  ) =>
  (task: Task) => {
    //
    const matchesSearchFilter =
      task.processmodelname.toLowerCase().includes(filter.toLowerCase()) ||
      task.flownodename?.toLowerCase().includes(filter.toLowerCase()) ||
      task.description.toLowerCase().includes(filter.toLowerCase());
    //
    const tags = JSON.parse(task.jsontag || "[]") as ServerTag[];
    const matchesTags =
      idtags.length === 0 || tags.some((tag) => idtags.includes(tag.idtag));
    //
    const matchesPriority =
      selectedPriorities.length === 0 ||
      selectedPriorities.includes(task.priority_name);
    //
    const userData = getUserData(task.userdata);
    const matchesUserData =
      !userDataKey || (userDataKey in userData);
    //
    const matchesProcessModelName =
      processModelsId.length === 0 ||
      processModelsId.includes(task.idprocessmodel);
    const result = isInAnd
      ? matchesSearchFilter &&
        matchesTags &&
        matchesPriority &&
        matchesUserData &&
        matchesProcessModelName
      : matchesSearchFilter ||
        matchesTags ||
        matchesPriority ||
        matchesUserData ||
        matchesProcessModelName;
    //
    return result;
  };

//GENERATA DA CHATGPT
const extractNumber = (item: number | string) => {
  if (typeof item === "number") {
    return item;
  }
  const match = item.match(/\d+/);
  return match ? parseInt(match[0], 10) : Infinity; // Se non c'è numero, ritorna Infinity
};

//GENERATA DA CHATGPT
export const customSort = (
  a: number | string,
  b: number | string,
  userDataSortType: SupportedUserDataTypes
) => {
  console.log(userDataSortType)
  if (userDataSortType === SupportedUserDataTypes.number) {
    const numA = Number(a);
    const numB = Number(b);
    if (!isNaN(numA) && !isNaN(numB)) {
      return numA - numB;
    } else if (!isNaN(numA)) {
      return -1;
    } else {
      return 1;
    }
  } else if (userDataSortType === SupportedUserDataTypes.date) {
    const aValid = dayjs(a).isValid() && isNaN(Number(a)); 
    const bValid = dayjs(b).isValid() && isNaN(Number(b)); 
    if (aValid && bValid) {
      return dayjs(a).diff(dayjs(b));
    } else if (aValid) {
      return -1;
    } else {
      return 1;
    }
  } else {
    const numA = extractNumber(a);
    const numB = extractNumber(b);

    if (numA !== numB) {
      return numA - numB;
    }
    // Se i numeri sono uguali, ordina come stringhe
    if (typeof a === "string" && typeof b === "string") {
      return a.localeCompare(b);
    } else if (typeof a === "number") {
      return -1;
    } else {
      return 1;
    }
  }
};

export const hasDuplicates = (items: unknown[]) => {
  return new Set(items).size !== items.length;
};

export const checkUserDataSortType = (
  userdatas: Array<string | null>,
  sort: SortState,
  selectedUserDataKey: string | null
) => {
  let userDataSortType = SupportedUserDataTypes.string;
  if (selectedUserDataKey && ![undefined, SortState.None].includes(sort)) {
    const counters: Partial<Record<SupportedUserDataTypes, number>> = {};
    for (const userdata of userdatas) {
      const value = getUserData(userdata)[selectedUserDataKey];
      const countersKey = Object.keys(counters).map(
        (v) => Number(v) as SupportedUserDataTypes
      );
      if (!countersKey.includes(value?.type || SupportedUserDataTypes.string)) {
        counters[value.type || SupportedUserDataTypes.string] = 1;
      } else {
        //@ts-ignore
        counters[value.type || SupportedUserDataTypes.string] += 1;
      }
    }
    // per tutti i tipi che sono stati contati almeno una volta
    const countersKey = Object.keys(counters).map(
      (v) => Number(v) as SupportedUserDataTypes
    );
    if (Object.keys(counters).length > 0) {
      for (const k of countersKey) {
        if (!counters[userDataSortType]) {
          userDataSortType = k;
        } else if (!counters[k]) {
          //@ts-ignore
        } else if (counters[userDataSortType] < counters[k]) {
          userDataSortType = k;
        }
      }
    }
  }
  return userDataSortType;
};
export const sortTasks = (
  tasks: Task[], //array di task da ordinare
  sortCriteria: SortCriterion,
  selectedUserDataKey: string | null,
  priorities: Priority[]
): Task[] => {
  if (
    Object.values(sortCriteria).filter((crit) => crit !== SortState.None)
      .length === 0
  )
    return tasks; //task non ordinati se non ci sono criteri
  const indexedPriority = Object.fromEntries(
    priorities.map(({ idorder, name }) => [name, idorder])
  );

  const userDataSortType = checkUserDataSortType(
    tasks.map((a) => a.userdata),
    sortCriteria.userdata,
    selectedUserDataKey
  );

  return tasks.sort((a, b) => {
    //metodo sort
    for (const [field, value] of Object.entries(sortCriteria)) {
      //le entries che torna obj.entries son un array di tuple(array di array con chiave - valore)?
      let result = 0;
      if (value === SortState.None) {
        continue;
      }
      switch (field) {
        case "name":
          result = (a.description || "").localeCompare(b.description || "");
          break;
        case "startDate":
          const dateA = new Date(a.created || 0); //0 in caso non sia definito
          const dateB = new Date(b.created || 0);
          result = dateA.getTime() - dateB.getTime();
          break;
        case "priority":
          result =
            (indexedPriority[a.priority_name] || 0) -
            (indexedPriority[b.priority_name] || 0);
          break;
        case "userdata":
          //quando cambia il filtro per userdatakey il sort per userdatakey va tolto
          result = customSort(
            getUserData(a.userdata)[selectedUserDataKey || ""].value,
            getUserData(b.userdata)[selectedUserDataKey || ""].value,
            userDataSortType
          );
          break;
        default:
          continue; // salta il criterio se non riconosciuto
      }
      if (result !== 0) return value === SortState.Desc ? -result : result; // se il è risultato diverso da zero (i due elementi sono diversi), il confronto viene
      //invertito se l'ordine è 'desc', altrimenti no.
    }
    return 0;
  });
};

export const processTasks = (
  //combiniamo filter e sort
  tasks: Task[], //array di task da processare.
  filter: string,
  searchTags: number[],
  selectedPriorities: string[],
  selectedActivities: string[],
  userDataKey: string | null,
  processModelsId: number[],
  sortCriteria: SortCriterion,
  priorities: Priority[]
): Task[] => {
  const filtered = tasks.filter(
    filterTasks(
      filter,
      searchTags,
      selectedPriorities,
      selectedActivities,
      userDataKey,
      processModelsId,
    )
  ); //filtriamo i task
  const sorted = sortTasks(filtered, sortCriteria, userDataKey, priorities); //ritorniamo ordinati i task filtrati
  return sorted;
};

export function getStyle(
  style: DraggingStyle | NotDraggingStyle | undefined,
  snapshot: DraggableStateSnapshot,
  isDoing: boolean
) {
  if (!isDoing || snapshot.draggingOver !== "done" || !snapshot.dropAnimation) {
    return style;
  }
  const { moveTo, curve, duration } = snapshot.dropAnimation;
  // move to the right spot
  const translate = `translate(${moveTo.x}px, ${moveTo.y}px)`;
  // add a bit of turn for fun
  const rotate = "scale(0.1)";

  // patching the existing style
  return {
    ...style,
    transform: `${translate} ${rotate}`,
    // slowing down the drop because we can
    transition: `all ${curve} ${duration + 1}s`,
  };
}
export module CONSTANTS {
  export const STORE_LABEL_COMPLETE_MODE = "label_complete_mode";
  export const STORE_EXTENSIONS_ELEMENTS = "extensionElements";
  export const STORE_OPP_POSITION = "oppPositions";
  export const STORE_MINIMAP_OPEN = "isMinimapOpen";
  export const STORE_OVERLAP_ENABLED = "isOverLapEnabled";
  export const STORE_SELECTED_ORGANIZATION_ID = "selected_organization_id";
  export const STORE_USER = "flowenti/user";
  export const STORE_SIDEBAR_OPEN = "isSidebarOpen";
}
export const url =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BACKEND_BASE_URI
    : "/flowentisysman/v1/";
export const REFRESH_ACTIVITIES_SECONDS = 10;
export const formatDate = (date: string | null) => {
  if (!date) {
    return "";
  }
  const timeZone = dayjs.tz.guess();
  const day = dayjs.utc(date);
  return day.tz(timeZone).format("DD/MM/YYYY HH:mm");
};
export const getTranslation = (translations: Translations, key: string) =>
  translations[key] || key;
// translations.filter((ele: MessageModel) => ele.iddescribe === key)[0]
//   ?.message ?? "";

export const create_UUID = () => {
  var dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      // eslint-disable-next-line no-mixed-operators
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
};
export interface OutletType {
  firstFilter: string;
  //# discourage use of any
  // setFirstFilter: any;
  setFirstFilter: Function;
  secondFilter: string;
  //# discourage use of any
  // setSecondFilter: any;
  setSecondFilter: Function;
}
export const toDataUrl = (url: string) =>
  new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  });

export interface JWTData {
  iss: string;
  exp: number;
  nbf: number;
  iat: number;
  userid: string;
  roles: string;
  ServerName: string;
  "last-event-id": string;
  username: string;
}
type ValueOf = string | symbol | number;
export type Res<T> = {
  [key: ValueOf]: T[];
};
export const groupBy = <T>(xs: T[], key: keyof T): Res<T> => {
  return xs.reduce((rv: Res<T>, x: T) => {
    (rv[x[key] as ValueOf] = rv[x[key] as ValueOf] || []).push(x);
    return rv;
  }, {});
};

export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export const MONTHS: Array<{ name: string; value: number }> = [
  // { name: "all", value: -1 },
  { name: "january", value: 1 },
  { name: "february", value: 2 },
  { name: "march", value: 3 },
  { name: "april", value: 4 },
  { name: "may", value: 5 },
  { name: "june", value: 6 },
  { name: "july", value: 7 },
  { name: "august", value: 8 },
  { name: "september", value: 9 },
  { name: "october", value: 10 },
  { name: "november", value: 11 },
  { name: "december", value: 12 },
];
export const DAYS: Array<{ name: string; value: number }> = [
  { name: "monday", value: 1 },
  { name: "tuesday", value: 2 },
  { name: "wednesday", value: 3 },
  { name: "thursday", value: 4 },
  { name: "friday", value: 5 },
  { name: "saturday", value: 6 },
  { name: "sunday", value: 0 },
];
export enum EventTypes {
  START,
  END,
  TIMER_START,
}
export enum TaskStates {
  TO_DO,
  DOING,
  DONE,
}

export const TASK_STATE_TO_STR = {
  [TaskStates.TO_DO]: "todo",
  [TaskStates.DOING]: "doing",
  [TaskStates.DONE]: "done",
};

export const SUPPORTED_EXTENSIONS = [
  "jpg",
  "jpeg",
  "png",
  "webp",
  "svg",
  "ico",
  "gif",
  "apng",
  "bmp",
  "avif",
  "pptx",
  "docx",
  "xlsx",
  "pdf",
];

export const getFileExtension = (filename: string) =>
  filename.split(".").pop()?.toLowerCase();
export const getQueryParams = (object: Record<string, any>) =>
  Object.keys(object)
    .map((key) => key + "=" + object[key])
    .join("&");

export const createPath = (
  fp: ProcessModelFolderType | SelectedProcessModelType,
  plain: (ProcessModelFolderType | SelectedProcessModelType)[]
) => {
  let results: number[] = [];
  if (fp.idfolder) {
    const result = plain.find((_) => _.id === fp.idfolder);
    if (result) {
      results.push(result.id);
      results = results.concat(createPath(result, plain));
    }
  }
  return results;
};

export const emptyImageonDragEvent = <T>(e: React.DragEvent<T>) => {
  var dragImgEl = document.getElementById("emptySpan");
  if (!dragImgEl) {
    dragImgEl = document.createElement("span");
    // set its style so it'll be effectively (but not technically) invisible and
    // won't change document flow
    dragImgEl.setAttribute(
      "style",
      "position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;"
    );
    dragImgEl.setAttribute("id", "emptySpan");
    // add it to the document
    document.body.appendChild(dragImgEl);
  }
  e.dataTransfer.setDragImage(dragImgEl, 0, 0);
};

export const clearLanes = (xmlContent: string | null) => {
  if (xmlContent) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlContent, "text/xml");
    // Clear Collaboration node
    const extensionNode = xmlDoc.getElementsByTagName(
      "bpmn2:extensionElements"
    )[0];
    if (extensionNode) {
      extensionNode.remove();
    }
    // const itemToRemove: Array<ChildNode> = [];
    // collaborationNode.childNodes.forEach(childNode => {
    //   if(childNode) {
    //     itemToRemove.push(childNode)
    //   }
    // })
    // for (let item of itemToRemove) {
    //   item.remove();
    // }
    // Clear Collaboration node

    // Clear name and associationName attribute from lanes
    // const lanesNode = xmlDoc.getElementsByTagName("bpmn2:lane");
    // for(let i = 0; i < lanesNode.length; i++) {
    //   lanesNode[i].removeAttribute("name")
    //   // lanesNode[i].removeAttribute("associationName")
    // }
    const result = new XMLSerializer().serializeToString(xmlDoc);
    return result || "";
  }
  return "";
};

export const putPrioritiesOnServer = async (
  priorities: Priority[],
  organizationId: number
) => {
  const promises = [];
  const createPayloads = [];
  for (let priority of priorities) {
    if (priority.new && priority.deleted) continue;
    if (priority.new) {
      createPayloads.push({
        idorder: priority.idorder,
        name: priority.name,
      });
      // promises.push(
      //   organizationsApi.post(`/${organizationId}/priorities`, [
      //     {
      //       idorder: priority.idorder,
      //       name: priority.name,
      //     },
      //   ])
      // );
    } else if (priority.deleted) {
      promises.push(
        organizationsApi.delete(`/${organizationId}/priorities/${priority.id}`)
      );
    } else {
      promises.push(
        organizationsApi.put(`/${organizationId}/priorities/${priority.id}`, {
          ...priority,
        })
      );
    }
  }

  if (createPayloads.length > 0) {
    promises.push(
      organizationsApi.post(`/${organizationId}/priorities`, createPayloads)
    );
  }

  await Promise.all(promises);
};
