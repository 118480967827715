import { AxiosRequestConfig } from "axios";
import { CONSTANTS } from "common";

// const getSelectedLanguage = () => {
//     if (translation.length) {
//       return translation[0].localization;
//     }
//     return navigator.language.split("-")[0];
//   };

const jwtInterceptor = (config: AxiosRequestConfig<any>) => {
  if (config.headers) {
    
    // const state = store.getState();
    const user = JSON.parse(localStorage.getItem(CONSTANTS.STORE_USER) || "null");
    if (user) {
      const { i18n, jwt: token } = user;
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
        config.headers["Accept-Language"] =
          i18n || navigator.language.split("-")[0];
      }
    }
  }
  return config;
};
export default jwtInterceptor;
