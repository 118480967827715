import {
  Badge,
  Box,
  Button,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DialogHeader from "./dialogs/DialogHeader";
import { getTranslation } from "common";
import { useTranslations } from "hooks";
import { ProcessModelErrorsType } from "model/processModel";
import ErrorList from "Component/Pages/Dashboard/Process/NewProcessModule/ErrorList";
import { Error } from "@mui/icons-material";
interface ProcessModelErrorProps {
  errors: Array<ProcessModelErrorsType>;
  selectedError: number | null;
  setSelectedError: (errorId: number | null) => void;
  isOverlapChecked: boolean;
}

const ProcessModelError: React.FC<ProcessModelErrorProps> = ({
  errors,
  selectedError,
  setSelectedError,
  isOverlapChecked,
}) => {
  const [open, setOpen] = useState(false);
  const translations = useTranslations();
  const handleClose = () => setOpen(false);
  const [enableClickAway, setEnableClickAway] = useState<boolean>(true);
  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    if(open) {
      setEnableClickAway(false);
    } else {
      setTimeout(() => setEnableClickAway(true));
    }
  }, [open]);
  
  if (errors.length <= 0) {
    return null;
  }
  return (
    <React.Fragment>
      <ClickAwayListener onClickAway={() => enableClickAway && setShowError(false)}>
        <Box
          sx={{
            position: "absolute",
            [isOverlapChecked ? "bottom" : "top"]: isOverlapChecked ? "50%" : 0,
            left: 0,
            zIndex: 999,
          }}
        >
          <Stack alignItems="center" direction="row">
            <Tooltip title={getTranslation(translations, "generic.errors")}>
              <IconButton
              id="errors-button"
                size="large"
                color="primary"
                aria-label="errors"
                sx={{ p: 0 }}
                onClick={() => setOpen(true)}
              >
                <Badge
                  overlap="circular"
                  color="error"
                  badgeContent={errors.length}
                >
                  <Error fontSize="large" />
                </Badge>
              </IconButton>
            </Tooltip>
            {selectedError !== null && showError && (
              <Box sx={{ borderRadius: 2, px: 1, bgcolor: "#2c2c2c44" }}>
                <Typography variant="caption">
                  {errors[selectedError]?.messaging}
                </Typography>
              </Box>
            )}
          </Stack>
        </Box>
      </ClickAwayListener>
      <Dialog open={open} onClose={handleClose}>
        <DialogHeader onClose={handleClose}>
          {getTranslation(translations, "generic.errors")}
        </DialogHeader>
        <DialogContent>
          <ErrorList
            errors={errors}
            selectedError={selectedError}
            setSelectedError={(value) => {
              setShowError(true);
              setSelectedError(value);
              handleClose();
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" size="small" onClick={handleClose}>
            {getTranslation(translations, "generic.button.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ProcessModelError;
