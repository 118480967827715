import React from "react";
import { useState, useEffect } from "react";
import { Process } from "model/processModel";
import { useSelectedOrganization } from "hooks";
import { baseApi, processmodelsApi } from "api";
//import axios from "axios";

//@ts-ignore
import BpmnModeler from "bpmn-js/lib/Modeler";
import Selection from "diagram-js/lib/features/selection/Selection";
//@ts-ignore
import { getBusinessObject } from "bpmn-js/lib/util/ModelUtil";
import MenuItem from "@mui/material/MenuItem";
import { SelectChangeEvent } from "@mui/material/Select";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { ProcessModelDataTypes } from "hooks/useBpmnModeler/useBpmnJsModeler";
import RunTimeAssignmentFields from "./RunTimeAssignmentFields";
import TimerDefinitionSettings from "./TimerDefinitionSettings";
import { generateISODurationString } from "../../../../../../../utils/DateUtils";

import { useSnackbar, useTranslations } from "hooks";
import { getTranslation } from "common";
import {
  Stack,
  TextField,
  Button,
  Typography,
  ListItemButton,
  ListItemText,
  Collapse,
  Box,
} from "@mui/material";
import { ExpandLess, ExpandMore, MoreVert } from "@mui/icons-material";
import { ExtensionElementType } from "model/processModel";
import { useParams } from "react-router-dom";

interface SpecificPropertiesPanelTypes {
  toggleSpecificPropertiesAccordian: boolean;
  setToggleSpecificPropertiesAccordian: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  element: any;
  modeler: BpmnModeler;
  timerType: string;
  setTimerType: (value: string) => void;
  timeDateValue: Date | null;
  setTimeDateValue: (value: Date | null) => void;
  timeDurationValue: Date | string;
  setTimeDurationValue: (value: Date | string) => void;
  processModelData: ProcessModelDataTypes;
  getRequestBodyForExtensionElements: () => Array<ExtensionElementType>;
  setProcessModelData: (data: ProcessModelDataTypes) => void;
  // getSelectedLanguage: () => string;
  openDiagram: (
    xml: string,
    shouldShowInTheViewPort: boolean,
    onSuccess?: () => void
  ) => Promise<void>;
  selectedDuration: {
    months: number;
    weeks: number;
    days: number;
    hours: number;
    minutes: number;
  };
  setSelectedDuration: (value: {
    months: number;
    weeks: number;
    days: number;
    hours: number;
    minutes: number;
  }) => void;
}

const SpecificPropertiesPanel: React.FC<SpecificPropertiesPanelTypes> = ({
  toggleSpecificPropertiesAccordian,
  setToggleSpecificPropertiesAccordian,
  element,
  modeler,
  timerType,
  setTimerType,
  timeDateValue,
  setTimeDateValue,
  processModelData,
  getRequestBodyForExtensionElements,
  setProcessModelData,
  // getSelectedLanguage,
  openDiagram,
  selectedDuration,
  setSelectedDuration,
}) => {
  const translations = useTranslations();
  const [timerDialog, setTimerDialog] = useState<boolean>(false);
  const { sendSnack } = useSnackbar();
  const { editId } = useParams();
  const [processModels, setProcessModels] = useState<
    Array<Process & { _name: string }>
  >([]);
  const [selectedOption, setSelectedOption] = useState<number | null>(
    processModelData.extensionsubprocesses.find(
      (elem) => elem.source === element.id
    )?.eventid || null
  );
  const selectedOrganization = useSelectedOrganization();
  useEffect(() => {
    (async () => {
      const res = await baseApi.get(
        `startevents/organizations/${selectedOrganization?.id}`
      );
      setProcessModels(
        res.data.possiblestart.map((_: Process) => ({
          ..._,
          _name:
            _.name === ""
              ? _.processmodelname
              : _.processmodelname + " (" + _.name + ")",
        }))
      );
    })();
  }, [selectedOrganization]);

  const SubprocessSpecificProperties = () => {
    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
      const id = parseInt(event.target.value);
      const selectedProcessModel = processModels.find((_) => _.id === id);
      if (id) {
        setSelectedOption(id);
        try {
          const { xml } = await modeler.saveXML();
          const response = await processmodelsApi.post(
            `/subprocess/currentflownode/${element.id}?eventid=${id}&name=${selectedProcessModel?._name}`,
            {
              ...processModelData,
              model: xml?.replaceAll("#ff0000", "#000000"),
              extensionelements: getRequestBodyForExtensionElements(),
            }
          );

          let newProcessModelData = {
            ...processModelData,
            ...response.data,
          };

          setProcessModelData(newProcessModelData);

          await openDiagram(response.data.model, false);
          let elementRegistry = modeler.get("elementRegistry") as any;
          let selectedElement: any = elementRegistry.get(element.id);
          const selection = modeler.get("selection") as Selection;
          selection.select(selectedElement);
          // selection.select([element]);
        } catch (error) {}
      }
    };
    const options = processModels.filter(
      (_) => String(_.idprocessmodel) !== String(editId)
    );
    return (
      <Stack spacing={2}>
        {options.length > 0 && (
          <TextField
            disabled={
              processModels.filter(
                (_) => String(_.idprocessmodel) !== String(editId)
              ).length <= 0
            }
            id="model-association-btn"
            select
            label={getTranslation(translations, "pm.modelname.label.caption")}
            fullWidth
            size="small"
            margin="normal"
            value={selectedOption || ""}
            onChange={handleChange}
          >
            {options.map((option) => (
              <MenuItem
                id={`model-${option.id}`}
                key={option.id}
                value={option.id}
              >
                {option._name}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Stack>
    );
  };

  const getShouldShowTimerTypeField = () => {
    const bo = getBusinessObject(element);
    const isTimerEvent = bo.eventDefinitions && bo.eventDefinitions.length;

    return (
      (element.type === "bpmn:StartEvent" && isTimerEvent) ||
      element.type === "bpmn:IntermediateCatchEvent" ||
      isTimerEvent
    );
  };
  const getShouldShowDuration = () => {
    return element.type === "bpmn:IntermediateCatchEvent";
  };
  const currentDate = new Date();
  const minDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );
  const handleChangeTimerType = (e: SelectChangeEvent<string>) => {
    setTimerType(e.target.value);
    if (e.target.value === "") {
      const bo = getBusinessObject(element);
      let eventDefinition = bo.eventDefinitions[0];

      delete eventDefinition["timeDuration"];
      delete eventDefinition["timeCycle"];
      delete eventDefinition["timeDate"];
      modeler.get<any>("modeling").updateProperties(element, {
        eventDefinitions: [eventDefinition],
      });
    }
  };

  const onSaveDate = (timeDateValue: Date | null) => {
    let moddle: any = modeler.get("moddle");
    const bo = getBusinessObject(element);
    const isFormalExpressionExist = bo.eventDefinitions[0]?.timeDate;
    if (timeDateValue) {
      if (isFormalExpressionExist && timeDateValue) {
        let eventDefinition = bo.eventDefinitions[0];
        eventDefinition.timeDate.body = new Date(timeDateValue).toISOString();

        delete eventDefinition["timeDuration"];
        delete eventDefinition["timeCycle"];

        modeler.get<any>("modeling").updateProperties(element, {
          eventDefinitions: [eventDefinition],
        });
        sendSnack({
          message: getTranslation(translations, "generic.model_update.caption"),
          type: "success",
        });
      } else {
        let formalExpression = moddle.create("bpmn:FormalExpression", {
          body: new Date(timeDateValue).toISOString(),
        });

        let eventDefinition = bo.eventDefinitions[0];

        delete eventDefinition["timeDuration"];
        delete eventDefinition["timeCycle"];

        eventDefinition["timeDate"] = formalExpression;

        modeler.get<any>("modeling").updateProperties(element, {
          eventDefinitions: [eventDefinition],
        });
        sendSnack({
          message: getTranslation(translations, "generic.model_update.caption"),
          type: "success",
        });
      }
    }
  };

  const onChangeSelectDate = (value: Date | null) => {
    if (value !== null) {
      setTimeDateValue(new Date(value));
    }
  };

  const shouldShowRunTimeAssignmentModeForLane = () => {
    return element.type === "bpmn:Lane";
  };
  const shouldShowRunTimeAssignmentMode = () => {
    return (
      element.type === "bpmn:ExclusiveGateway" ||
      element.type === "bpmn:InclusiveGateway" ||
      element.type === "bpmn:Task" ||
      element.type === "bpmn:SubProcess"
    );
  };

  const shouldShowSpecificProperties = () => {
    return (
      shouldShowRunTimeAssignmentMode() ||
      shouldShowRunTimeAssignmentModeForLane() ||
      getShouldShowTimerTypeField()
    );
  };

  const shouldShowCycleOption = () => {
    const bo = getBusinessObject(element);
    const isTimerEvent = bo.eventDefinitions && bo.eventDefinitions.length;

    return element.type === "bpmn:StartEvent" && isTimerEvent;
  };

  const onSaveDurationSettings = () => {
    const isoString = generateISODurationString(selectedDuration);
    if (isoString) {
      // const durationValue = generateDurationIsoString(value);

      let moddle: any = modeler.get("moddle");

      const bo = getBusinessObject(element);

      const isFormalExpressionExist = bo.eventDefinitions[0]?.timeDuration;
      if (isFormalExpressionExist) {
        let eventDefinition = bo.eventDefinitions[0];
        eventDefinition.timeDuration.body = isoString;

        delete eventDefinition["timeDate"];
        delete eventDefinition["timeCycle"];

        modeler.get<any>("modeling").updateProperties(element, {
          eventDefinitions: [eventDefinition],
        });

        sendSnack({
          message: getTranslation(translations, "generic.model_update.caption"),
          type: "success",
        });
      } else {
        let formalExpression = moddle.create("bpmn:FormalExpression", {
          body: isoString,
        });

        let eventDefinition = bo.eventDefinitions[0];

        delete eventDefinition["timeDate"];
        delete eventDefinition["timeCycle"];

        eventDefinition["timeDuration"] = formalExpression;

        modeler.get<any>("modeling").updateProperties(element, {
          eventDefinitions: [eventDefinition],
        });

        sendSnack({
          message: getTranslation(translations, "generic.model_update.caption"),
          type: "success",
        });
      }
      // setTimeDurationValue(value);
    }
  };

  const RenderDurationFields = () => {
    return (
      <Stack direction="column" alignItems={"end"} spacing={2}>
        <TextField
          fullWidth
          label={getTranslation(
            translations,
            "pm.propertyeditor.specificproperties.repetition_details.duration.label.month"
          )}
          size="small"
          type="number"
          name="months"
          inputProps={{ min: 0 }}
          value={selectedDuration.months}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSelectedDuration({
              ...selectedDuration,
              months: Number(e.target.value),
            });
          }}
        />
        <TextField
          fullWidth
          label={getTranslation(
            translations,
            "pm.propertyeditor.specificproperties.repetition_details.duration.label.weeks"
          )}
          size="small"
          type="number"
          name="weeks"
          inputProps={{ min: 0 }}
          value={selectedDuration.weeks}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSelectedDuration({
              ...selectedDuration,
              weeks: Number(e.target.value),
            });
          }}
        />
        <TextField
          fullWidth
          label={getTranslation(
            translations,
            "pm.propertyeditor.specificproperties.repetition_details.duration.label.days"
          )}
          size="small"
          type="number"
          name="days"
          inputProps={{ min: 0 }}
          value={selectedDuration.days}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSelectedDuration({
              ...selectedDuration,
              days: Number(e.target.value),
            });
          }}
        />
        <TextField
          fullWidth
          label={getTranslation(
            translations,
            "pm.propertyeditor.specificproperties.repetition_details.duration.label.hours"
          )}
          size="small"
          type="number"
          name="hours"
          inputProps={{ min: 0 }}
          value={selectedDuration.hours}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSelectedDuration({
              ...selectedDuration,
              hours: Number(e.target.value),
            });
          }}
        />
        <TextField
          fullWidth
          label={getTranslation(
            translations,
            "pm.propertyeditor.specificproperties.repetition_details.duration.label.minutes"
          )}
          size="small"
          type="number"
          name="minutes"
          inputProps={{ min: 0, max: 60 }}
          value={selectedDuration.minutes}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSelectedDuration({
              ...selectedDuration,
              minutes: Number(e.target.value),
            });
          }}
        />
        <Button
          size="small"
          variant="contained"
          onClick={onSaveDurationSettings}
        >
          {getTranslation(translations, "pm.button.save")}
        </Button>
      </Stack>
    );
  };
  if (!shouldShowSpecificProperties()) {
    return null;
  }
  return (
    <React.Fragment>
      <ListItemButton
        onClick={() => setToggleSpecificPropertiesAccordian((prev) => !prev)}
      >
        <ListItemText
          primary={getTranslation(
            translations,
            "pm.propertyeditor.specificproperties"
          )}
        />
        {toggleSpecificPropertiesAccordian ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse
        sx={{ pt: 2 }}
        timeout="auto"
        unmountOnExit
        in={toggleSpecificPropertiesAccordian}
      >
        {/* senza di questo pezzetto succedeva che: Uncaught Error: Rendered fewer hooks than expected. This may be caused by an accidental early return statement in React Hooks */}
        <Box
          style={{
            display: element.type === "bpmn:SubProcess" ? "block" : "none",
          }}
        >
          {SubprocessSpecificProperties()}
        </Box>
        <Box
          style={{
            display: element.type !== "bpmn:SubProcess" ? "block" : "none",
          }}
        >
          <RunTimeAssignmentFields
            element={element}
            shouldShowRunTimeAssignmentModeForLane={
              shouldShowRunTimeAssignmentModeForLane
            }
            shouldShowRunTimeAssignmentMode={shouldShowRunTimeAssignmentMode}
            modeler={modeler}
            processModelData={processModelData}
            getRequestBodyForExtensionElements={
              getRequestBodyForExtensionElements
            }
            setProcessModelData={setProcessModelData}
            openDiagram={openDiagram}
          />
          {getShouldShowTimerTypeField() && (
            <Stack spacing={2}>
              <TextField
                size="small"
                fullWidth
                select
                label={getTranslation(
                  translations,
                  "pm.propertyeditor.specificproperties.label.timer_type"
                )}
                id="timer-type"
                value={
                  !shouldShowCycleOption() && timerType === "Cycle"
                    ? ""
                    : timerType
                }
                onChange={(e) =>
                  handleChangeTimerType(e as SelectChangeEvent<string>)
                }
              >
                <MenuItem value="">
                  {getTranslation(
                    translations,
                    "pm.propertyeditor.specificproperties.repetition_details.type_of_timer.none"
                  )}
                </MenuItem>
                <MenuItem value={"Date"}>
                  {getTranslation(
                    translations,
                    "pm.propertyeditor.specificproperties.repetition_details.type_of_timer.date"
                  )}
                </MenuItem>
                {getShouldShowDuration() && (
                  <MenuItem value={"Duration"}>
                    {getTranslation(
                      translations,
                      "pm.propertyeditor.specificproperties.repetition_details.type_of_timer.duration"
                    )}
                  </MenuItem>
                )}
                {shouldShowCycleOption() && (
                  <MenuItem value={"Cycle"}>
                    {getTranslation(
                      translations,
                      "pm.propertyeditor.specificproperties.repetition_details.type_of_timer.cycle"
                    )}
                  </MenuItem>
                )}
              </TextField>

              {timerType === "Date" && (
                <Stack direction="column" alignItems={"end"} spacing={2}>
                  <DateTimePicker
                    label={getTranslation(
                      translations,
                      "pm.propertyeditor.specificproperties.label.select_date"
                    )}
                    slotProps={{
                      textField: {
                        size: "small",
                        helperText:
                          !timeDateValue ||
                          isNaN(new Date(timeDateValue).getTime())
                            ? getTranslation(
                                translations,
                                "generic.invalid_data.caption"
                              )
                            : undefined,
                      },
                    }}
                    minDate={dayjs(minDate) as any}
                    value={dayjs(timeDateValue || new Date()) as any}
                    onChange={onChangeSelectDate}
                  />
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => onSaveDate(timeDateValue)}
                    disabled={
                      !timeDateValue || isNaN(new Date(timeDateValue).getTime())
                    }
                  >
                    {getTranslation(translations, "pm.button.save")}
                  </Button>
                </Stack>
              )}

              {timerType === "Cycle" && (
                <Button
                  onClick={() => setTimerDialog(true)}
                  size="small"
                  variant="contained"
                  endIcon={<MoreVert />}
                >
                  {getTranslation(
                    translations,
                    "pm.propertyeditor.specificproperties.label.timer_type.value"
                  )}
                </Button>
              )}

              {timerType === "Duration" && (
                <Stack mt={2} spacing={2}>
                  <Typography variant="subtitle2">
                    {getTranslation(
                      translations,
                      "pm.propertyeditor.specificproperties.label.select_duration"
                    )}
                  </Typography>
                  <RenderDurationFields />
                </Stack>
              )}

              {timerType === "Cycle" && (
                <TimerDefinitionSettings
                  open={timerDialog}
                  onClose={() => setTimerDialog(false)}
                  timerType={timerType}
                  timeDateValue={timeDateValue}
                  modeler={modeler}
                  element={element}
                />
              )}
            </Stack>
          )}
        </Box>
      </Collapse>
    </React.Fragment>
  );
};

export default SpecificPropertiesPanel;
