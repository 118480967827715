import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  Box,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { organizationsApi, usersApi } from "api";
import {
  useInfiniteScroll,
  useSelectedOrganization,
  useSnackbar,
  useTranslations,
  useUser,
} from "hooks";
import { getTranslation, OutletType } from "common";
import { useOutletContext } from "react-router-dom";
import UserItem, { User } from "./UserItem";
import {
  ExpandLess,
  ExpandMore,
  ForwardToInbox,
  MoreVertRounded,
  Unsubscribe,
} from "@mui/icons-material";
import AvatarRem from "./AvatarRem";
interface UsersPanelProps {
  onSelect: Function;
  selected: User | null;
  organizationId: number;
  administrator: boolean;
}
type Ref =
  | {
    getUsers: () => void;
    getUserUntilPage: (userSelected: User) => Promise<void>;
    getInvitedUsers: () => void;
  }
  | undefined;

const INITIAL_PAGE = 1;
const PAGE_SIZE = 10;

const getName = (
  firstname: string,
  lastname: string,
  email: string
): string => {
  return firstname && lastname ? `${firstname} ${lastname}` : email;
};

const UsersPanel = forwardRef<Ref, UsersPanelProps>(
  ({ onSelect, selected, organizationId, administrator }, ref) => {
    const [users, setUsers] = useState<Array<User>>([]);
    const [invited, setInvited] = useState<Array<User>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const translations = useTranslations();
    const { secondFilter: userFilter } = useOutletContext<OutletType>();
    const [page, setPage] = useState<number>(1);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const lastUserElementRef = useInfiniteScroll(setPage, loading, hasMore);
    const mount = useRef<boolean>(false);
    const { sendSnack } = useSnackbar();
    const selectedOrganization = useSelectedOrganization();
    const isAdmin = selectedOrganization?.administrator;
    const user = useUser();
    const [openInvitedUsers, setOpenInvitedUsers] = useState<boolean>(false);

    const getInvitedUsers = useCallback(async () => {
      try {
        const res = await organizationsApi.get(
          `${organizationId}/users/all?scope=notactivated`
        );
        setInvited(res.data.notactivated);
      } catch { } finally { }
    }, [organizationId]);

    useEffect(() => {
      getInvitedUsers();
    }, [getInvitedUsers]);

    const getFirstUsers = useCallback(async () => {
      setLoading(true);
      try {
        const queryPage = `?page=${INITIAL_PAGE}&pagesize=${PAGE_SIZE}`;
        const filter = `&name=${userFilter}`;
        const { data } = await organizationsApi.get(
          `${organizationId}/users/v2${queryPage}${filter}`
        );
        setHasMore(data.pagination.curr_page < data.pagination.total_pages);
        setUsers([...data.users]);
        setPage(INITIAL_PAGE);
      } catch {
      } finally {
        setLoading(false);
      }
    }, [organizationId, userFilter]);

    const getUserUntilPage = useCallback(
      async (userSelected: User) => {
        setLoading(true);
        try {
          const queryPage = `?page=${1}&pagesize=${PAGE_SIZE * page}`;
          const filter = `&name=${userFilter}`;
          const { data } = await organizationsApi.get(
            `${organizationId}/users/v2${queryPage}${filter}`
          );
          setHasMore(data.pagination.curr_page < data.pagination.total_pages);
          setUsers([...data.users]);
          if (userSelected) {
            const updatedSelected = (data.users as User[]).find(
              (_: User) => _.id === userSelected.id
            );
            onSelect({ ...updatedSelected });
          }
        } catch {
        } finally {
          setLoading(false);
        }
      },
      [organizationId, onSelect, page, userFilter]
    );

    const getUsers = useCallback(
      async (query: string) => {
        if (page !== INITIAL_PAGE) {
          setLoading(true);
          try {
            const queryPage = `?page=${page}&pagesize=${PAGE_SIZE}`;
            const filter = `&name=${query}`;
            const { data } = await organizationsApi.get(
              `${organizationId}/users/v2${queryPage}${filter}`
            );
            setHasMore(
              Number(data.pagination.curr_page) <
              Number(data.pagination.total_pages)
            );
            setUsers((_users) => [..._users, ...data.users]);
          } catch {
          } finally {
            setLoading(false);
          }
        }
      },
      [organizationId, page]
    );

    useImperativeHandle(
      ref,
      () => ({
        getUserUntilPage,
        getUsers: getFirstUsers,
        getInvitedUsers,
      }),
      [getFirstUsers, getUserUntilPage, getInvitedUsers]
    );

    useEffect(() => {
      getUsers(userFilter);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUsers]);

    useEffect(() => {
      if (organizationId && mount.current) {
        getFirstUsers();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId, userFilter]);

    useEffect(() => {
      if (!mount.current) {
        mount.current = true;
        getFirstUsers();
      }
    }, [getFirstUsers]);

    const onDeleteInvitedUser = async (userId: number) => {
      try {
        // /($idorganization)/users/($iduser)
        const { data } = await organizationsApi.delete(
          `${selectedOrganization?.id}/users/${userId}`
        );
        sendSnack({ message: data.responsemessage, type: "success" });
        getInvitedUsers();
      } catch {
      } finally {
        setInviteMenuOpen(null);
      }
    };

    const onReinviteUser = async (id: number, email: string) => {
      try {
        await usersApi.post(
          `${user?.userId}/organizations/${selectedOrganization?.id}/resendInvite/${id}`,
          { email: [email] }
        );
        sendSnack({
          message: getTranslation(
            translations,
            "generic.user_reinvite_success_wait_for_response.caption"
          ),
          type: "success",
        });
        getInvitedUsers();
      } catch {
      } finally {
        setInviteMenuOpen(null);
      }
    };


  //prima veniva memorizzato solo l'elemento html, adesso lo stato contiene un oggetto con i dati
    const [inviteMenuOpen, setInviteMenuOpen] = useState<{
      anchorEl: HTMLElement | null;
      userId: number | null;
      email: string | null;
    } | null>(null);

    return (
      <React.Fragment>
        <Stack height="100%" overflow="auto" flex={1}>
          <Stack height="100%">
            <Typography variant="subtitle1">
              {getTranslation(translations, "users.name")}
            </Typography>
            <Divider flexItem sx={{ mb: 1 }} />
            {isAdmin && (
              <Box maxHeight={"40%"} overflow="auto">
                <ListItemButton
                  dense
                  id="invited-users-button"
                  onClick={() => setOpenInvitedUsers((_) => !_)}
                >
                  <ListItemText>
                    {getTranslation(
                      translations,
                      "organizations.label.invitedusers"
                    )}
                  </ListItemText>
                  <ListItemSecondaryAction>
                    <IconButton disableRipple disableTouchRipple disableFocusRipple>
                      {!openInvitedUsers ? <ExpandMore /> : <ExpandLess />}
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItemButton>
                <Collapse in={openInvitedUsers} timeout="auto">
                  <List
                    sx={{ maxHeight: "100%" }}
                    id="invited-users"
                    disablePadding
                  >
                    {invited.map(
                      ({ picture, lastname, firstname, email, id }) =>
                        getName(firstname, lastname, email)
                          .toLowerCase()
                          .includes(userFilter.toLowerCase()) && (
                          <ListItem
                            key={`invited-${id}`}
                            id={`invited-${id}`}
                            divider
                          >
                            <ListItemAvatar
                              sx={{ height: "40px", width: "40px" }}
                            >
                              <AvatarRem src={picture || undefined} />
                            </ListItemAvatar>
                            <ListItemText>
                              {getName(firstname, lastname, email)}
                            </ListItemText>
                            <ListItemSecondaryAction>
                              <IconButton
                                id={`invited-users-btn-${id}`}
                                onClick={({ currentTarget }) =>
                                  setInviteMenuOpen({ anchorEl: currentTarget, userId: id, email: email }) //passiamo gli oggetti nuovi
                                }
                              >
                                <MoreVertRounded />
                              </IconButton>
                              <Menu
                                open={Boolean(inviteMenuOpen)}
                                anchorEl={inviteMenuOpen?.anchorEl}
                                onClose={() => setInviteMenuOpen(null)}
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                              >
                                <MenuItem
                                  id={`resend-invitation-${id}`}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    onReinviteUser(inviteMenuOpen?.userId!, inviteMenuOpen?.email!);
                                  }}
                                >
                                  <ListItemIcon>
                                    <ForwardToInbox fontSize="small" />
                                  </ListItemIcon>
                                  <ListItemText>
                                    {getTranslation(translations, "generic.resend_invitation.caption")}
                                  </ListItemText>
                                </MenuItem>
                                <MenuItem
                                  id={`delete-invitation-${id}`}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    onDeleteInvitedUser(inviteMenuOpen?.userId!);
                                  }}
                                >
                                  <ListItemIcon>
                                    <Unsubscribe fontSize="small" />
                                  </ListItemIcon>
                                  <ListItemText>
                                    {getTranslation(
                                      translations,
                                      "generic.delete_invitation.caption"
                                    )}
                                  </ListItemText>
                                </MenuItem>
                              </Menu>
                            </ListItemSecondaryAction>
                          </ListItem>
                        )
                    )}
                  </List>
                </Collapse>
              </Box>
            )}
            <Divider />
            <List
              id="users-list"
              sx={{
                overflow: "auto",
                // 90px è la somma delle altezze dei componenti sopra a questa lista
                maxHeight: openInvitedUsers ? "60%" : "calc(100% - 90px)",
              }}
              disablePadding
            >
              {users.map(
                (
                  {
                    username,
                    administrator: _administrator,
                    picture,
                    lastname,
                    firstname,
                    email,
                    id,
                    groups,
                    roles,
                  },
                  idx
                ) => (
                  // getName(firstname, lastname, email)
                  // .toLowerCase()
                  // .includes(userFilter.toLowerCase()) &&
                  <React.Fragment key={`user-${id}`}>
                    {users.length === idx + 1 ? (
                      <UserItem
                        userLoggedIsAdmin={administrator}
                        lastRef={lastUserElementRef}
                        getFirstUsers={getFirstUsers}
                        {...{
                          username,
                          administrator: _administrator,
                          picture,
                          lastname,
                          firstname,
                          email,
                          id,
                          onSelect,
                          organizationId,
                          selected,
                          groups,
                          roles,
                        }}
                      />
                    ) : (
                      <UserItem
                        userLoggedIsAdmin={administrator}
                        getFirstUsers={getFirstUsers}
                        {...{
                          username,
                          administrator: _administrator,
                          picture,
                          lastname,
                          firstname,
                          email,
                          id,
                          onSelect,
                          organizationId,
                          selected,
                          groups,
                          roles,
                        }}
                      />
                    )}
                  </React.Fragment>
                )
              )}
              {loading && (
                <Stack alignItems="center">
                  <CircularProgress />
                </Stack>
              )}
            </List>
          </Stack>
        </Stack>
      </React.Fragment>
    );
  }
);

export default UsersPanel;
