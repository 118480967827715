import { useEffect, useState } from "react";
import useSelectedOrganization from "./useSelectedOrganization";
import { organizationsApi } from "api";
import { ProcessModelType } from "model/processModel";

const useProcessModels = () => {
  const selectedOrganization = useSelectedOrganization();
  const [processModels, setProcessModels] = useState<ProcessModelType[]>([]);
  const [modelsLoading, setModelsLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        setModelsLoading(true);
        const { data } = await organizationsApi.get(
          `/${selectedOrganization?.id}/processmodels/v2`
        );
        setProcessModels(data.processmodels);
        setModelsLoading(false);
      } catch { } finally { }
    })();
  }, [selectedOrganization]);

  return { processModels, modelsLoading };
};

export default useProcessModels;
