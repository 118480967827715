import React from "react";
import TaskItem, { TaskDetail } from "./TaskItem";
import { BaseTask } from "model/activitiesModel";

export interface StartedProcess extends TaskDetail {
  jsontag: string | null;
  idprocess: number;
  process_description: string;
  process_enddate: string | null;
  process_startdate: string | null;
  process_idprocessmodel: number;
  process_userdata: string;
  processduration: number;
}

interface ProcessItemProps {
  handleClick: (task: TaskDetail, type: "delete" | "terminate") => void;
  process: StartedProcess;
  filters: Record<string, any>;
  setSelectedTask: (task: BaseTask) => void;
  userDataKeys: string[];
}
const ProcessItem: React.FC<ProcessItemProps> = ({
  handleClick,
  setSelectedTask,
  process,
  filters,
  userDataKeys,
}) => {
  return (
    <React.Fragment>
      <TaskItem
        setSelectedTask={setSelectedTask}
        handleClick={handleClick}
        key={`task-in-processes-${process.idprocesstoken}`}
        process={process}
        filters={filters}
        task={process}
        userDataKeys={userDataKeys}
      />
    </React.Fragment>
  );
};

export default ProcessItem;
