import React from "react";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { IconButton } from "@mui/material";
import { getTranslation } from "common";
import { useTranslations } from "hooks";
import { Label } from "./Label";

interface LabelRowProps {
  color?: string;
  name?: string;
  onEditClick?: () => void;
}

export const LabelRow: React.FC<LabelRowProps> = ({
  color,
  name,
  onEditClick,
}) => {
  const translations = useTranslations();

  return (
    <React.Fragment>
      <Label
        labelCompleteMode
        name={name || getTranslation(translations, "generic.labelname.caption")}
        color={color}
      />
      <IconButton size="small" onClick={onEditClick}>
        <EditRoundedIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
};

export default LabelRow;
