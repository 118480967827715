import { emptyImageonDragEvent } from "common";
import React, { useLayoutEffect, useRef, useState } from "react";
import useSidebar from "./useSidebar";
interface useDragItemReturn {
  ref: React.MutableRefObject<HTMLElement | undefined>;
  dragging: boolean;
  onDragEnd: (e: React.DragEvent<HTMLElement>) => void;
  onDrag: (e: React.DragEvent<HTMLElement>) => void;
  onDragStart: (
    event: React.DragEvent<HTMLElement>,
    cb: (e: React.DragEvent<HTMLElement>) => void
  ) => void;
}
interface useDragItemParameters {
  horizontal?: boolean;
  vertical?: boolean;
}
type useDragItemType = (props?: useDragItemParameters) => useDragItemReturn;
const useDragItem: useDragItemType = (
  { horizontal = true, vertical = true } = { horizontal: true, vertical: true }
) => {
  const ref = useRef<HTMLElement | undefined>();
  const clonedNode = useRef<HTMLElement | undefined>();
  const [, , distanceFromLeft] = useSidebar();
  const [dragging, setDragging] = useState<boolean>(false);

  const onDragEnd = (e: React.DragEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    emptyImageonDragEvent(e);
    if (clonedNode.current) {
      clonedNode.current.style.display = 'none';
      clonedNode.current.remove();
    }
    document.body.style.overflow ='unset';
    setDragging(false);
  };

  const onDrag = (e: React.DragEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    emptyImageonDragEvent(e);
    if (clonedNode.current) {
      clonedNode.current.style.width = `${ref.current?.offsetWidth}px`;
      clonedNode.current.style.zIndex = "999999999999999999999999";
      clonedNode.current.style.position = "absolute";
      if (horizontal) {
        clonedNode.current.style.left = `${e.clientX}px`;
      } else {
        clonedNode.current.style.left = `${distanceFromLeft}px`;
        clonedNode.current.style.right = `0px`;
      }
      if (vertical) {
        clonedNode.current.style.top = `${e.clientY + 25}px`;
      }
    }

   
  };
  useLayoutEffect(() => {
    if(dragging) {
      clonedNode.current = ref.current?.cloneNode(true) as HTMLElement;
      if(clonedNode.current) {
        document.body.appendChild(clonedNode.current);
        document.body.style.overflow ='hidden';
      }
    }
  }, [dragging]);
  const onDragStart = (
    event: React.DragEvent<HTMLElement>,
    cb: (e: React.DragEvent<HTMLElement>) => void
  ) => {
    event.stopPropagation();
    emptyImageonDragEvent(event);
    setDragging(true);
    cb && cb(event);
  };
  return { ref, dragging, onDragEnd, onDrag, onDragStart };
};

export default useDragItem;
