import { Close } from "@mui/icons-material";
import { DialogTitle, IconButton } from "@mui/material";
import React from "react";

interface DialogHeaderProps {
  children?: React.ReactNode;
  onClose: () => void;
}

const DialogHeader: React.FC<DialogHeaderProps> = ({ children, onClose, ...other }) => {
  return (
    <DialogTitle component="div" sx={{ m: 0, p: 2, display: "flex", alignItems: "center", justifyContent: "space-between" }} {...other}>
      <div style={{ flex: 1, overflow: "hidden", textOverflow: "ellipsis" }}>
        {children}
      </div>
      <IconButton
        id="closeicon-task"
        aria-label="close"
        onClick={onClose}
        sx={{
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
    </DialogTitle>
  );
};

export default DialogHeader;
