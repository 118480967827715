import {
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Stack,
} from "@mui/material";
import BpmnModeler from "bpmn-js/lib/Modeler";
import { getTranslation } from "common";
import { useSnackbar, useTranslations, useUser } from "hooks";
import { DAYS, MONTHS } from "common/utilities";
import DialogHeader from "components/dialogs/DialogHeader";
import cronstrue from "cronstrue";
import React, { useCallback, useEffect, useState } from "react";
import SelectCrondata from "./SelectCrondata";
import { generateCronExpression, parseCronExpression } from "utils/DateUtils";
import { range } from "lodash";
import { getBusinessObject } from "bpmn-js/lib/util/ModelUtil";
import { ModdleElement } from "bpmn-js/lib/model/Types";
import Modeling from "bpmn-js/lib/features/modeling/Modeling";

interface TimerDefinitionSettingsTypes {
  timerType: string;
  timeDateValue: Date | null;
  modeler: BpmnModeler;
  element: ModdleElement;
  open: boolean;
  onClose: () => void;
}

enum EverySupported {
  year,
  month,
  week,
  day,
  hour,
  minute,
}
interface CronData {
  every: EverySupported;
  minutes: number[]; // ["2", "4", "10-15"]
  hours: number[];
  days: number[];
  months: number[];
  daysOfWeek: number[];
}
const DEFAULT_CRONSTRING = "* * * * *";
const DEFAULT_CRONDATA: CronData = {
  every: EverySupported.year,
  days: [1],
  daysOfWeek: [],
  hours: [0],
  minutes: [0],
  months: [1],
};
const getDefaultByEvery = (newEvery: EverySupported): CronData => {
  switch (newEvery) {
    case EverySupported.year:
      return DEFAULT_CRONDATA;
    case EverySupported.month:
      return { ...DEFAULT_CRONDATA, months: [] };
    case EverySupported.week:
      return { ...DEFAULT_CRONDATA, months: [], daysOfWeek: [1], days: [] };
    case EverySupported.day:
      return { ...DEFAULT_CRONDATA, months: [], days: [] };
    case EverySupported.hour:
      return { ...DEFAULT_CRONDATA, months: [], days: [], hours: [] };
    case EverySupported.minute:
      return {
        ...DEFAULT_CRONDATA,
        months: [],
        days: [],
        hours: [],
        minutes: [],
      };
  }
};
const TimerDefinitionSettings = (props: TimerDefinitionSettingsTypes) => {
  const { modeler, element, open, onClose } = props;
  const { sendSnack } = useSnackbar();
  const translations = useTranslations();
  const [cronstring, setCronstring] = useState<string>(DEFAULT_CRONSTRING);
  // m h d m dow
  // * * * * *
  const [crondata, setCrondata] = useState<CronData>(DEFAULT_CRONDATA);

  const setCrondataFromCronstring = useCallback(() => {
    if (
      [
        "bpmn:StartEvent",
        "bpmn:IntermediateCatchEvent",
        "bpmn:BoundaryEvent",
      ].includes(element.type)
    ) {
      const bo = getBusinessObject(element);
      const expression =
        bo.eventDefinitions && bo.eventDefinitions.length
          ? bo.eventDefinitions[0]?.timeCycle
          : null;
      if (expression) {
        const {
          selectedDays: daysOfWeek,
          selectedMinutes: minutes,
          selectedHours: hours,
          selectedMonths: months,
          selectedDaysOfTheMonth: days,
        } = parseCronExpression(expression.body);
        const _crondata = {
          every:
            months.length > 0
              ? EverySupported.year
              : daysOfWeek.length > 0
              ? EverySupported.month
              : days.length > 0
              ? EverySupported.week
              : hours.length > 0
              ? EverySupported.day
              : minutes.length > 0
              ? EverySupported.hour
              : EverySupported.minute,
          days,
          daysOfWeek,
          hours,
          minutes,
          months,
        };
        setCrondata(_crondata);
      }
    }
  }, [element]);

  // utente può inserire per ogni crondata:
  // 1. intervallo da a 10-15 (6 volte)
  // 2. valore secco -> lista di valori
  // ogni minuto 15 dell'ora e ogni minuto 20 dell'ora
  // 15/20(2 volte)
  useEffect(() => {
    const newCronstring = generateCronExpression({
      selectedMonths: crondata.months.sort((a, b) => a - b),
      selectedDays: crondata.daysOfWeek.sort((a, b) => a - b),
      selectedDaysOfTheMonth: crondata.days.sort((a, b) => a - b),
      selectedHours: crondata.hours.sort((a, b) => a - b),
      selectedMinutes: crondata.minutes.sort((a, b) => a - b),
    });
    setCronstring(newCronstring);
  }, [crondata]);

  useEffect(() => {
    if (!open) {
      setCrondata(DEFAULT_CRONDATA);
      // setCronstring(DEFAULT_CRONSTRING);
    } else {
      setCrondataFromCronstring();
    }
  }, [open, setCrondataFromCronstring]);

  const prepareUpdateState = <G extends boolean, T>(
    multiple: G,
    newValue: G extends true ? T[] : T,
    key: keyof Omit<CronData, "every">
  ): ((currentState: CronData) => CronData) => {
    return (currentState) => {
      // ccs -> copy current state
      const ccs = { ...currentState };
      if (multiple) {
        ccs[key] = newValue as any;
        return ccs;
      }
      const isPresent = (ccs[key] as any).includes(newValue as any);
      if (isPresent) {
        ccs[key] = (ccs[key] as any).filter(
          (value: any) => value !== newValue
        ) as any;
      } else {
        ccs[key] = [...ccs[key], newValue as any];
      }
      return ccs;
    };
  };
  const user = useUser();
  const locale = user?.i18n || navigator.language.split("-")[0];

  const handleSave = () => {
    const moddle: ModdleElement = modeler.get("moddle");
    const modeling: Modeling = modeler.get('modeling');
    const bo = getBusinessObject(element);
    const isFormalExpressionExist = bo.eventDefinitions[0]?.timeCycle;
    if (isFormalExpressionExist) {
      let eventDefinition = bo.eventDefinitions[0];
      eventDefinition.timeCycle.body = cronstring;

      delete eventDefinition["timeDate"];
      delete eventDefinition["timeDuration"];
      modeling.updateLabel(element, cronstrue.toString(cronstring, { locale }));
      modeler.get<any>("modeling").updateProperties(element, {
        eventDefinitions: [eventDefinition],
      });
    } else {
      let formalExpression = moddle.create("bpmn:FormalExpression", {
        body: cronstring,
      });

      let eventDefinition = bo.eventDefinitions[0];

      delete eventDefinition["timeDate"];
      delete eventDefinition["timeDuration"];

      eventDefinition["timeCycle"] = formalExpression;
      modeler.get<any>("modeling").updateProperties(element, {
        eventDefinitions: [eventDefinition],
      });
    }
    sendSnack({
      message: getTranslation(translations, "pm.cycle.save_message"),
      type: "info",
      autoClose: 5000,
    });
    onClose();
  };
  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={onClose}>
      <DialogHeader onClose={onClose}>
        {getTranslation(
          translations,
          "pm.propertyeditor.specificproperties.repetition_details"
        )}
      </DialogHeader>
      <DialogContent>
        <Typography>{cronstrue.toString(cronstring, { locale })}</Typography>
        <Stack
          direction="row"
          gap={1}
          flexWrap={"wrap"}
          flexBasis={"100%"}
          spacing={1}
          alignItems="center"
        >
          <span>{getTranslation(translations, "pm.cycle.every")}</span>
          <SelectCrondata
            value={crondata.every}
            onChange={({ target }) =>
              setCrondata({
                ...getDefaultByEvery(target.value as unknown as EverySupported),
                every: target.value as unknown as EverySupported,
              })
            }
            options={[
              {
                value: EverySupported.year as EverySupported,
                label: getTranslation(translations, "generic.year.caption"),
                key: "year",
              },
              {
                value: EverySupported.month as EverySupported,
                label: getTranslation(translations, "generic.month.caption"),
                key: "month",
              },
              {
                value: EverySupported.week as EverySupported,
                label: getTranslation(translations, "generic.week.caption"),
                key: "week",
              },
              {
                value: EverySupported.day as EverySupported,
                label: getTranslation(translations, "generic.day.caption"),
                key: "day",
              },
              {
                value: EverySupported.hour as EverySupported,
                label: getTranslation(translations, "generic.hour.caption"),
                key: "hour",
              },
              {
                value: EverySupported.minute as EverySupported,
                label: getTranslation(translations, "generic.minute.caption"),
                key: "minute",
              },
            ]}
          />
          {[EverySupported.year].includes(crondata.every) && (
            <React.Fragment>
              <span>{getTranslation(translations, "pm.cycle.in")}</span>
              <SelectCrondata
                value={crondata.months}
                multiple
                onChange={({ target }) =>
                  setCrondata(prepareUpdateState(true, target.value, "months"))
                }
                options={MONTHS.map(({ name, value }) => ({
                  key: String(value),
                  value,
                  label: getTranslation(
                    translations,
                    `pm.propertyeditor.specificproperties.repetition_details.cycle.label.monthly.${name}`
                  ),
                }))}
              />
            </React.Fragment>
          )}
          {[EverySupported.year, EverySupported.month].includes(
            crondata.every
          ) && (
            <React.Fragment>
              <span>{getTranslation(translations, "pm.cycle.on")}</span>
              <SelectCrondata
                value={crondata.daysOfWeek}
                multiple
                onChange={({ target }) =>
                  setCrondata(
                    prepareUpdateState(true, target.value, "daysOfWeek")
                  )
                }
                options={DAYS.map(({ name, value }) => ({
                  key: String(value),
                  value,
                  label: getTranslation(
                    translations,
                    `pm.propertyeditor.specificproperties.repetition_details.label_weeks.in_the_days.dayoftheweek.${name}`
                  ),
                }))}
              />
            </React.Fragment>
          )}
          {[
            EverySupported.year,
            EverySupported.month,
            EverySupported.week,
          ].includes(crondata.every) && (
            <React.Fragment>
              <span>{getTranslation(translations, "pm.cycle.and")}</span>
              <SelectCrondata
                value={crondata.days}
                multiple
                onChange={({ target }) =>
                  setCrondata(prepareUpdateState(true, target.value, "days"))
                }
                options={range(1, 32).map((n) => ({
                  key: String(n),
                  value: n,
                  label: String(n),
                }))}
              />
            </React.Fragment>
          )}
          {[
            EverySupported.year,
            EverySupported.month,
            EverySupported.week,
            EverySupported.day,
          ].includes(crondata.every) && (
            <React.Fragment>
              <span>{getTranslation(translations, "pm.cycle.at")}</span>
              <SelectCrondata
                value={crondata.hours}
                multiple
                onChange={({ target }) =>
                  setCrondata(prepareUpdateState(true, target.value, "hours"))
                }
                options={range(0, 24).map((n) => ({
                  key: String(n),
                  value: n,
                  label: String(n),
                }))}
              />
            </React.Fragment>
          )}
          {[
            EverySupported.year,
            EverySupported.month,
            EverySupported.week,
            EverySupported.day,
            EverySupported.hour,
          ].includes(crondata.every) && (
            <React.Fragment>
              <span>:</span>
              <SelectCrondata
                value={crondata.minutes}
                multiple
                onChange={({ target }) =>
                  setCrondata(
                    prepareUpdateState(true, target.value || [], "minutes")
                  )
                }
                options={range(0, 60).map((n) => ({
                  key: String(n),
                  value: n,
                  label: String(n),
                }))}
              />
            </React.Fragment>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {getTranslation(translations, "generic.button.cancel")}
        </Button>
        <Button onClick={handleSave}>
          {getTranslation(translations, "pm.button.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TimerDefinitionSettings;
