import React from 'react';
import Editor from './Editor';

interface InputCommentProps {
  onChange?: (value: string) => void;
  value: string;
  readonly?: boolean;
}

const InputComment: React.FC<InputCommentProps> = ({
  onChange,
  value,
  readonly,
}) => {
  return (
    <Editor
        value={value}
        onChange={onChange}
        readonly={readonly}
    />
  )
}

export default InputComment;


