import {
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
} from "@mui/material";
import React from "react";
import { UsersOfSelectedOrganization } from "../NewProcessModule";
import { useDragItem } from "hooks";
import AvatarRem from "components/AvatarRem";

interface UserAssociationItemProps {
  selected: boolean;
  ele: UsersOfSelectedOrganization;
  getUsername: (ele: UsersOfSelectedOrganization) => string;
  onLaneMouseDown: any;
  selectedLane: string;
}
const UserAssociationItem: React.FC<UserAssociationItemProps> = ({
  selected,
  ele,
  getUsername,
  onLaneMouseDown,
  selectedLane,
}) => {
  const { dragging, onDrag, onDragEnd, onDragStart, ref } = useDragItem();
  return (
    <Paper
      ref={ref as any}
      draggable={true}
      onDragStart={(e) =>
        onDragStart(e, (event: any) => {
          const dragData = {
              name: getUsername(ele),
              idparticipant: ele.id,
              idparticipanttype: 3,
              assignmentmode: 0,
            };

          event.dataTransfer.setData("application/json", JSON.stringify(dragData));
        })
      }
      onDrag={onDrag}
      onDragEnd={onDragEnd}
      sx={{
        // width: "100%",
        my: 1,
        backgroundColor: dragging
          ? (_) => _.palette.background.paper
          : "transparent",
      }}
      elevation={dragging ? 5 : 0}
    >
      <ListItemButton
        disableGutters
        selected={selected}
        id={`users${ele.id}`}
        onDoubleClick={(e) => {
          if (Boolean(selectedLane)) {
            onLaneMouseDown(selectedLane, {
              name: getUsername(ele),
              idparticipant: ele.id,
              idparticipanttype: 3,
              assignmentmode: 0,
            });
          }
        }}
      >
        <ListItemAvatar
          onDragStart={(e) => e.stopPropagation()}
          draggable={false}
        >
          <AvatarRem icon={ele?.picture || ""}/>
        </ListItemAvatar>
        <ListItemText>{getUsername(ele)}</ListItemText>
      </ListItemButton>
    </Paper>
  );
};

export default UserAssociationItem;
