import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  Button,
  DialogActions,
} from "@mui/material";
import { getTranslation } from "common";
import { useTranslations } from "hooks";
import DialogHeader from "components/dialogs/DialogHeader";

interface SaveProcessModelPopUpTypes {
  shouldShowSaveModelPopup: boolean;
  setShouldShowSaveModelPopup: React.Dispatch<React.SetStateAction<boolean>>;
  saveXml: (func: Function) => void;
  processModelName: string;
}

const SaveProcessModelPopUp = ({
  shouldShowSaveModelPopup,
  setShouldShowSaveModelPopup,
  saveXml,
  processModelName,
}: SaveProcessModelPopUpTypes) => {
  const navigate = useNavigate();

  const translations = useTranslations();
  return (
    <Dialog
      open={shouldShowSaveModelPopup}
      onClose={() => {
        setShouldShowSaveModelPopup(false);
      }}
      fullWidth={true}
      maxWidth="xs"
    >
      <DialogHeader
        onClose={() => {
          setShouldShowSaveModelPopup(false);
        }}
      >
        {processModelName
          ? processModelName
          : getTranslation(translations, "pm.dialog.save.header")}
      </DialogHeader>

      <DialogContent>
        {getTranslation(translations, "pm.dialog.save.body")}
      </DialogContent>
      <DialogActions>
        <Button
          id="leave-process-model-btn"
          size="small"
          variant="contained"
          onClick={() => {
            setShouldShowSaveModelPopup(false);
            navigate(-1);
          }}
        >
          {getTranslation(translations, "generic.leavewithoutsave")}
        </Button>
        <Button
          size="small"
          variant="contained"
          onClick={() =>
            saveXml(() => {
              navigate(-1);
            })
          }
        >
          {getTranslation(translations, "pm.dialog.save.button.ok.label")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveProcessModelPopUp;
