//Colors for component CreateLabel
export const COLORS = [
    "#000000",  "#FFC0CB",   "#808080",  "#C0C0C0", "#800000",   "#FF0000", 
    "#800080", "#FF00FF", "#008000", "#00FF00",   "#808000",    "#FFFF00", 
    "#000080",   "#0000FF",    "#008080",  "#00ffff", "#7fffd4", "#90ee90"
    // Tonalità di verde
    // "#4bce97", "#71d7a8", "#91dfb9", "#aee8ca", "#caf0db", "#e4f7ed",
    // Tonalità di giallo
    // "#f5cd47", "#fad569", "#fddd88", "#ffe5a6", "#ffeec3", "#fff6e1",
    // Tonalità di arancione
    // "#fea362", "#ffb27c", "#ffc195", "#ffd1af", "#ffe0c9", "#ffefe4",
    // Tonalità di rosso
    // "#f87168", "#fd8a80", "#ffa298", "#ffbab1", "#ffd1ca", "#ffe8e4",
    // Tonalità di viola
    // "#9f8fef", "#b0a1f2", "#c1b3f5", "#d1c6f8", "#e1d9fa", "#f0ecfd",
];

//Colors for component LabelManager
export const SUGGESTED_LABELS = ["#CAE4D9", "#FEFCCB", "#F6AC82"];