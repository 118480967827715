import { useEffect, useState } from "react";
import { organizationsApi } from "api";

export interface Priority {
  id: number | string;
  new?: true;
  deleted?: true;
  idorder: number;
  candelete: boolean;
  idorganization: number;
  name: string;
}
const usePriorities = (
  idOrganization: number | null
): [Priority[], boolean, React.Dispatch<React.SetStateAction<Priority[]>>] => {
  const [priorities, setPriorities] = useState<Priority[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (idOrganization) {
      (async () => {
        try {
          setLoading(true);
          const { data } = await organizationsApi.get(
            `/${idOrganization}/priorities`
          );
          setPriorities(data.priorities);
          setLoading(false);
        }
        catch { }
        finally {
          setLoading(false);
        }
      })();
    }
  }, [idOrganization]);

  return [priorities, loading, setPriorities];
};

export default usePriorities;
