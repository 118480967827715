import { Checkbox, Stack } from "@mui/material";
import { getTranslation } from "common";
import { useTranslations } from "hooks";
import React, { useState } from "react";

interface AdminCheckboxProps {
  initialChecked: boolean;
  onChange: (checked: boolean) => Promise<void>;
}

const AdminCheckbox: React.FC<AdminCheckboxProps> = ({
  onChange,
  initialChecked,
}) => {
  const translations = useTranslations();
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Checkbox
        disabled={loading}
        checked={initialChecked || false}
        onClick={(e) => e.stopPropagation()}
        onChange={async (e, _checked) => {
          e.stopPropagation();
          setLoading(true);
          e.preventDefault();
          try {
            await onChange(_checked);
          } catch {
          } finally {
            setLoading(false);
          }
        }}
      />
      {getTranslation(translations, "organization.check.admin.label")}
    </Stack>
  );
};

export default AdminCheckbox;
