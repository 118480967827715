import { createSlice } from "@reduxjs/toolkit";
import { CONSTANTS } from "common";
import organizationModel, { ModelType, SelectedOrganizationType } from 'model/organizationModel';

const initialState: organizationModel = {
    id: 0,
    name: "",
    listOfModels: [],
    organizations: [],
    selectedOrganization: null
}

export const messageSlice = createSlice({
    name: "organization",
    initialState,
    reducers: {
        addOrganization: (state, action) => {
            state.id = action.payload.id;
            state.name = action.payload.name;
        },
        saveBpmnDiagram: (state, action) => {
            // state.bpmn = action.payload.bpmn
        },
        addProcessModel: (state, action) => {
            const { newModel } = action.payload
            const updatedList = [...state.listOfModels]
            updatedList.push(newModel)
            state.listOfModels = [...updatedList]
        },
        updateProcessModel: (state, action) => {

            const { updatedModel } = action.payload

            const updatedList = [...state.listOfModels]

            const index = updatedList.findIndex((model: ModelType) => {
                return model.id === updatedModel.id
            })

            updatedList.splice(index, 1, updatedModel)

            state.listOfModels = [...updatedList]
        },
        deleteProcessModel: (state, action) => {
            const { index, id } = action.payload
            const updatedModels = [...state.listOfModels]

            if (updatedModels[index].id === id) {
                updatedModels.splice(index, 1)
            }
            state.listOfModels = [...updatedModels]
        },
        addOrganizations: (state, action) => {
            const { list } = action.payload

            state.organizations = list
        },
        addSelectedOrganization: (state, action) => {
            const { id } = action.payload
            const organizationsList: Array<SelectedOrganizationType> = [...state.organizations];
            localStorage.setItem(CONSTANTS.STORE_SELECTED_ORGANIZATION_ID, id);
            const organization = organizationsList.find(org => org.id === id)
            state.selectedOrganization = organization ? organization : state.selectedOrganization
        },
        selectDefaultOrganization: (state, action) => {
            const organizationsList: Array<SelectedOrganizationType> = [...state.organizations];
            if (organizationsList.length >= 1) {
                const organization = organizationsList[0]
                state.selectedOrganization = organization
            }
        },
        removeSelectedOrganization: (state, action) => {
            localStorage.removeItem(CONSTANTS.STORE_SELECTED_ORGANIZATION_ID);
            state.selectedOrganization = null
        }
    }
})

export default messageSlice.reducer;
export const { addOrganization, saveBpmnDiagram, updateProcessModel, addProcessModel, deleteProcessModel, addOrganizations, addSelectedOrganization, selectDefaultOrganization, removeSelectedOrganization } = messageSlice.actions;

