import React, { useEffect, useState } from "react";
import { useSnackbar, useTranslations } from "hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Typography,
  CircularProgress,
} from "@mui/material";
import { getTranslation } from "common";
import { noTokenApi } from "api";

const MAX_COUNTER = 5;

const Invitation = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const translations = useTranslations();
  const jwt = searchParams.get("jwt");
  const idOrg = searchParams.get("idorganization");
  const [loaded, setLoaded] = useState(false);
  const [counter, setCounter] = useState(MAX_COUNTER);
  const { sendSnack } = useSnackbar();

  useEffect(() => {
    let interval: NodeJS.Timeout;
    (async () => {
      if (idOrg && jwt) {
        try {
          if (isNaN(Number(idOrg))) {
            sendSnack({
              type: "error",
              message: getTranslation(
                translations,
                "generic.something_went_wrong.caption"
              ),
            });
          } else {
            await noTokenApi.post(`/users/${jwt}/acceptinvitation`, {
              idorganization: idOrg,
            });
            setLoaded(true);
            interval = setInterval(() => {
              setCounter((_counter) => _counter - 1);
            }, 1000);
          }
        } catch {
          navigate("/");
        }
      } else {
        navigate("/");
      }
    })();
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (counter <= 0) {
      navigate("/login");
    }
  }, [counter, navigate]);

  return (
    <Box width="100%" height="100%">
      <Container
        maxWidth="sm"
        sx={{
          justifyContent: "center",
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              loaded
                ? getTranslation(translations, "application.redirect.label")
                : getTranslation(translations, "register.label.welcome_button")
            }
          />
          <CardContent
            sx={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loaded ? (
              <React.Fragment>
                <CircularProgress
                  variant="determinate"
                  value={(counter * 100) / MAX_COUNTER}
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 5,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                  >
                    {counter}
                  </Typography>
                </Box>
              </React.Fragment>
            ) : (
              <CircularProgress />
            )}
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default Invitation;
