import * as React from "react";
import Button from "@mui/material/Button";
import { ArrowForwardIos } from "@mui/icons-material";
import { SequenceFlow, Task } from "model/activitiesModel";

export interface TaskCardButtonDialogBoxProps {
  open: boolean;
  onClose: (value: string) => void;
  task: Task;
  handleSequenceFlowChoice?: (task: Task, sequenceFlow: SequenceFlow) => void;
}
export interface TaskCardButtonDialogProps {
  task: Task;
  handleSequenceFlowChoice?: (task: Task, sequenceFlow: SequenceFlow) => void;
}

const TaskCardButtonDialogBox: React.FC<TaskCardButtonDialogBoxProps> = ({
  onClose,
  open,
  task,
  handleSequenceFlowChoice,
}) => {
  return (
    // <List sx={{ pt: 0 }}>
    <React.Fragment>
      {task.sequenceflows?.map((sequenceFlow) => (
        <Button
          variant="outlined"
          size="small"
          endIcon={<ArrowForwardIos />}
          key={`sequence-flow-${sequenceFlow.id}`}
          onClick={() =>
            handleSequenceFlowChoice &&
            handleSequenceFlowChoice(task, sequenceFlow)
          }
        >
          {sequenceFlow.name}
        </Button>
      ))}
    </React.Fragment>
    // </List>
  );
};

export const TaskCardButtonDialog: React.FC<TaskCardButtonDialogProps> = ({
  task,
  handleSequenceFlowChoice,
}) => {
  const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = (value: string) => {
    setOpen(false);
  };
  // const translations = useTranslations();
  /* <Button variant="contained" onClick={handleClickOpen} size="small">
    {getTranslation(translations, "process.action.next")}
  </Button> */
  return (
    <TaskCardButtonDialogBox
      open={open}
      onClose={handleClose}
      task={task}
      handleSequenceFlowChoice={handleSequenceFlowChoice}
    />
  );
};
