import { useEffect, useMemo, useState } from 'react'

export enum SortState { None, Asc, Desc }; 

const useSortableList = <T>(_list: Array<T>, property: keyof T, sort: SortState) => {
    const [sortedList, setSortedList] = useState<Array<T>>([]);
    const list = useMemo(() => _list, [_list]);
    useEffect(() => {
        if (sort === SortState.Asc) {
          setSortedList(
            [...list].sort((a, b) =>
              (a[property] as string).localeCompare(b[property] as string)
            )
          );
        }
        if (sort === SortState.Desc) {
            setSortedList(
            [...list].sort((a, b) =>
              (b[property] as string).localeCompare(a[property] as string)
            )
          );
        }
        if (sort === SortState.None) {
          setSortedList([...list]);
        }
      }, [sort, list, property]);
    
  return sortedList;
}

export default useSortableList