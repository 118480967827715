import React from "react";
import { ArrowDownward, ArrowUpward, Sort } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { HeaderCellProps } from "./types";

const RenderHeader = <T extends unknown>(props: HeaderCellProps<T>) => {
  const isSortable = props.column.sortable;
  return (
    <Box
      sx={{
        backgroundColor: (_) => _.palette.background.default,
        height: "40px",
        borderRadius: 0,
      }}
    >
      <Stack
        sx={{ height: "100%" }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        px={1}
        spacing={2}
      >
        <Typography
          sx={{ fontWeight: "bold", color: (_) => _.palette.text.primary }}
        >
          {props.column.name}
        </Typography>
        {isSortable && (
          <IconButton onClick={() => props.onSort()} size="small">
            {!props.sortDirection && <Sort fontSize="small" />}
            {props.sortDirection === "ASC" && <ArrowUpward fontSize="small" />}
            {props.sortDirection === "DESC" && (
              <ArrowDownward fontSize="small" />
            )}
          </IconButton>
        )}
      </Stack>
    </Box>
  );
};

export default RenderHeader;
