import React from 'react'
import { RowProps } from './types'
import { Box } from '@mui/material'
import DefaultRenderCell from './RenderCell';
const Row = <T extends unknown>({ pl, row, columns, keyGetter }: RowProps<T>) => {
  return (
    <React.Fragment>
        {columns.map((column, idx) => {
            const RenderCell = column.RenderCell ? column.RenderCell : DefaultRenderCell;
            return <Box pl={pl && idx === 0 ? pl : 0} key={`row-${keyGetter(row)}-column-${String(column.key)}`} component="td">
                <RenderCell
                    row={row} 
                    column={column} 
                    value={String(row[column.key as keyof T])}
                />
            </Box>
        })}
    </React.Fragment>
  )
}

export default Row