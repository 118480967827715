import React from "react";
import { FormControl, FormControlLabel, Checkbox, Stack, Tooltip } from "@mui/material";
import { Priority } from "hooks/usePriorities";

interface PrioritySearchSelectProps {
  priorities: Priority[];
  selectedPriorities: string[];
  setSelectedPriorities: React.Dispatch<React.SetStateAction<string[]>>;
}

const PrioritySearchSelect: React.FC<PrioritySearchSelectProps> = ({
  priorities,
  selectedPriorities,
  setSelectedPriorities,
}) => {
  const togglePriority = (name: string) => {
    const currentIndex = selectedPriorities.indexOf(name);
    const newSelected = [...selectedPriorities];

    if (currentIndex === -1) {
      newSelected.push(name);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelectedPriorities(newSelected);
  };

  return (
    <FormControl fullWidth>
      <Stack direction="column">
        {priorities.map((priority) => (
          <Tooltip title={priority.name} key={priority.id}>
            <FormControlLabel
              slotProps={{
                typography: {
                  sx: {
                    maxWidth: 150,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  },
                },
              }}
              control={
                <Checkbox
                  id={`priorities-filter-${priority.id}`}
                  checked={selectedPriorities.includes(priority.name)}
                  onChange={() => togglePriority(priority.name)}
                  color="primary"
                />
              }
              label={priority.name}
            />
          </Tooltip>
        ))}
      </Stack>
    </FormControl>
  );
};

export default PrioritySearchSelect;
