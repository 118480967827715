import { combineReducers, configureStore } from "@reduxjs/toolkit";
// import { combineReducers, configureStore, createStore } from "@reduxjs/toolkit";

import snackbarReducer from "Redux/snackbarReducer";
import messageReducer from "Redux/messageReducer";
import organizationReducer from "Redux/organizationReducer";
import processReducer from "Redux/processReducer";
import priorityReducer from "Redux/priorityReducer"
import storage from "redux-persist/lib/storage";


import { persistReducer } from "redux-persist";


const persistConfig = {
    key: 'root',
    storage: storage,
}


const rootReducer = combineReducers({
    message: messageReducer,
    organization: organizationReducer,
    process: processReducer,
    snackbar: snackbarReducer,
    priority: priorityReducer,

})

const persisted = persistReducer(persistConfig, rootReducer)
const store = configureStore({
    reducer: persisted,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }),
})

export default store;
export type RootState = ReturnType<typeof store.getState>
