import axios from 'axios';
import jwtInterceptor from './jwtInterceptor';
import errorInterceptor from './errorInterceptor';

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_BASE_URI}uploads`,
    headers: {
        "Content-Type": "application/json"
    }
});

instance.interceptors.request.use(jwtInterceptor);
instance.interceptors.response.use(undefined, errorInterceptor);

export default instance;