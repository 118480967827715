import React, { useEffect, useMemo, useState } from "react";
import { LabelData, LabelDataWithOptionalId } from "./label_Interface";
import { Grid, Stack, TextField } from "@mui/material";
import { Label } from "./Label";
import { CheckRounded } from "@mui/icons-material";
import { COLORS } from "./colors";
import { getTranslation } from "common";
import { useTranslations } from "hooks";


interface LabelFormProps {
  defaultLabel?: LabelData;
  onChange: (label: LabelDataWithOptionalId) => void;
}

const LabelForm: React.FC<LabelFormProps> = ({ defaultLabel, onChange }) => {
  const translations = useTranslations();
  const randomColor = useMemo(
    () => COLORS[Math.floor(Math.random() * COLORS.length)],
    []
  );
  const [color, setColor] = useState(defaultLabel?.css || randomColor);
  const [name, setName] = useState(defaultLabel?.name || "");

  useEffect(() => {
    onChange({
        id: defaultLabel?.id,
        css: color,
        name
    })
  }, [color, defaultLabel?.id, name, onChange]);

  return (
    <Stack spacing={1}>
      <Stack justifyContent="center" alignItems="center" p={3}>
        <Label labelCompleteMode color={color} name={name} />
      </Stack>
      <TextField
        onKeyDown={e => e.stopPropagation()}
        size="small"
        value={name}
        onChange={(e) => setName(e.target.value)}
        label={getTranslation(translations, "generic.title.caption")}
      />
      <Grid container>
        {COLORS.map((_color) => (
          <React.Fragment key={_color}>
            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                heigth: "auto",
                aspectRatio: "1 / 1",
                bgcolor: _color,
                "&:hover": {
                  cursor: "pointer",
                  opacity: 0.75,
                },
                position: "relative",
              }}
              onClick={() => setColor(_color)}
            >
              {color === _color && (
                <CheckRounded
                  color="info"
                  sx={{
                    color: (_) => _.palette.getContrastText(color),
                  }}
                />
              )}
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Stack>
  );
};

export default LabelForm;
