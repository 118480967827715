import React, { ReactNode } from "react";
import { Checkbox, MenuItem, TextField, Typography } from "@mui/material";

type SelectChangeEvent<T> = Event & { target: { value: T; name: string } };

type SelectCrondataProps<G, T extends string | number | undefined> = {
  multiple?: G;
  value: G extends true ? T[] : T;
  onChange: (
    e: SelectChangeEvent<G extends true ? T[] : T>,
    child: ReactNode
  ) => void;
  options: {
    label: string;
    key: string;
    value: T;
  }[];
};

const SelectCrondata = <
  G extends boolean | undefined,
  T extends string | number | undefined
>({
  value,
  onChange,
  options,
  multiple = false,
}: SelectCrondataProps<G, T>) => {
  return (
    <TextField
      sx={{ minWidth: 100 }}
      variant="standard"
      SelectProps={{
        multiple,
        value,
        onChange: onChange as any,
        renderValue: (_value) => (
          <Typography>
            {options
              .filter(({ value }) =>
                [...((multiple ? _value : [_value]) as T[])].includes(value)
              )
              ?.map(({ label }) => label).join(", ")}
          </Typography>
        ),
      }}
      size="small"
      select
    >
      {options.map(({ label, value: _value, key }) => (
        <MenuItem
          key={key}
          value={_value as string | number | readonly string[] | undefined}
        >
          {multiple && (
            <Checkbox
              checked={(value as unknown as T[]).indexOf(_value) > -1}
            />
          )}
          {label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default SelectCrondata;
