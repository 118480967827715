import { createSlice } from "@reduxjs/toolkit";
import SnackbarModel from "model/snackbarModel";
import { create_UUID } from "common";

const initialState: Array<SnackbarModel> = [];

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    deleteSnackbarById: (state, { payload }: { payload: string }) => {
        return [...state.filter(({ id }) => id !== payload )];
    },
    addSnackbar: (state, { payload }: { payload: SnackbarModel }) => {
        const autoClose = payload.autoClose ?? 3000;
        return [...state, { ...payload, autoClose, id: create_UUID() }];
    },
    addInfo: (state, action) => {
        return [...state, { type: "info", message: action.payload }];
    },
    addSuccess: (state, action) => {
        return [...state, { type: "success", message: action.payload }];
    },
    addError: (state, action) => {
        return [...state, { type: "error", message: action.payload }];
    },
  },
});

export default snackbarSlice.reducer;
export const { deleteSnackbarById, addSnackbar, addError, addInfo, addSuccess } = snackbarSlice.actions;
