import React, { useEffect, useState, forwardRef, useCallback, useImperativeHandle } from 'react';
import { organizationsApi, robotsApi } from "api";
import { Robot } from "hooks/useRobots";
import {
    List,
    ListItemAvatar,
    ListItemText,
    CircularProgress,
    Stack,
    Divider,
    Typography,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Box,
    Tooltip,
    ListItem,
} from '@mui/material';
import { Clear, PrecisionManufacturing, Info, Email, ErrorOutline } from '@mui/icons-material';
import AvatarRem from './AvatarRem';
import { getTranslation, OutletType } from 'common';
import { useTranslations, useUser } from 'hooks';
import RobotDetails from './RobotDetails';
import { useOutletContext } from 'react-router-dom';
import { useSnackbar } from 'hooks';

interface RobotsPanelProps {
    organizationId: number;
    administrator: boolean;
}
interface Category {
    id: number;
    description: string;
}

type Ref = { getRobots: Function } | undefined;

const RobotsPanel = forwardRef<Ref, RobotsPanelProps>(({ organizationId, administrator }, ref) => {
    const { secondFilter: robotsFilter } = useOutletContext<OutletType>();
    // console.log(robotsFilter)
    const [robots, setRobots] = useState<Robot[]>([]);
    const [loading, setLoading] = useState(false);
    const [robotToRemove, setRobotToRemove] = useState<Robot | undefined>(undefined);
    const [selectedRobot, setSelectedRobot] = useState<Robot | undefined>(undefined);
    const [detailsOpen, setDetailsOpen] = useState(false);
    const translations = useTranslations();
    const [categories, setCategories] = useState<Category[]>([]);
    const { sendSnack } = useSnackbar();
    const user = useUser();

    const getCategoryDescription = (id: number) => {
        const category = categories.find(category => category.id === id);
        return category ? category.description : '';
    };

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const { data } = await robotsApi.get('/categories');
                if (data) {
                    setCategories(data.categories);
                }
            } catch {
                setCategories([]);
            }
        };
        fetchCategories();
    }, []);

    const fetchRobots = useCallback(async () => {
        setLoading(true);
        try {
            const { data } = await organizationsApi.get(`/${organizationId}/robots`);
            setRobots(data.robots);
            //  console.log(data.robots)
        } catch { }
        finally {
            setLoading(false);
        }
    }, [organizationId]);

    useEffect(() => {
        fetchRobots();
    }, [fetchRobots])

    const handleRemoveRobot = async () => {
        if (robotToRemove) {
            try {
              const response =  await organizationsApi.delete(`/${organizationId}/robots/${robotToRemove.id}`);
                setRobots((prevRobots) => prevRobots.filter(robot => robot.id !== robotToRemove.id));
                sendSnack({
                    message: response.data.responsemessage,
                    type: 'success',
                });
                handleCloseDialog();
            } catch { }
        }
    };

    const handleOpenDialog = (robot: Robot) => {
        setRobotToRemove(robot);
    };

    const handleCloseDialog = () => {
        setRobotToRemove(undefined);
    };

    const handleOpenDetails = (robot: Robot) => {
        setSelectedRobot(robot);
        setDetailsOpen(true);
    };

    const handleCloseDetails = () => {
        setDetailsOpen(false);
        setSelectedRobot(undefined);
    };

    useImperativeHandle(
        ref,
        () => ({
            getRobots: fetchRobots,
        }),
        [fetchRobots]
    );

    // useEffect(() => {
    //     fetchRobots();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [organizationId]);

    return (
        <Stack height="100%" overflow="auto" flex={1}>
            <Typography variant="subtitle1" sx={{ padding: 1 }}>
                {getTranslation(translations, "robots.name")}
            </Typography>
            <Divider />
            {loading ? (
                <Stack alignItems="center">
                    <CircularProgress />
                </Stack>
            ) : (
                <List sx={{ overflow: 'auto', maxHeight: 'calc(100% - 50px)', maxWidth: '100%' }} disablePadding>
                    {robots.filter((robot) => robot.name.toLowerCase().includes(robotsFilter.toLowerCase())).map((robot) => (
                        <ListItem
                            key={robot.id}
                            // selected={selectedId === robot.id}
                            // onClick={() => onSelect(robot.id)}
                            divider
                        >
                            <ListItemAvatar>
                                <AvatarRem icon={robot.picture || ""}>
                                    <PrecisionManufacturing />
                                </AvatarRem>
                            </ListItemAvatar>
                            <Box display="flex" flexDirection="column" flexGrow={1}>
                                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                                    <ListItemText
                                        primary={robot.name}
                                        secondary={robot.description || ""}
                                        primaryTypographyProps={{ sx: { fontWeight: "bold", maxWidth: 250, textOverflow: 'ellipsis', textWrap: 'nowrap', overflow: "hidden", marginRight: 10 } }}
                                        secondaryTypographyProps={{ sx: { maxWidth: 250, textOverflow: 'ellipsis', textWrap: 'nowrap', overflow: "hidden" } }} />
                                    {robot.published === false && robot.user?.id !== user?.userId && (
                                        <Tooltip title={getTranslation(translations, "robot.deprecated")}>
                                            <ErrorOutline fontSize="small" color="warning" />
                                        </Tooltip>
                                    )}
                                    <Tooltip title={getTranslation(translations, "robot.details.tooltip")}>
                                        <IconButton
                                        id='robot-info-icon'
                                            size="small"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleOpenDetails(robot);
                                            }}
                                        >
                                            <Info fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    {administrator && (
                                        <Tooltip title={getTranslation(translations, "generic.remove")}>
                                            <IconButton
                                                size='small'
                                                edge="end"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleOpenDialog(robot);
                                                }}
                                            >
                                                <Clear />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </Box>
                                <ListItemText>{robot.idcategory ? `${getCategoryDescription(robot.idcategory)}` : ""}</ListItemText>
                                <Box display="flex" flexDirection="row" alignItems="center" mr={1}>
                                    <ListItemText
                                        primary={(robot.user?.firstname || '') + " " + (robot.user?.lastname || '')}

                                    />
                                </Box>
                                <Box display="flex" flexDirection="row" alignItems="center" mr={1}>
                                    <IconButton disableRipple sx={{ p: 0, m: 0, cursor: "default" }}>
                                        <Email fontSize="small" sx={{ mr: 1 }} />
                                    </IconButton>
                                    {robot.user?.email || ''}
                                </Box>
                            </Box>
                        </ListItem>
                    ))}
                </List>
            )}

            {/* Dialog di conferma */}
            <Dialog
                open={!!robotToRemove}
                onClose={handleCloseDialog}
            >
                <DialogTitle>
                    {getTranslation(translations, "confirm.remove.robot.title")}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>
                        {getTranslation(translations, "generic.button.cancel")}
                    </Button>
                    <Button onClick={handleRemoveRobot} color="error" autoFocus>
                        {getTranslation(translations, "generic.remove")}
                    </Button>
                </DialogActions>
            </Dialog>

            <RobotDetails
                open={detailsOpen}
                onClose={handleCloseDetails}
                robot={selectedRobot}
                onRobotUpdate={fetchRobots}
            />
        </Stack>
    );
});

export default RobotsPanel;
