import { useCallback, useEffect, useState } from "react";
import { Task } from "model/activitiesModel";
import { getUserData } from "common/utilities";

export enum SupportedUserDataTypes {
  string,
  number,
  date
}

export interface UserData {
  value: string;
  inCard: boolean;
  type: SupportedUserDataTypes;
}

const useUserDataKeys = (tasks: {
  todo: Task[];
  doing: Task[];
}): [string[]] => {
  //l'hook torna un'array di stringhe
  const [keys, setKeys] = useState<string[]>([]);

  const getKeys = useCallback((task: Task) => {
    const userdata = getUserData(task.userdata);
    return Object.keys(userdata);
  }, []);

  useEffect(() => {
    if (tasks) {
      let keys: string[] = [];
      const filterKeys = (keys: string[]) => (key: string) => !keys.includes(key); 
      for (const task of tasks.doing) {
        const newKeys = getKeys(task).filter(filterKeys(keys))
        keys = [...keys, ...newKeys];
      }
      for (const task of tasks.todo) {
        keys = [...keys, ...getKeys(task).filter(filterKeys(keys))];
      }
      setKeys(keys);
    }
  }, [tasks, getKeys]);

  return [keys];
};

export default useUserDataKeys;
