import React, { useReducer } from "react";

export type Action = {
  type: string;
  payload?: any;
};

type Reducer<State> = (state: State, action: Action) => State;

type BaseAction<Parameters, Return> = (args: Parameters) => Return;
export type DispatchAction<Parameters, Return> = (
  dispatch: React.Dispatch<Action>
) => BaseAction<Parameters, Return>;
// type BaseActions = {
//     [key: string]: DispatchAction;
// };

// interface ContextProvided<State, Actions> {
//     state: State;
//     actions: Actions;
// };
interface ContextProvided {
  state: any;
  actions: any;
}

const _ = function <
  ContextType extends ContextProvided,
  State,
  BaseActions extends { [key: string]: DispatchAction<any, any> }
>
(
    reducer: Reducer<State>, 
    actions: BaseActions, 
    defaultValue: State
) {
  // type ProvidedActions = { [Property in keyof BaseActions]: ReturnType<BaseActions[Property]> };
  const Context = React.createContext<ContextType>(undefined as any);
  // type ProvidedActions = { [Property in keyof BaseActions]: ReturnType<BaseActions[Property]> };
  // const Context = React.createContext<ContextProvided<State, ProvidedActions>>(undefined as any);

  const Provider = ({ children }: { children: React.ReactNode }) => {
    const [state, dispatch] = useReducer(reducer, defaultValue);

    const boundActions: any = {};
    for (let key in actions) {
      let action: BaseActions[keyof BaseActions] = actions[key];
      boundActions[key] = action(dispatch);
    }
    const contextValues: any = { state, actions: boundActions };
    return (
      <Context.Provider value={contextValues}>{children}</Context.Provider>
    );
  };

  return { Context, Provider };
}

export default _;
