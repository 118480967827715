import axios from 'axios';
import errorInterceptor from './errorInterceptor';
import langInterceptor from './langInterceptor';

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_BASE_URI}`,
    headers: {
        "Content-Type": "application/json"
    }
});

instance.interceptors.request.use(langInterceptor);
instance.interceptors.response.use(undefined, errorInterceptor);

export default instance;