import React, { useEffect } from "react";
import ExampleTheme from "./themes/ExampleTheme";
import {
  InitialConfigType,
  LexicalComposer,
} from "@lexical/react/LexicalComposer";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import {
  ToolbarPlugin,
  AutoLinkPlugin,
  CodeHighlightPlugin,
  ListMaxIndentLevelPlugin,
} from "./plugins";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import {
  TRANSFORMERS,
  $convertFromMarkdownString,
  $convertToMarkdownString,
} from "@lexical/markdown";
import { Box, CardContent, Paper, Stack } from "@mui/material";
import { useTranslations, useVersions } from "hooks";
import { getTranslation } from "common";

const Placeholder = () => {
  const translations = useTranslations();
  return (
    <Box
      sx={{
        backgroundColor: "transparent",
        left: (_) => _.spacing(1),
        top: (_) => _.spacing(2),
        position: "absolute",
        color: "text.disabled",
        pointerEvents: "none",
      }}
    >
      {getTranslation(translations, "generic.entersometext.caption")}
    </Box>
  );
};

const editorConfig: InitialConfigType = {
  // The editor theme
  namespace: "comment-editor",
  theme: ExampleTheme,
  // Handling of errors during update
  onError: (error: any) => {
    throw error;
  },
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
  ],
};
interface EditorProps {
  value: string;
  onChange?: (value: string) => void;
  readonly?: boolean;
}

const Editor: React.FC<EditorProps> = ({ value, onChange, readonly }) => {
  const { getVersion, updateVersion } = useVersions();
  let paddingConf: any = {
    pl: 0,
    py: (_: any) => `${_.spacing(1)}!important`,
  };
  if (readonly) {
    paddingConf = { p: 0, pb: "0px!important" };
  }
  useEffect(() => {
    if (!value) {
      updateVersion(1);
    }
  }, [updateVersion, value]);

  return (
    <LexicalComposer
      key={getVersion(1)}
      initialConfig={{
        ...editorConfig,
        editable: !readonly,
        editorState: () => $convertFromMarkdownString(value),
      }}
    >
      <Stack
        component={Paper}
        sx={{
          boxShadow:
            "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
        }}
      >
        {!readonly && (
          <React.Fragment>
            <ToolbarPlugin value={value} />
          </React.Fragment>
        )}
        <CardContent
          sx={{
            position: "relative",
            ...paddingConf,
          }}
        >
          <RichTextPlugin
            contentEditable={
              <Box
                sx={{
                  p: 1,
                  pl: (_) => _.spacing(1),
                }}
              >
                <ContentEditable
                  id="startprocess-comment"
                  style={{
                    border: "none",
                    overflowY: "auto",
                    outline: "none",
                  }}
                />
              </Box>
            }
            placeholder={<Placeholder />}
            ErrorBoundary={LexicalErrorBoundary}
          />
        </CardContent>
      </Stack>
      <HistoryPlugin />
      <AutoFocusPlugin />
      <CodeHighlightPlugin />
      <ListPlugin />
      <LinkPlugin />
      <OnChangePlugin
        onChange={(editorState, editor) => {
          editorState.read(() => {
            const markdown = $convertToMarkdownString();
            onChange && onChange(markdown);
          });
        }}
      />
      <AutoLinkPlugin />
      <ListMaxIndentLevelPlugin maxDepth={7} />
      <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
    </LexicalComposer>
  );
};

export default Editor;
