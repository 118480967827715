import { processesApi, processesUploadsApi, uploadsApi } from "api";
import {
  SUPPORTED_EXTENSIONS,
  getFileExtension,
} from "common/utilities";
import { Attachment } from "components/AttachmentList";
import { BaseTask } from "model/activitiesModel";
import { useCallback, useState } from "react";

const useAttachments = (
  task: BaseTask | null
): [
  Attachment[],
  React.Dispatch<React.SetStateAction<Attachment[]>>,
  () => Promise<void>,
  { [key: string]: string },
  { [key: string]: any }
] => {
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [images, setImages] = useState<{ [key: string]: string }>({});
  const [histories, setHistories] = useState<{ [key: string]: any }>({});

  const download = async (attachment: Attachment) => {
    try {
      const { data } = await processesUploadsApi.get(`/${attachment.id}`);
      return data.blob;
    } catch {
    } finally {
    }
  };

  const history = async (attachment: Attachment) => {
    try {
      const { data } = await uploadsApi.get(`/${attachment.id}/histories`);
      return data.uploads;
    } catch {
    } finally {
    }
  };

  const getAttachments = useCallback(async () => {
    if (task) {
      try {
        const response = await processesApi.get(
          `${task.idprocess}/attachments/light`
        );
        const promises = [];
        const ids = [];
        const promises_histories = [];
        const ids_histories = [];
        for (let attachment of response.data.attachments) {
          if (
            SUPPORTED_EXTENSIONS.includes(
              getFileExtension(attachment.filename) || ""
            )
          ) {
            promises.push(download(attachment));
            ids.push(attachment.id);
          } else {
          }
          promises_histories.push(history(attachment));
          ids_histories.push(attachment.id);
        }
        const result = await Promise.all(promises);
        const result_histories = await Promise.all(promises_histories);
        setAttachments(() => [...response.data.attachments]);
        setImages(
          Object.fromEntries(ids.map((key, index) => [key, result[index]]))
        );
        setHistories(
          Object.fromEntries(
            ids_histories.map((key, index) => [key, result_histories[index]])
          )
        );
      } catch {
      } finally {
      }
    }
  }, [task]);

  return [attachments, setAttachments, getAttachments, images, histories];
};

export default useAttachments;
