import React, { useState } from "react";
import { GroupedRows as GroupedRowsType, GroupedRowsProps } from "./types";
import {
  Box,
  Stack,
  Typography,
} from "@mui/material";
import Row from "./Row";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const GroupedRows = <T extends unknown>({
  keyGetter,
  groupedRows,
  setSort,
  groupKeys,
  sort,
  groupKeysOriginal,
  columns,
  noRowsComponent,
}: GroupedRowsProps<T>) => {
  const key = groupKeys[0];
  const column = columns.find(_ => _.key === key);
  const RenderCell = column?.RenderCell;
  const [expanded, setExpanded] = useState<Record<string, boolean>>({});
  const pl = 2 * ((groupKeysOriginal?.length || 0) - (groupKeys?.length || 0))
  return (
    <React.Fragment>
      {groupKeys && groupKeys.length > 0 ? (
        <React.Fragment>
          {Object.keys(groupedRows).map((k) => (
            <React.Fragment key={`rowgroup-${k}`}>
              {Array.isArray(groupedRows[k]) && groupedRows[k].length === 1 ? (
                <Box
                  sx={{ 
                    pl,
                    width: "100%" 
                  }}
                  component="tr"
                >
                  <Row
                    pl={pl}
                    keyGetter={keyGetter}
                    columns={columns}
                    row={(groupedRows[k] as T[])[0]}
                  />
                </Box>
              ) : (
                <React.Fragment>
                    <Box sx={{ pl, minHeight: 40 }} component="tr">
                      <Box
                        component="td"
                        sx={{ minHeight: 40 }}
                        colSpan={columns.length}
                      >
                        <Stack
                          onClick={() =>
                            setExpanded((_) => ({ ..._, [k]: !_[k] }))
                          }
                          px={1}
                          minHeight={40}
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography sx={{  pl  }}>{RenderCell ? <RenderCell column={column} row={groupedRows[k] as T} value={k} /> : k}</Typography>
                          {expanded[k] ? <ExpandLess /> : <ExpandMore />}
                        </Stack>
                      </Box>
                    </Box>
                    {expanded[k] && 
                      <GroupedRows
                        columns={columns}
                        groupedRows={groupedRows[k] as GroupedRowsType<T>}
                        keyGetter={keyGetter}
                        setSort={setSort}
                        sort={sort}
                        groupKeysOriginal={groupKeysOriginal}
                        groupKeys={(() => {
                          const [, ...rest] = groupKeys;
                          return rest;
                        })()}
                      />
                    }
                </React.Fragment>
              )}
            </React.Fragment>
          ))}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {(groupedRows as any).map((row: T) => (
            <Box
              sx={{
                width: "100%",
                border: "1px solid orange !important",
                // backgroundColor: (_) => _.palette.background.paper,
              }}
              key={`row-${keyGetter(row)}`}
              component="tr"
            >
              <Row pl={pl} columns={columns} keyGetter={keyGetter} row={row} />
            </Box>
          ))}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default GroupedRows;
