import React, { useCallback, useRef } from "react";

const useInfiniteScroll = (setPage: React.Dispatch<React.SetStateAction<number>>, loading: boolean, hasMore: boolean) => {
  const observer = useRef<IntersectionObserver>();
  const lastElementRef = useCallback(
    (node: any) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prev: number) => {
            return prev + 1});
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, setPage, hasMore]
  );
  return lastElementRef;
};

export default useInfiniteScroll;
