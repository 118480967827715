import { useState, useEffect } from "react";

import PrimaryPropertiesPanel from "./PrimaryPropertiesPanel";
import SpecificPropertiesPanel from "./SpecificPropertiesPanel";

//@ts-ignore
import { getBusinessObject } from "bpmn-js/lib/util/ModelUtil";
//@ts-ignore
import BpmnModeler from "bpmn-js/lib/Modeler";
import {
  ProcessModelDataTypes,
} from "hooks/useBpmnModeler/useBpmnJsModeler";
import { parseISODurationString } from "../../../../../../utils/DateUtils";
// import "./PropertyPanelEditor.css";
import { Box, List } from "@mui/material";
import { ExtensionElementType } from "model/processModel";

interface ElementPropertiesTypes {
  modeler: BpmnModeler;
  element: any;
  processModelData: ProcessModelDataTypes;
  getRequestBodyForExtensionElements: () => Array<ExtensionElementType>;
  setProcessModelData: (data: ProcessModelDataTypes) => void;
  // getSelectedLanguage: () => string;
  openDiagram: (xml: string, shouldShowInTheViewPort: boolean, onSuccess?: () => void) => Promise<void>;
  // saveBtnRef: any;
  // publishBtnRef: any;

  primaryPropertiesNameRef: any;
}

function ElementProperties(props: ElementPropertiesTypes) {
  let {
    element,
    modeler,
    processModelData,
    getRequestBodyForExtensionElements,
    setProcessModelData,
    // getSelectedLanguage,
    openDiagram,
    // saveBtnRef,
    // publishBtnRef,
    primaryPropertiesNameRef,
  } = props;

  const [
    togglePrimaryPropertiesAccordian,
    setTogglePrimaryPropertiesAccordian,
  ] = useState<boolean>(true);
  const [
    toggleSpecificPropertiesAccordian,
    setToggleSpecificPropertiesAccordian,
  ] = useState<boolean>(true);
  const [timerType, setTimerType] = useState<string>("");
  const [timeDateValue, setTimeDateValue] = useState<Date | null>(null);
  const [timeDurationValue, setTimeDurationValue] = useState<Date | string>("");

  const [selectedDuration, setSelectedDuration] = useState<{
    months: number;
    weeks: number;
    days: number;
    hours: number;
    minutes: number;
  }>({
    months: 0,
    weeks: 0,
    days: 0,
    hours: 0,
    minutes: 0,
  });

  useEffect(() => {
    if (
      element.type === "bpmn:StartEvent" ||
      element.type === "bpmn:IntermediateCatchEvent" ||
      element.type === "bpmn:BoundaryEvent"
    ) {
      const bo = getBusinessObject(element);
      const formalExpressionOfDate =
        bo.eventDefinitions && bo.eventDefinitions.length
          ? bo.eventDefinitions[0]?.timeDate
          : "";

      const formalExpressionOfDuration =
        bo.eventDefinitions && bo.eventDefinitions.length
          ? bo.eventDefinitions[0]?.timeDuration
          : "";
      const formalExpressionOfCycle =
        bo.eventDefinitions && bo.eventDefinitions.length
          ? bo.eventDefinitions[0]?.timeCycle
          : "";
      if (formalExpressionOfDate) {
        const value: Date = formalExpressionOfDate.body
          ? new Date(formalExpressionOfDate.body)
          : new Date();
        setTimerType("Date");
        setTimeDateValue(value);
      } else if (formalExpressionOfDuration) {
        const durationValue: string = formalExpressionOfDuration.body
          ? formalExpressionOfDuration.body
          : "";
        if (durationValue) {
          // const value = generateIsoStringFromDuration(
          //   durationValue,
          //   new Date()
          // );
          const value: {
            months: number;
            weeks: number;
            days: number;
            hours: number;
            minutes: number;
          } = parseISODurationString(durationValue);
          setSelectedDuration(value);
          setTimerType("Duration");
          // setTimeDurationValue(value);
        }
      } else if (formalExpressionOfCycle) {
        setTimerType("Cycle");
      } else {
        setTimerType("");
        setTimeDateValue(null);
        setSelectedDuration({
          months: 0,
          weeks: 0,
          days: 0,
          hours: 0,
          minutes: 0,
        });
      }
    }
  }, [element]);

  if (element?.labelTarget) {
    element = element.labelTarget;
  }

  return (
    <Box key={element.id} sx={{ overflowY: "auto" }}>
      <List>
        <PrimaryPropertiesPanel
          togglePrimaryPropertiesAccordian={togglePrimaryPropertiesAccordian}
          setTogglePrimaryPropertiesAccordian={
            setTogglePrimaryPropertiesAccordian
          }
          element={element}
          modeler={modeler}
          primaryPropertiesNameRef={primaryPropertiesNameRef}
        />
        <SpecificPropertiesPanel
          toggleSpecificPropertiesAccordian={toggleSpecificPropertiesAccordian}
          setToggleSpecificPropertiesAccordian={
            setToggleSpecificPropertiesAccordian
          }
          element={element}
          modeler={modeler}
          timerType={timerType}
          setTimerType={setTimerType}
          timeDateValue={timeDateValue}
          setTimeDateValue={setTimeDateValue}
          timeDurationValue={timeDurationValue}
          setTimeDurationValue={setTimeDurationValue}
          processModelData={processModelData}
          getRequestBodyForExtensionElements={
            getRequestBodyForExtensionElements
          }
          setProcessModelData={setProcessModelData}
          // getSelectedLanguage={getSelectedLanguage}
          openDiagram={openDiagram}
          selectedDuration={selectedDuration}
          setSelectedDuration={setSelectedDuration}
        />
      </List>
    </Box>
  );
}

export default ElementProperties;
