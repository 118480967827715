import { Box } from "@mui/material";
// import { getTranslation } from "common";
import { ProcessStarted } from "components";
import { useSelectedOrganization } from "hooks";
import React from "react";
// import SwipeableViews from "react-swipeable-views";

// function a11yProps(index: number) {
//   return {
//     id: `full-width-tab-${index}`,
//     "aria-controls": `full-width-tabpanel-${index}`,
//   };
// }
// interface TabPanelProps {
//   children?: React.ReactNode;
//   dir?: string;
//   index: number;
//   value: number;
// }
// function TabPanel(props: TabPanelProps) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`full-width-tabpanel-${index}`}
//       aria-labelledby={`full-width-tab-${index}`}
//       {...other}
//     >
//       {value === index && children}
//     </div>
//   );
// }

const Processes = () => {
  const organization = useSelectedOrganization();
  // const translations = useTranslations();
  // const [value, setValue] = useState(0);
  // const theme = useTheme();
  // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setValue(newValue);
  // };
  // const handleChangeIndex = (index: number) => {
  //   setValue(index);
  // };

  return organization?.id ? (
    <React.Fragment>
      {/* <Stack
        sx={{
          "div[role=tabpanel]": {
            height: "100%",
          },
        }}
        height="100%"
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label={getTranslation(translations, "pm.label.process_started")} {...a11yProps(0)} />
          <Tab
            label={getTranslation(translations, "pm.title")}
            {...a11yProps(1)}
          />
        </Tabs>
        <SwipeableViews
          style={{ paddingTop: theme.spacing(1), height: "100%" }}
          containerStyle={{ height: "100%" }}
          slideStyle={{ height: "100%" }}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0}> */}
            <Box height="100%">
              <ProcessStarted organization={organization} />
            </Box>
          {/* </TabPanel>
          <TabPanel value={value} index={1}>
            <Box height="100%">
              <ProcessModels organizationId={organization.id} />
            </Box>
          </TabPanel>
        </SwipeableViews>
      </Stack> */}
    </React.Fragment>
  ) : null;
};

export default Processes;
