import { Menu, MenuItem } from "@mui/material";
import { organizationsApi } from "api";
import React, { useCallback, useEffect, useState } from "react";
import { RoleAssociation } from "../UserSelectedPanel";

interface RolesMenuProps {
  anchor: null | HTMLElement;
  onLink: (id: number) => void;
  associated: Array<RoleAssociation>;
  onClose: Function;
  organizationId: number;
}

const RolesMenu: React.FC<RolesMenuProps> = ({
  anchor,
  onLink,
  associated,
  onClose,
  organizationId,
}) => {
  const [voices, setVoices] = useState<Array<RoleAssociation>>([]);

  const getRoles = useCallback(async () => {
    try {
      const response = await organizationsApi.get<{
        roles: Array<RoleAssociation>;
      }>(`${organizationId}/roles`);
      setVoices(
        response.data.roles.filter(
          (x) => !associated.map((_) => _.id).includes(x.id)
        )
      );
    } catch {
    } finally {
    }
  }, [organizationId, associated]);

  useEffect(() => {
    if (Boolean(anchor)) {
      getRoles();
    }
  }, [anchor, getRoles]);

  return (
    <Menu
      open={voices.length > 0 ? Boolean(anchor) : false}
      onClose={() => onClose()}
      anchorEl={anchor}
    >
      {voices.map(({ name, id }) => (
        <MenuItem
          id={`menu-role-${id}`}
          key={`menu-role-${id}`}
          onClick={() => onLink(id)}
        >
          {name}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default RolesMenu;
