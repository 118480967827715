import { Box } from "@mui/material";
import { getTranslation } from "common";
import { useTranslations } from "hooks";
import React from "react";

const RobotsHelp = () => {
  const translations = useTranslations();

  return (
    <React.Fragment>
      <Box
        dangerouslySetInnerHTML={{
          __html: getTranslation(translations, "help.robots.main"),
        }}
      />
    </React.Fragment>
  );
};

export default RobotsHelp;
